import React, { Component } from "react";
import Tabletop from "tabletop";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const styles = theme => ({
    root: {
        width: "90%",
        marginTop: theme.spacing.unit * 3,
        overflowX: "auto"
    },
    gridItem: {
        marginBottom: 10
    },
    dropDown: {
        fullWidth: true
    },
    formControl: {
        margin: theme.spacing.unit * 3
    },
    group: {
        margin: `${theme.spacing.unit}px 0`
    },
    table: {
        // maxWidth: 200,
        paddingBottom: 20
    },
    headingElements: {
        marginTop: 10,
        marginBottom: 30
    },
    containerPaper: {
        padding: 20
    },
    textField: {
        marginLeft: -10,
        marginRight: theme.spacing.unit,
        width: 200,
        textAlign: "center"
    },
    paper: {
        padding: theme.spacing.unit * 20,
        textAlign: "left",
        color: theme.palette.text.secondary
    }
});

class AboutPage extends Component {
    state = {
        data: []
    };

    componentDidMount() {
        Tabletop.init({
            key: "1AuSld3z_g1DzfAY2iX56Zd9rQdHCypz57DIgd35329g",
            callback: googleData => {
                this.setState({ data: googleData });
            },
            simpleSheet: true
        });
    }

    render() {
        const { data } = this.state;
        const { classes } = this.props;
        return (
            // <>HIPEC Patient Tracking System Documentation</>
            <div>
                <Grid container direction="column" id="instructions" className={classes.paper}>
                    {data.map(obj => {
                        return (
                            <Grid item key={obj.instructionNumber}>
                                <p style={{ color: "black", fontSize: "1.5em", fontFamily: "sans-serif" }}>
                                    {obj.instructionNumber}. {obj.instruction}.
                                </p>
                                <img
                                    alt="instruction"
                                    src={obj.imageLink1}
                                    style={{ display: "block", margin: "0 auto", paddingBottom: "30px" }}
                                />
                                <figcaption style={{ fontSize: 14, textAlign: "center", fontFamily: "sans-serif" }}>
                                    {obj.imageCaption1}
                                </figcaption>
                            </Grid>
                        );
                    })}
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(AboutPage);
