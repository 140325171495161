const initialState = {
    data: [],
    loading: false,
    error: ''
  };
  
const pathologyNotesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_PATHOLOGY_NOTES':
            return {
                ...state,
                data: [],
                loading: true,
                error: '',
                updateSuccess: false
            };
        case 'ADD_PATHOLOGY_NOTES_SUCCESS':
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: '',
                updateSuccess: true
            };
        case 'SET_PATHOLOGY_NOTES':
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: '',
                updateSuccess: false
            };     
        case 'PATHOLOGY_NOTES_ERROR':
            return {
                ...state,
                loading: false,
                error: 'Error in pathology notes.',
                updateSuccess: false
            };
        case 'CLEAR_PATHOLOGY_NOTES_ERROR':
            return {
                ...state,
                loading: false,
                error: '',
                updateSuccess: false
            };  
        default:
            return state;
    }
  };
  
  export default pathologyNotesReducer;