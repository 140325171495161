import { takeLatest , call, put } from 'redux-saga/effects';
import axios from 'axios';

function* intervention(action) {
    try {
        const response = yield call(axios.get, `/intervention/${action.payload}`);
        yield put({ type: 'SET_INTERVENTION', payload: response.data });
    }
    catch (error) {
        console.log('error with getting reques', error);
    }
    }

function* updateIntervention(action) { 
    try {
        yield call(axios.put, `/intervention/${action.payload.patient_id}`, action.payload);
        yield put({ type: 'UPDATE_INTERVENTION_SUCCESS', payload: action.payload })

    } catch (error) {
        console.log(error);
        yield put({ type: 'INTERVENTION_ERROR', payload: error });
    }
}

function* interventionSaga() {
    yield takeLatest('GET_INTERVENTION', intervention);
    yield takeLatest('UPDATE_INTERVENTION', updateIntervention);
  
}

export default interventionSaga;