import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import GridItem from "@material-ui/core/Grid";

const styles = theme => ({
    root: {
        width: "100%",
        flexGrow: 1,
        marginTop: theme.spacing.unit * 3,
        overflowX: "auto"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    },
    paper: {
        padding: theme.spacing.unit * 1,
        textAlign: "center",
        color: theme.palette.text.secondary
    }
});

class MetastasisLocation extends Component {
    render() {
        const { primary_tumor, gender, metastasisLocationOptions } = this.props;

        if (!primary_tumor || !metastasisLocationOptions) {
            return <></>;
        }

        return (
            <Grid container spacing={24}>
                {metastasisLocationOptions.map(
                    (opt, index) =>
                        (gender !== "Male" || (gender === "Male" && opt.option !== "Ovaries" && opt.option !== "Uterus")) && (
                            <>
                                <GridItem item xs={12} sm={3} md={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                key={index}
                                                name={opt.db_name}
                                                checked={primary_tumor[opt.db_name]}
                                                onChange={this.props.handleChangeCheckbox}
                                            />
                                        }
                                        label={opt.option}
                                    />
                                </GridItem>
                                {opt.option === "Other" && primary_tumor[opt.db_name] && (
                                    <TextField
                                        disabled={!primary_tumor.other}
                                        margin="dense"
                                        onChange={this.props.handleChange}
                                        name="other_location"
                                        value={primary_tumor.other_location ? primary_tumor.other_location : ""}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        id="other_location"
                                        label="Other Location"
                                    />
                                )}
                            </>
                        )
                )}
            </Grid>
        );
    }
}

MetastasisLocation.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = reduxState => ({
    metastasisLocationOptions: reduxState.optionsReducer.metastasisLocationOptions,
    gender: reduxState.patientReducer.data.gender
});

export default connect(mapStateToProps)(withStyles(styles)(MetastasisLocation));
