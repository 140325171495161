const initialState = {
    data: {},
    loading: false,
    error: ''
  };

const interventionReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_INTERVENTION':
                return {
                    ...state,
                    data: {},
                    loading: true,
                    error: '',
                    updateSuccess: false
                };
            case 'SET_INTERVENTION':
                return {
                    ...state,
                    data: action.payload,
                    loading: false,
                    error: '',
                    updateSuccess: false
                    };     
            case 'INTERVENTION_ERROR':
                    return {
                        ...state,
                        loading: false,
                        error: 'Error in intake details.',
                        updateSuccess: false
                    };     
            case 'UPDATE_INTERVENTION_SUCCESS':
                    return {
                        ...state,
                        data: action.payload,
                        loading: false,
                        error: '',
                        updateSuccess: true
                        };   
            case 'CLEAR_INTERVENTION_ERROR':
                    return {
                        ...state,
                        loading: false,
                        error: '',
                        updateSuccess: false
                    };   
            default:
                return state;
    }
  };
  
  
  export default interventionReducer;