import axios from 'axios';
import { call, put, takeLatest} from 'redux-saga/effects';

function* allMyPatients(action) {
  try {
    const response = yield call(axios.get, `/api/all-patients/${action.payload}`);
    yield put({ type: 'ALL_PATIENTS_FOUND', payload: response.data });
  } catch (error) {
    yield put({ type: 'ALL_PATIENTS_ERROR', payload: error });
    console.log('All my patients GET request failure ', error);
  }
}

function* allMyPatientsAfterAdd(action) {
  try {
    const response = yield call(axios.get, `/api/all-patients/${action.payload}`);
    yield put({ type: 'ALL_PATIENTS_FOUND_AFTER_ADD', payload: response.data });
  } catch (error) {
    yield put({ type: 'ALL_PATIENTS_ERROR', payload: error });
  }
}

function* fetchPatient(action) {
    try {
      const response = yield call(axios.get, `/api/find-a-patient/${action.payload}`);
      yield put({ type: 'SET_PATIENT_FOUND', payload: response.data });
    } catch (error) {
      yield put({ type: 'SET_PATIENT_ERROR', payload: error });
      console.log('Patient GET request failed:ERROR', error);
    }
}

// Saga triggered on ADD_PATIENT
function* addNewPatient(action) {
    try {
        yield call(axios.post, '/api/add-a-patient', action.payload.patientObject);
        // yield put({ type: 'SET_NEW_PATIENT', payload: action.payload.patientObject});
        yield put({ type: 'ALL_MY_PATIENTS_AFTER_ADD', payload: action.payload.userId });
    }
    catch (error) {
        yield put({ type: 'SET_NEW_PATIENT_ERROR', payload: error });
        console.log('there was an error with your POST', error);
    }
}

// Saga triggered on UPDATE_PATIENT
function* updatePatient(action) {
    try {
        yield call(axios.put, '/api/update-a-patient', action.payload);
        yield put({ type: 'UPDATE_PATIENT_SUCCESS', payload: action.payload  });
    }
    catch (error) {
        console.log('there was an error with your POST', error);
        yield put({ type: 'SET_PATIENT_ERROR', payload: 'Error updating this patient. Please try again.' });
    }
}

function* patientSaga() {
  yield takeLatest('FIND_PATIENT', fetchPatient);
  yield takeLatest('ADD_PATIENT', addNewPatient);
  yield takeLatest('UPDATE_PATIENT', updatePatient);
  yield takeLatest('ALL_MY_PATIENTS', allMyPatients);
  yield takeLatest('ALL_MY_PATIENTS_AFTER_ADD', allMyPatientsAfterAdd);
}

export default patientSaga;
