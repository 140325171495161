import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import classNames from "classnames";
import Divider from "@material-ui/core/Divider";
import PSDSSPageData from "./PSDSSPageData";
import Card from "@material-ui/core/Card";
import CircularProgress from "@material-ui/core/CircularProgress";
import Notification from "../Notification";

const styles = theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing.unit * 3,
        overflowX: "auto"
    },
    container: {
        //   display: "flex",
        //   flexWrap: "wrap",
        //   alignItems: "stretch",
        //   marginTop: 85
    },
    gridItem: {
        marginBottom: 10
    },
    dropDown: {
        fullWidth: true
    },
    formControl: {
        margin: theme.spacing.unit * 3
    },
    group: {
        margin: `${theme.spacing.unit}px 0`
    },
    table: {
        // maxWidth: 200,
        paddingBottom: 20
    },
    headingElements: {
        marginTop: 10,
        marginBottom: 30
    },
    containerPaper: {
        padding: 20
    },
    textField: {
        marginLeft: -10,
        marginRight: theme.spacing.unit,
        width: 200,
        textAlign: "center"
    },
    paper: {
        padding: theme.spacing.unit * 2,
        // textAlign: 'left',
        color: theme.palette.text.secondary
    }
});

class PSDSSPage extends Component {
    state = {
        changesMade: false,
        localLoading: true,
        errorOpen: true,
        patient_id: null,
        pci: null,
        clinical: null,
        histology: null,
        synchronous_liver_treatment: false,
        timing: null,
        date_treatment: null,
        treatment_type: null,
        notes: null,
        total: null
    };

    setInitialState = () => {
        const { clinical, histology, synchronous_liver_treatment, timing, date_treatment, treatment_type, notes, total } = this.props.psdss;
        const { pciScore } = this.props;

        this.setState(
            {
                changesMade: false,
                errorOpen: true,
                patient_id: this.props.patientId,
                pci: pciScore === null ? null : pciScore < 10 ? 1 : pciScore < 20 ? 3 : pciScore >= 20 ? 7 : null,
                clinical: clinical || null,
                histology: histology || null,
                synchronous_liver_treatment: synchronous_liver_treatment || null,
                timing: timing || null,
                total: total || null,
                date_treatment: date_treatment || null,
                treatment_type: treatment_type || null,
                notes: notes || null
            },
            () => {
                this.setState(
                    {
                        ...this.state,
                        localLoading: false
                    },
                    this.calculatePSDSS
                );
            }
        );
    };

    handleChange = event => {
        this.setState({
            ...this.state,
            changesMade: true,
            [event.target.name]: event.target.value
        });
    };

    handlePSDSSChange = event => {
        this.setState(
            {
                ...this.state,
                changesMade: true,
                [event.target.name]: event.target.value
            },
            this.calculatePSDSS
        );
    };

    handleChangeCheckbox = event => {
        this.setState({
            ...this.state,
            changesMade: true,
            [event.target.name]: event.target.checked
        });
    };

    handleCloseError = () => {
        this.setState({
            ...this.state,
            errorOpen: false
        });
        this.props.dispatch({ type: "CLEAR_PSDSS_ERROR" });
        this.setInitialState();
    };

    calculatePSDSS = () => {
        const { pci, histology, clinical } = this.state;
        const { pciScore } = this.props;

        if (!pciScore) {
            this.setState({
                ...this.state,
                total: "Not Available",
                localLoading: false
            });
        } else {
            this.setState({
                ...this.state,
                total: Number(clinical) + Number(pci) + Number(histology),
                localLoading: false
            });
        }
    };

    toggleSwitch = event => {
        this.setState({
            [event.target.name]: event.target.checked
        });
    };

    saveClicked = () => {
        if (this.state.changesMade) {
            this.props.dispatch({ type: "UPDATE_PSDSS", payload: this.state });
        }
        this.setState({
            ...this.state,
            changesMade: false
        });
    };

    componentDidMount() {
        this.props.onRef(this);
        this.setInitialState();
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    componentWillUnmount() {
        if (this.state.changesMade) {
            if (
                window.confirm(
                    "You have made changes that have not been saved. Click on OK to save these changes or Cancel to discard them."
                )
            ) {
                this.saveClicked();
            }
        }
        this.props.onRef(undefined);
    }

    render() {
        const { classes, loading, error, updateSuccess, pciScore } = this.props;
        const { localLoading, errorOpen } = this.state;

        if (loading || localLoading) {
            return (
                <>
                    <div className={classes.toolbar} />
                    <Grid container className={classes.progress} spacing={24} alignItems="center" justify="center">
                        <CircularProgress></CircularProgress>
                    </Grid>
                </>
            );
        }
        return (
            <>
                {error && <Notification open={errorOpen} error={true} handleCloseError={this.handleCloseError} message={error} />}
                {updateSuccess && (
                    <Notification
                        open={errorOpen}
                        error={false}
                        handleCloseError={this.handleCloseError}
                        message="PSDSS Information Saved"
                    />
                )}

                <Grid container className={classes.container}>
                    <Card className={classes.paper}>
                        <Grid container direction="row" justify="space-evenly" alignItems="flex-start">
                            <Grid item xs={12} className={classes.gridItem}>
                                <Grid container direction="row" justify="flex-start">
                                    <Grid item xs={12} className={classes.gridItem}>
                                        <Typography variant="h6">Peritonial Surface Disease Severity Scale</Typography>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridItem}></Grid>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={2} className={classes.textField}>
                                        <TextField
                                            label="Clinical Evaluation Value"
                                            inputProps={{
                                                style: { textAlign: "center" }
                                            }}
                                            onChange={this.handlePSDSSChange}
                                            name="clinical"
                                            value={this.state.clinical || ""}
                                            id="patientWeightInput"
                                            className={classNames(classes.margin, classes.textField)}
                                        />
                                    </Grid>
                                    <Grid item xs={2}></Grid>
                                    <Grid item xs={2} className={classes.textField}>
                                        <TextField
                                            label="PCI Value"
                                            // onChange={this.handleChange}
                                            disabled
                                            name="pci"
                                            value={
                                                pciScore === null
                                                    ? "Not Yet Entered"
                                                    : pciScore < 10
                                                    ? 1
                                                    : pciScore < 20
                                                    ? 3
                                                    : pciScore >= 20
                                                    ? 7
                                                    : "Not Yet Entered"
                                            }
                                            id="pci"
                                            className={classNames(classes.margin, classes.textField)}
                                            inputProps={{
                                                style: { textAlign: "center" }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2}></Grid>
                                    <Grid item xs={2} className={classes.textField}>
                                        <TextField
                                            label="Histology Score"
                                            onChange={this.handlePSDSSChange}
                                            name="histology"
                                            value={this.state.histology || ""}
                                            id="histologyScore"
                                            className={classNames(classes.margin, classes.textField)}
                                            inputProps={{
                                                style: { textAlign: "center" }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridItem} align="center">
                                        <Grid container direction="row" justify="space-evenly" alignItems="center">
                                            <Grid item xs={4} className={classes.headingElements} align="center">
                                                <Typography>
                                                    Clinical Evaluation
                                                    <br />
                                                    Enter a value from 0 to 6
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4} className={classes.headingElements} align="center">
                                                <Typography>
                                                    PCI of {pciScore}
                                                    <br />
                                                    (from Intervention tab)
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4} className={classes.headingElements} align="center">
                                                <Typography>
                                                    Histology
                                                    <br />
                                                    Enter a value from 1 to 9
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} className={classes.gridItem} align="center">
                                                <Divider />
                                            </Grid>
                                            <Grid item xs={4} className={classes.gridItem} align="center">
                                                <Typography variant="overline">No Symptoms</Typography>
                                            </Grid>
                                            <Grid item xs={4} className={classes.gridItem} align="center">
                                                <Typography variant="overline">{"<"} 10</Typography>
                                            </Grid>
                                            <Grid item xs={4} className={classes.gridItem} align="center">
                                                <Typography variant="overline">Good/Moderate N0</Typography>
                                            </Grid>
                                            <Grid item xs={4} className={classes.gridItem} align="center">
                                                <Typography>0</Typography>
                                            </Grid>
                                            <Grid item xs={4} className={classes.gridItem} align="center">
                                                <Typography>1</Typography>
                                            </Grid>
                                            <Grid item xs={4} className={classes.gridItem} align="center">
                                                <Typography>1</Typography>
                                            </Grid>
                                            <Grid item xs={12} className={classes.gridItem} align="center">
                                                <Divider variant="fullWidth"></Divider>
                                            </Grid>
                                            <Grid item xs={4} className={classes.gridItem} align="center">
                                                <Typography variant="overline">Mild Symptoms</Typography>
                                            </Grid>
                                            <Grid item xs={4} className={classes.gridItem} align="center">
                                                <Typography variant="overline">10-20</Typography>
                                            </Grid>
                                            <Grid item xs={4} className={classes.gridItem} align="center">
                                                <Typography variant="overline">Moderate N1-2</Typography>
                                            </Grid>
                                            <Grid item xs={4} className={classes.gridItem} align="center">
                                                <Typography>1</Typography>
                                            </Grid>
                                            <Grid item xs={4} className={classes.gridItem} align="center">
                                                <Typography>3</Typography>
                                            </Grid>
                                            <Grid item xs={4} className={classes.gridItem} align="center">
                                                <Typography>3</Typography>
                                            </Grid>
                                            <Grid item xs={12} className={classes.gridItem} align="center">
                                                <Divider variant="fullWidth"></Divider>
                                            </Grid>
                                            <Grid item xs={4} className={classes.gridItem} align="center">
                                                <Typography variant="overline">Severe Symptoms</Typography>
                                            </Grid>
                                            <Grid item xs={4} className={classes.gridItem} align="center">
                                                <Typography variant="overline">{">"} 20</Typography>
                                            </Grid>
                                            <Grid item xs={4} className={classes.gridItem} align="center">
                                                <Typography variant="overline">Poor/Signet</Typography>
                                            </Grid>
                                            <Grid item xs={4} className={classes.gridItem} align="center">
                                                <Typography>6</Typography>
                                            </Grid>
                                            <Grid item xs={4} className={classes.gridItem} align="center">
                                                <Typography>7</Typography>
                                            </Grid>
                                            <Grid item xs={4} className={classes.gridItem} align="center">
                                                <Typography>9</Typography>
                                            </Grid>
                                            <Grid item xs={12} className={classes.gridItem} align="center">
                                                <Divider variant="fullWidth"></Divider>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} className={classes.gridItem}>
                                            <Typography variant="h5" align="center">
                                                PSDSS Score: {this.state.total}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} className={classes.gridItem}>
                                            <Typography align="center">
                                                2 to 3 (Stage I) - 4 to 7 (Stage II) - 8 to 10 (Stage III) - 11+ (Stage IV){" "}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                    <br />
                    <Card className={classes.paper}>
                        <Typography variant="h6">Additional Information</Typography>
                        <Divider />
                        <PSDSSPageData
                            psdssInfo={this.state}
                            toggleSwitch={this.toggleSwitch}
                            handleChangeCheckbox={this.handleChangeCheckbox}
                            handleChange={this.handleChange}
                        />
                    </Card>
                </Grid>
            </>
        );
    }
}

const mapStateToProps = reduxState => ({
    psdss: reduxState.psdssReducer.data,
    loading: reduxState.psdssReducer.loading,
    error: reduxState.psdssReducer.error,
    updateSuccess: reduxState.psdssReducer.updateSuccess,
    patientId: reduxState.patientReducer.data.id,
    pciScore: reduxState.interventionReducer.data.pci_score
});

export default connect(mapStateToProps, null, null, { withRef: true })(withStyles(styles)(PSDSSPage));
