import { put, takeLatest , call } from 'redux-saga/effects';
import axios from 'axios';


function* getIntake(action) { 
    try {
        const response = yield axios.get( `/api/intake/${action.payload}`);
        yield put({ type: 'SET_INTAKE', payload: response.data } )
    } catch (error) {
        yield put({ type: 'INTAKE_ERROR', payload: error });
        console.log('Intake GET request failure ', error);
      }
}

function* updateIntake(action) {
    try {
        yield call(axios.put, `/api/intake/${action.payload.id}`, action.payload);
        yield put({type: 'UPDATE_INTAKE_SUCCESS', payload: action.payload});
    }
    catch(error){
        yield put({ type: 'INTAKE_ERROR', payload: error });
        console.log(error)
    }
}

function* intakeSaga() {
  yield takeLatest('GET_INTAKE', getIntake);
  yield takeLatest('UPDATE_INTAKE', updateIntake);
}

export default intakeSaga;