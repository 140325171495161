import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import IntakePageGeneralInfo from "./IntakePageGeneralInfo";
import IntakePageGeneralHealth from "./IntakePageGeneralHealth";
import IntakePageNeoAdjuvant from "./IntakePageNeoAdjuvant";
import IntakePageDiagnosticScopy from "./IntakePageDiagnosticScopy";
import IntakePageDiagnosticCT from "./IntakePageDiagnosticCT";
import IntakePageDiagnosticMRI from "./IntakePageDiagnosticMRI";
import IntakePageOther from "./IntakePageOther";
import CircularProgress from "@material-ui/core/CircularProgress";
import Notification from "../Notification";
import moment from "moment";

const styles = theme => ({
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2
    },
    toolbar: {
        display: "flex",
        color: "primary",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing.unit * 1,
        ...theme.mixins.toolbar
    },
    progress: {
        margin: theme.spacing.unit * 2,
        padding: theme.spacing.unit * 3
    }
});

class IntakePage extends Component {
    state = {
        changesMade: false,
        localLoading: true,
        errorOpen: true,
        bmi_auto: "",
        length_m: "",
        weight_kg: "",
        id: "",
        patient_id: "",
        cea_mg_l: "",
        ca125_u_ml: "",
        ca199_u_ml: "",
        cta: "",
        leucocyte: "",
        smoking: "",
        diabetes: "",
        insulin_dependent: "",
        cardiovascular: "",
        hypertension: "",
        stoma_pre_hipec: "",
        stoma_type: "",
        neo_adj_chemotherapy_type: "",
        neo_adj_chemotherapy_cycles: "",
        neo_adj_chemotherapy_other_type: "",
        neo_adj_chemotherapy_other_cycles: "",
        biological_id: "",
        biological_other: "",
        notes: "",
        diagnostic_scopy: "",
        date_scopy: "",
        scopy_conclusion: "",
        scopy_notes: "",
        diagnostic_ct: "",
        date_ct: "",
        ct_conclusion: "",
        ct_notes: "",
        diagnostic_mri: "",
        date_mri: "",
        mri_conclusion: "",
        mri_notes: "",
        synch_meta_chronous: "",
        assessment_of_pss: "",
        asa_score: "",
        pss_score: ""
    };

    setInitialState = () => {
        this.setState(
            {
                ...this.state,
                changesMade: false,
                errorOpen: true,
                bmi_auto: this.props.intake.bmi_auto,
                length_m: this.props.intake.length_m,
                weight_kg: this.props.intake.weight_kg,
                id: this.props.intake.id,
                patient_id: this.props.patientId,
                cea_mg_l: this.props.intake.cea_mg_l,
                ca125_u_ml: this.props.intake.ca125_u_ml,
                ca199_u_ml: this.props.intake.ca199_u_ml,
                cta: this.props.intake.cta,
                leucocyte: this.props.intake.leucocyte,
                smoking: this.props.intake.smoking,
                diabetes: this.props.intake.diabetes,
                insulin_dependent: this.props.intake.insulin_dependent,
                cardiovascular: this.props.intake.cardiovascular,
                hypertension: this.props.intake.hypertension,
                stoma_pre_hipec: this.props.intake.stoma_pre_hipec,
                stoma_type: this.props.intake.stoma_type,
                neo_adj_chemotherapy_type: this.props.intake.neo_adj_chemotherapy_type,
                neo_adj_chemotherapy_cycles: this.props.intake.neo_adj_chemotherapy_cycles,
                neo_adj_chemotherapy_other_type: this.props.intake.neo_adj_chemotherapy_other_type,
                neo_adj_chemotherapy_other_cycles: this.props.intake.neo_adj_chemotherapy_other_cycles,
                biological_id: this.props.intake.biological_id,
                biological_other: this.props.intake.biological_other,
                notes: this.props.intake.notes,
                diagnostic_scopy: this.props.intake.diagnostic_scopy,
                date_scopy: this.props.intake.date_scopy ? moment(this.props.intake.date_scopy).format("YYYY-MM-DD") : null,
                scopy_conclusion: this.props.intake.scopy_conclusion,
                scopy_notes: this.props.intake.scopy_notes,
                diagnostic_ct: this.props.intake.diagnostic_ct,
                date_ct: this.props.intake.date_ct ? moment(this.props.intake.date_ct).format("YYYY-MM-DD") : null,
                ct_conclusion: this.props.intake.ct_conclusion,
                ct_notes: this.props.intake.ct_notes,
                diagnostic_mri: this.props.intake.diagnostic_mri,
                date_mri: this.props.intake.date_mri ? moment(this.props.intake.date_mri).format("YYYY-MM-DD") : null,
                mri_conclusion: this.props.intake.mri_conclusion,
                mri_notes: this.props.intake.mri_notes,
                synch_meta_chronous: this.props.intake.synch_meta_chronous,
                assessment_of_pss: this.props.intake.assessment_of_pss,
                asa_score: this.props.intake.asa_score,
                pss_score: this.props.intake.pss_score
            },
            () => {
                this.setState({
                    ...this.state,
                    localLoading: false
                });
            }
        );
    };

    //Function to calculate the bmi for the patient.
    calculateBMI = () => {
        let bmi;
        let height = Number(this.state.length_m);
        let weight = Number(this.state.weight_kg);
        let squaredHeight = height * height;
        bmi = weight / squaredHeight;
        if (this.state.length_m !== "" && this.state.weight_kg !== "") {
            this.setState({
                bmi_auto: String(parseFloat(bmi).toFixed(2))
            });
        } else {
            this.setState({
                bmi_auto: ""
            });
        }
    };

    //Function to handle the changes in our inputs and selectors.
    handleChange = event => {
        this.setState(
            {
                [event.target.name]: event.target.value,
                changesMade: true
            },
            this.calculateBMI
        );
    };

    handleCloseError = () => {
        this.setState({
            ...this.state,
            errorOpen: false
        });
        this.props.dispatch({ type: "CLEAR_INTAKE_ERROR" });
        this.setInitialState();
    };

    saveClicked = () => {
        if (this.state.changesMade) {
            this.props.dispatch({ type: "UPDATE_INTAKE", payload: this.state });
        }
        this.setState({
            ...this.state,
            changesMade: false
        });
    };

    componentDidMount() {
        this.props.onRef(this);
        this.setInitialState();
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    componentWillUnmount() {
        if (this.state.changesMade) {
            if (
                window.confirm(
                    "You have made changes that have not been saved. Click on OK to save these changes or Cancel to discard them."
                )
            ) {
                this.saveClicked();
            }
        }
        this.props.onRef(undefined);
    }

    render() {
        const { classes, loading, error, updateSuccess } = this.props;
        const { localLoading, errorOpen } = this.state;

        if (loading || localLoading) {
            return (
                <>
                    <div className={classes.toolbar} />
                    <Grid container className={classes.progress} spacing={24} alignItems="center" justify="center">
                        <CircularProgress></CircularProgress>
                    </Grid>
                </>
            );
        }

        return (
            <>
                {error && <Notification open={errorOpen} error={true} handleCloseError={this.handleCloseError} message={error} />}
                {updateSuccess && (
                    <Notification
                        open={errorOpen}
                        error={false}
                        handleCloseError={this.handleCloseError}
                        message="Intake Information Saved"
                    />
                )}
                <Grid container>
                    <Grid item xs={12} md={4}>
                        <Paper className={classes.paper}>
                            <IntakePageGeneralInfo intake={this.state} handleChange={this.handleChange} calculateBMI={this.calculateBMI} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Paper className={classes.paper}>
                            <IntakePageGeneralHealth intake={this.state} handleChange={this.handleChange} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper className={classes.paper}>
                            <IntakePageNeoAdjuvant intake={this.state} handleChange={this.handleChange} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper className={classes.paper}>
                            <IntakePageDiagnosticScopy intake={this.state} handleChange={this.handleChange} />
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Paper className={classes.paper}>
                            <IntakePageDiagnosticCT intake={this.state} handleChange={this.handleChange} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper className={classes.paper}>
                            <IntakePageDiagnosticMRI intake={this.state} handleChange={this.handleChange} />
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <IntakePageOther intake={this.state} handleChange={this.handleChange} />
                        </Paper>
                    </Grid>
                </Grid>
            </>
        );
    }
}

const mapStateToProps = reduxState => ({
    intake: reduxState.intakeReducer.data,
    patientId: reduxState.patientReducer.data.id,
    loading: reduxState.intakeReducer.loading,
    error: reduxState.intakeReducer.error,
    updateSuccess: reduxState.intakeReducer.updateSuccess
});

export default connect(mapStateToProps, null, null, { withRef: true })(withStyles(styles)(IntakePage));
