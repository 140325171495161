const initialState = {
  data: [],
  loading: false,
  error: ''
};

const patientReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'CLEAR_PATIENT':
        return {};
      case 'FIND_PATIENT':
        return {
          ...state,
          data: {},
          loading: true,
          error: '',
          updateSuccess: false
        };
      case 'SET_PATIENT_FOUND':
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: '',
          updateSuccess: false
        };
      case "SET_PATIENT_ERROR":
        return {
          ...state,
          loading: false,
          error: action.payload,
          updateSuccess: false
        };
        case 'UPDATE_PATIENT_SUCCESS':
          return {
            ...state,
            data: action.payload,
            loading: false,
            error: '',
            updateSuccess: true
          };
      case "CLEAR_PATIENT_ERROR":
        return {
          ...state,
          loading: false,
          error: '',
          updateSuccess: false
      };
      case 'SET_NEW_PATIENT':
        return {
          ...state,
          addSuccess: true,
          patient: action.payload
      };
      case 'SET_NEW_PATIENT_ERROR':
        return {
          ...state,
          loading: false,
          error: 'Error adding new patient. Please try again.',
          addSuccess: false
      };  
      case 'ALL_MY_PATIENTS':
        return {
          ...state,
          allPatients: [],
          loading: true,
          error: '',
          updateSuccess: false
        };
      case 'ALL_MY_PATIENTS_AFTER_ADD':
            return {
              ...state,
              allPatients: [],
              loading: true,
              error: '',
              updateSuccess: false,
              addSuccess: true
            };
      case "CLEAR_ALL_MY_PATIENTS_ERROR":
          return {
            ...state,
            loading: false,
            error: '',
            updateSuccess: false
      };  
      case 'ALL_PATIENTS_FOUND':
        return {
          ...state,
          allPatients: action.payload,
          loading: false,
          error: '',
          updateSuccess: false
        };
        case 'ALL_PATIENTS_FOUND_AFTER_ADD':
          return {
            ...state,
            allPatients: action.payload,
            loading: false,
            error: '',
            updateSuccess: false,
            addSuccess: true
          };
      case "ALL_PATIENTS_ERROR":
        return {
          ...state,
          allPatients: [],
          loading: false,
          error: 'Error loading patients.',
          updateSuccess: false
        }
      default:
        return state;
    }
  };
  
  // user will be on the redux state at:
  // state.user
  export default patientReducer;
  