import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

// worker Saga: will be fired on "FIND_POST_OP" actions
function* fetchPostOp(action) {
  try {
    const response = yield axios.get(`api/post_op/${action.payload}`);
    yield put({ type: 'SET_POST_OP_FOUND', payload: response.data });
  } catch (error) {
    yield put({ type: 'SET_POST_OP_ERROR', payload: error });
    console.log('Post op get request failed', error);
  }
}

function* updatePostOp(action) {
  try {
      yield call(axios.put, `/api/post_op`, action.payload);
      yield put( { type: 'UPDATE_POST_OP_SUCCESS' , payload: action.payload} );
  } catch (error) {
      console.log(error);
      yield put({ type: 'SET_POST_OP_ERROR', payload: 'Error updating post op information. Please try again.' });

  }
}

function* postOpSaga() {
  yield takeLatest('FIND_POST_OP', fetchPostOp);
  yield takeLatest('UPDATE_POST_OP', updatePostOp);
}

export default postOpSaga;
