import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Divider, Typography } from "@material-ui/core";

const styles = theme => ({
    gridItem: {
        marginBottom: 10
    },
    container: {
        display: "flex",
        flexWrap: "wrap"
    },
    root: {
        display: "flex",
        flexWrap: "wrap",
        margin: "dense"
    }
});

class FollowupAdjuvantChemoPage extends Component {
    render() {
        const { classes, adjChemoOptions, biologicalOptions, adjuvant_chemo } = this.props;

        if (!adjChemoOptions || !biologicalOptions) return <></>;

        const otherChemoType = adjChemoOptions.find(opt => opt.option == "Other").id;
        const otherBiological = biologicalOptions.find(opt => opt.option == "Other").id;

        return (
            <>
                <Grid container className={classes.container} spacing={24}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Followup Treatment</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={6} md={4} className={classes.gridItem}>
                        <FormControl className={classes.root} autoComplete="off">
                            <InputLabel shrink={true} htmlFor="follow_up_adj_chemotherapy_type">
                                Chemotherapy Type
                            </InputLabel>
                            <Select
                                value={adjuvant_chemo.follow_up_adj_chemotherapy_type}
                                onChange={this.props.handleChange}
                                input={
                                    <Input
                                        name="follow_up_adj_chemotherapy_type"
                                        id="type"
                                        value={adjuvant_chemo.follow_up_adj_chemotherapy_type}
                                    />
                                }
                            >
                                {adjChemoOptions.map(adj => (
                                    <MenuItem key={adj.id} value={adj.id}>
                                        {adj.option}
                                    </MenuItem>
                                ))}
                            </Select>
                            {adjuvant_chemo.follow_up_adj_chemotherapy_type == otherChemoType && (
                                <TextField
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    margin="dense"
                                    onChange={this.props.handleChange}
                                    name="follow_up_adj_chemotherapy_other_type"
                                    value={adjuvant_chemo.follow_up_adj_chemotherapy_other_type}
                                    id="follow_up_adj_chemotherapy_other_type"
                                    placeholder="Enter Other Chemotherapy Type"
                                />
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} md={4} className={classes.gridItem}>
                        <FormControl className={classes.root} autoComplete="off">
                            <TextField
                                margin="dense"
                                onChange={this.props.handleChange}
                                name="follow_up_adj_chemotherapy_cycles"
                                value={adjuvant_chemo.follow_up_adj_chemotherapy_cycles}
                                id="follow_up_adj_chemotherapy_cyclesInput"
                                label="Cycles (n)"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                type="number"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">n</InputAdornment>
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} md={4} className={classes.gridItem}>
                        <FormControl className={classes.root} autoComplete="off">
                            <InputLabel shrink={true} htmlFor="follow_up_biological">
                                Biological
                            </InputLabel>
                            <Select
                                value={adjuvant_chemo.follow_up_biological}
                                onChange={this.props.handleChange}
                                input={<Input name="follow_up_biological" id="follow_up_biological" />}
                            >
                                {biologicalOptions.map(adj => (
                                    <MenuItem key={adj.id} value={adj.id}>
                                        {adj.option}
                                    </MenuItem>
                                ))}
                            </Select>
                            {String(adjuvant_chemo.follow_up_biological) == otherBiological && (
                                <TextField
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    margin="dense"
                                    onChange={this.props.handleChange}
                                    name="follow_up_biological_other"
                                    value={adjuvant_chemo.follow_up_biological_other}
                                    id="follow_up_biological_otherInput"
                                    placeholder="Enter Other Biological"
                                />
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} md={4} className={classes.gridItem}></Grid>
                    <Grid item xs={12} className={classes.gridItem}>
                        <FormControl className={classes.root} autoComplete="off">
                            <TextField
                                fullwidth="true"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                onChange={this.props.handleChange}
                                value={adjuvant_chemo.follow_up_notes}
                                name="follow_up_notes"
                                multiline
                                rows="5"
                                variant="outlined"
                                label="Notes"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </>
        );
    }
}

const mapStateToProps = reduxState => ({
    adjChemoOptions: reduxState.optionsReducer.adjChemoOptions,
    biologicalOptions: reduxState.optionsReducer.biologicalOptions
});

export default connect(mapStateToProps)(withStyles(styles)(FollowupAdjuvantChemoPage));
