import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
    root: {
        color: theme.palette.text.primary,
        margin: theme.spacing.unit * 3,
        padding: theme.spacing.unit * 2,
        textAlign: "center",
        fontSize: 16
    },
    paper: {
        margin: theme.spacing.unit * 3,
        padding: theme.spacing.unit * 2,
        textAlign: "center",
        color: theme.palette.text.primary
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        color: "#0337a7",
        ...theme.mixins.toolbar
    },
    alert: {
        color: "#0337a7"
    },
    item: {
        alignItems: "center",
        textAlign: "center"
    },
    container: {
        fontFamily: '"Lato", Arial, Helvetica, sans-serif',
        marginTop: 120,
        backgroundImage: ""
    },
    link: {
        margin: theme.spacing.unit,
        alignItems: "center",
        textAlign: "center",
        fontSize: 24
    },
});

class LoginPage extends Component {
    state = {
        username: "",
        password: ""
    };

    login = event => {
        event.preventDefault();
        if (this.state.username && this.state.password) {
            this.props.dispatch({
                type: "LOGIN",
                payload: {
                    username: this.state.username,
                    password: this.state.password
                }
            });
        } else {
            this.props.dispatch({ type: "LOGIN_INPUT_ERROR" });
        }
    }; // end login

    handleInputChangeFor = propertyName => event => {
        this.setState({
            [propertyName]: event.target.value
        });
    };

    render() {
        const { classes } = this.props;
        return (
            <>
                <div className={classes.toolbar} />
                {!this.props.user.id && (
                    <Grid container className={classes.container} spacing={24} alignItems="center" justify="center">
                        <Grid item xs={6} sm={4} className={classes.item}>
                            <Paper className={classes.paper}>
                                <Grid item xs={12} className={classes.item}>
                                    <TextField
                                        className={classes.textField}
                                        onChange={this.handleInputChangeFor("username")}
                                        name="username"
                                        value={this.state.username}
                                        type="text"
                                        id="username"
                                        label="Username"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        className={classes.textField}
                                        onChange={this.handleInputChangeFor("password")}
                                        name="password"
                                        value={this.state.password}
                                        type="password"
                                        id="password"
                                        label="Password"
                                    />
                                </Grid>
                                <Grid item xs={12} className={classes.item} style={{ marginTop: 50 }}>
                                    <Button onClick={this.login} variant="contained" color="primary">
                                        Login
                                    </Button>
                                </Grid>
                                {this.props.errors.loginMessage && (
                                    <Grid item xs={12} className={classes.item}>
                                        <h4 className={classes.textField} role="alert">
                                            {this.props.errors.loginMessage}
                                        </h4>
                                    </Grid>
                                )}
                            </Paper>
                            <Grid item xs={12}>
                                <Typography className={classes.root}></Typography>
                                <Link href="https://hipecregistry.com/join-our-registry/" className={classes.link}>
                                    Join Our Registry
                                </Link>
                            </Grid>
                            <Grid alignItems="center">
                                <Typography className={classes.root} align="center">
                                    Supported By
                                </Typography>
                                <img alt="Supported by ThermaSolutions" src="images/ts-logo.png" height="30" />
                                <br></br>
                                <img alt="Supported by " src="images/LARPD.jpg" height="60" />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </>
        );
    }
}

// Instead of taking everything from state, we just want the error messages.
// if you wanted you could write this code like this:
// const mapStateToProps = ({errors}) => ({ errors });
const mapStateToProps = reduxState => ({
    errors: reduxState.errors,
    user: reduxState.user
});

export default connect(mapStateToProps)(withStyles(styles)(LoginPage));
