
const initialState = {
  data: [],
  loading: false,
  error: ''
};

const adverseEventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_ADVERSE_EVENT':
        return {
            ...state,
            data: [],
            loading: true,
            error: '',
            updateSuccess: false
      };
    case 'SET_ADVERSE_EVENT':
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: '',
          updateSuccess: false
          };
    case 'SET_ADVERSE_EVENT_ERROR':
        return {
            ...state,
            loading: false,
            error: 'Error reading adverse event details.',
            updateSuccess: false
        };
    case 'UPDATE_ADVERSE_EVENT_SUCCESS':
        return {
          ...state,
          data: action.payload.adverse_events,
          loading: false,
          error: '',
          updateSuccess: true
        };
    case 'CLEAR_ADVERSE_EVENT_ERROR':
        return {
          ...state,
          loading: false,
          error: '',
          updateSuccess: false
      };
    default:
      return state;
  }
};

// adverseEvents will be on the redux state at:
// state.adverseEvents
export default adverseEventsReducer;