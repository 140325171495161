import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import PropTypes from "prop-types";
import Input from "@material-ui/core/Input";

const styles = theme => ({
    formControl: {
        margin: theme.spacing.unit
    }
});

class Modality extends Component {
    renderOptions(recModalityOptions) {
        return recModalityOptions.map((opt, i) => {
            return (
                <MenuItem key={i} value={opt.id}>
                    {opt.option}
                </MenuItem>
            ); // end return
        }); // end map
    } // end renderTagOptions

    render() {
        const { classes, recModalityOptions } = this.props;
        if (!recModalityOptions) return <></>;
        return (
            <FormControl fullWidth={true} margin="dense" className={classes.formControl}>
                <InputLabel shrink htmlFor="rec_modality">
                    Modality
                </InputLabel>
                <Select
                    value={this.props.recurrence.rec_modality}
                    onChange={this.props.handleChange}
                    input={<Input value={this.props.recurrence.rec_modality} name="rec_modality" id="rec_modality" />}
                >
                    {this.renderOptions(recModalityOptions)}
                </Select>
            </FormControl>
        );
    } // end return
} // end class Modality

Modality.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapReduxStateToProps = reduxState => ({
    recModalityOptions: reduxState.optionsReducer.recModalityOptions
});

export default connect(mapReduxStateToProps)(withStyles(styles)(Modality));
