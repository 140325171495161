import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import Notification from "../Notification";
import moment from "moment";

const styles = theme => ({
    table: {
        minWidth: 1060
    },
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2
    },
});

class OperativeNotesPage extends Component {
    state = {
        changesMade: false,
        localLoading: true,
        errorOpen: true,
        note: "",
        userId: "",
        title: "",
        firstName: "",
        lastName: ""
    };

    handleChange = event => {
        this.setState({
            changesMade: true,
            [event.target.name]: event.target.value
        });
    };

    saveClicked = () => {
        const { note } = this.state;
        const { patientId, user } = this.props;

        if (this.state.changesMade) {
            this.props.dispatch({
                type: "ADD_OPERATIVE_NOTE",
                payload: {
                    operativeNotes: note,
                    userId: patientId,
                    title: user.title,
                    firstName: user.first_name,
                    lastName: user.last_name
                }
            });
            this.setState({
                changesMade: false,
                note: ""
            });
        }
    };

    setInitialState = () => {
        this.setState(
            {
                ...this.state,
                changesMade: false,
                errorOpen: true
            },
            () => {
                this.setState({
                    ...this.state,
                    localLoading: false
                });
            }
        );
    };

    handleCloseError = () => {
        this.setState({ ...this.state, errorOpen: false });
        this.props.dispatch({ type: "CLEAR_OPERATIVE_NOTES_ERROR" });
        this.setInitialState();
    };

    componentDidMount() {
        this.props.onRef(this);
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        const { patientId } = this.props;
        this.props.dispatch({ type: "GET_OPERATIVE_NOTES", payload: patientId });
        this.setInitialState();
    }

    componentWillUnmount() {
        if (this.state.changesMade) {
            if (
                window.confirm(
                    "You have made changes that have not been saved. Click on OK to save these changes or Cancel to discard them."
                )
            ) {
                this.saveClicked();
            }
        }
        this.props.onRef(undefined);
    }

    render() {
        const { classes, operativeNotes, loading, error } = this.props;
        const { note, localLoading, errorOpen } = this.state;

        if (loading || localLoading) {
            return (
                <>
                    <div className={classes.toolbar} />
                    <Grid container className={classes.progress} spacing={24} alignItems="center" justify="center">
                        <CircularProgress></CircularProgress>
                    </Grid>
                </>
            );
        }

        return (
            <>
                <Grid container spacing={24}>
                    {error && <Notification open={errorOpen} error={true} handleCloseError={this.handleCloseError} message={error} />}
                    <Grid item>
                        <Card className={classes.paper}>
                            <TextField
                                onChange={this.handleChange}
                                value={note}
                                name="note"
                                margin="dense"
                                id="note"
                                label="Operative Notes"
                                type="text"
                                fullWidth={true}
                                multiline
                                rows="10"
                                variant="outlined"
                            />
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Created By</TableCell>
                                        <TableCell>Operative Notes</TableCell>
                                        <TableCell>Note Completion Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {operativeNotes &&
                                        operativeNotes.map(note => {
                                            return (
                                                <TableRow>
                                                    <TableCell>
                                                        {note.title} {note.last_name}, {note.first_name}
                                                    </TableCell>
                                                    <TableCell>{note.operative_notes}</TableCell>
                                                    <TableCell>{moment(note.date_completed).format("LLLL")}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </Card>
                    </Grid>
                </Grid>
            </>
        );
    }
}

OperativeNotesPage.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = reduxState => ({
    patientId: reduxState.patientReducer.data.id,
    user: reduxState.user,
    operativeNotes: reduxState.operativeNotesReducer.data,
    loading: reduxState.operativeNotesReducer.loading,
    error: reduxState.operativeNotesReducer.error
});

export default connect(mapStateToProps, null, null, { withRef: true })(withStyles(styles)(OperativeNotesPage));
