import React, { Component } from "react";
import { HashRouter as Router, Route, Redirect, Switch, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import PersonIcon from "@material-ui/icons/Person";
import appTheme from "./AppTheme";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";
import AboutPage from "../AboutPage/AboutPage";
import InfoPage from "../InfoPage/InfoPage";
import PatientInformation from "../PatientInformation/PatientInformation";
import ManageUsersPage from "../ManageUsersPage/ManageUsersPage";
import Patients from "../Patients/Patients";
import ResearchPage from "../ResearchPage/ResearchPage";
import LogOutButton from "../LogOutButton/LogOutButton";
import LoginPage from "../LoginPage/LoginPage";
import "./App.css";
import appImage from "../../hipec-appbar.jpg";

const styles = theme => ({
    root: {
        // display: "flex"
    },
    grow: {
        flexGrow: 1,
        color: "#0337a7",
        fontFamily: "'Lato', 'Arial', 'Helvetica', sans-serif"
    },
    appBar: {
        zIndex: theme.zIndex + 9999999999,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    // button: {
    //     marginLeft: theme.spacing.unit * 1
    // },
    // menuButton: {
    //     marginRight: 36
    // },
    navLink: {
        display: "inline",
        color: "#27358c",
        paddingLeft: 20,
        textTransform: "uppercase",
        textDecoration: "none",
        fontFamily: "'Lato', 'Arial', 'Helvetica', sans-serif",
        letterSpacing: ".02em"
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        ...theme.mixins.toolbar
    }
    // content: {
    //     flexGrow: 1,
    //     paddingTop: 140
    // }
});

class App extends Component {
    componentDidMount() {
        this.props.dispatch({ type: "FETCH_USER" });
        this.props.dispatch({ type: "GET_OPTIONS" });
    }

    userName() {
        if (this.props.user.id) {
            const { title, first_name, last_name } = this.props.user;
            let fullName =
                (title === null ? "" : title) + " " + (first_name === null ? "" : first_name) + " " + (last_name === null ? "" : last_name);
            return fullName;
        }
        return "";
    }
    render() {
        const { classes, user } = this.props;
        if (!user) {
            return <></>;
        }

        return (
            <Router>
                <div className={classes.root}>
                    <CssBaseline />
                    <MuiThemeProvider theme={appTheme}>
                        <AppBar
                            elevation={1}
                            className={classes.appbar}
                            style={{ background: "url(" + appImage + ") 0 28%", backgroundSize: "cover" }}
                            position="fixed"
                        >
                            <Toolbar className={classes.toolbar}>
                                <Typography className={classes.grow}>
                                    <img alt="HIPEC-Logo" src="images/hipec-patient-registry-logo.png" height="40" position="middle" />
                                </Typography>
                                {(user.access_level === 1 || user.access_level === 2) && (
                                    <NavLink className={classes.navLink} to="/research">
                                        Research
                                    </NavLink>
                                )}
                                {user.access_level === 2 && (
                                    <NavLink className={classes.navLink} to="/patients">
                                        Patients
                                    </NavLink>
                                )}
                                {user.access_level === 3 && (
                                    <NavLink className={classes.navLink} to="/admin">
                                        Admin
                                    </NavLink>
                                )}
                                {user.id && <LogOutButton className="link-button" />}
                                {!user.id && (
                                    <NavLink className={classes.navLink} to="/">
                                        Login
                                    </NavLink>
                                )}
                                {user.id && (
                                    <>
                                        <Typography className={classes.navLink}>
                                            <IconButton disabled color="inherit">
                                                <PersonIcon />
                                            </IconButton>
                                            {this.userName()}
                                        </Typography>
                                    </>
                                )}
                            </Toolbar>
                        </AppBar>
                        <Switch>
                            {/* Visiting localhost:3000 will redirect to localhost:3000/ depending upon access_level */}
                            {!user.id && <Redirect exact from="/" to="/login" />}
                            {user.access_level === 1 && <Redirect exact from="/" to="/research" />}
                            {user.access_level === 2 && <Redirect exact from="/" to="/patients" />}
                            {user.access_level === 3 && <Redirect exact from="/" to="/admin" />}
                            {user.access_level === 1 && <Redirect exact from="/login" to="/research" />}
                            {user.access_level === 2 && <Redirect exact from="/login" to="/patients" />}
                            {user.access_level === 3 && <Redirect exact from="/login" to="/admin" />}

                            {/* Visiting localhost:3000/about will show the about page.
                            This is a route anyone can see, no login necessary */}
                            <Route exact path="/about" component={AboutPage} />
                            {!user.id && <Route exact path="/login" component={LoginPage} />}
                            {(user.access_level === 1 || user.access_level === 2) && (
                                <ProtectedRoute exact path="/research" component={ResearchPage} />
                            )}
                            {user.access_level === 2 && <ProtectedRoute exact path="/patients" component={Patients} />}
                            <ProtectedRoute exact path="/info" component={InfoPage} />
                            <ProtectedRoute exact path="/patient-info" component={PatientInformation} />
                            {user.access_level === 3 && <ProtectedRoute path="/admin" component={ManageUsersPage} />}
                            {/* If none of the other routes matched, we will show a 404. */}
                            <Route render={() => <h1>404</h1>} />
                        </Switch>
                    </MuiThemeProvider>
                </div>
            </Router>
        );
    }
}

const mapStateToProps = reduxState => ({
    user: reduxState.user
});

export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(App));
