import { put, takeLatest, call } from "redux-saga/effects";
import axios from "axios";

function* researchData(action) {
    try {
        const response = yield call(axios.post, `/getResearchData/`, action.payload);
        yield put({ type: "SET_RESEARCH_DATA", payload: response.data });
    } catch (error) {
        yield put({ type: "GET_RESEARCH_ERROR", payload: error });
        console.log("error with getting request", error);
    }
}

function* getResearchDataSaga() {
    yield takeLatest("GET_RESEARCH_DATA", researchData);
}

export default getResearchDataSaga;
