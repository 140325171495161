import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import moment from "moment";

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit
    },
});

class AddFollowUpDialog extends Component {
    state = {
        open: false,
        id: 0,
        patient_id: 0,
        follow_up_date: null
    };

    componentDidMount() {
        this.setState({
            patient_id: this.props.patient.id
        });
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    // Called when the input field changes
    handleChange = event => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        });
    };

    addFollowUpHistory = () => {
        this.props.dispatch({ type: "ADD_FOLLOW_UP_HISTORY", payload: this.state });
        this.props.dispatch({ type: "FETCH_FOLLOW_UP_HISTORY", payload: this.state.patient_id });
        this.setState({ open: false });
        this.setState({ date: null });
    };

    render() {
        const { classes } = this.props;

        return (
            <>
                <Button variant="contained" color="primary" onClick={this.handleClickOpen} style={{ float: "right" }}>
                    Add Follow Up Visit
                </Button>
                <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Add Follow Up Visit</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Enter the new follow up date to add to the list.</DialogContentText>
                        <TextField
                            name="follow_up_date"
                            label="Follow Up Date"
                            className={classes.textField}
                            value={moment(this.state.follow_up_date).format("YYYY-MM-DD")}
                            // fullWidth
                            type="date"
                            onChange={this.handleChange}
                            margin="normal"
                            // variant="outlined"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.addFollowUpHistory} color="primary">
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

const mapStateToProps = reduxState => ({
    patient: reduxState.patientReducer.data
});

export default connect(mapStateToProps)(withStyles(styles)(AddFollowUpDialog));
