import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

// worker SAGA: will be fired on 'ADD_FOLLOW_UP_HISTORY' action
function* addFollowUpHistory(action) {
  try {
      // axios asynch call to add item on database
      yield call(axios.post, '/api/follow_up_history', action.payload);
      yield put( { type: 'FETCH_FOLLOW_UP_HISTORY' , payload: action.payload.patient_id} );
  }
  catch (error) {
      console.log('error with add follow up history post request');
  }
}

// worker Saga: will be fired on "FETCH_FOLLOW_UP_HISTORY" actions
function* fetchFollowUpHistory(action) {
  try {
    const response = yield axios.get(`api/follow_up_history/${action.payload}`);
    // set state
    yield put({ type: 'SET_FOLLOW_UP_HISTORY', payload: response.data });
  } catch (error) {
    yield put({ type: 'FOLLOW_UP_HISTORY_ERROR', payload: error });
    console.log('follow up history get request failed', error);
  }
}

function* updateFollowUpHistory(action) {
  try {
      yield call(axios.put, `/api/follow_up_history`, action.payload);
      yield put( { type: 'FETCH_FOLLOW_UP_HISTORY', payload: action.payload.patient_id} );
  } catch (error) {
      console.log(error);
      yield put({ type: 'FOLLOW_UP_HISTORY_ERROR', payload: error });
  }
}

function* followUpHistorySaga() {
  yield takeLatest('ADD_FOLLOW_UP_HISTORY', addFollowUpHistory);
  yield takeLatest('FETCH_FOLLOW_UP_HISTORY', fetchFollowUpHistory);
  yield takeLatest('UPDATE_FOLLOW_UP_HISTORY', updateFollowUpHistory);
}

export default followUpHistorySaga;
