import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles, createMuiTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import Divider from "@material-ui/core/Divider";
import CssBaseline from "@material-ui/core/CssBaseline";
import PrimaryTumorPage from "../PrimaryTumorPage/PrimaryTumorPage";
import IntakePage from "../IntakePage/IntakePage";
import PSDSSPage from "../PSDSSPage/PSDSSPage";
import InterventionPage from "../InterventionPage/InterventionPage";
import PathologyNotesPage from "../PathologyNotesPage/PathologyNotesPage";
import OperativeNotesPage from "../OperativeNotesPage/OperativeNotesPage";
import PostOpPage from "../PostOpPage/PostOpPage";
import FollowUpPage from "../FollowUpPage/FollowUpPage";
import PatientDetail from "./PatientDetail";
import ColorectalTheme from "../App/ColorectalTheme";
import AppendixTheme from "../App/AppendixTheme";
import GastricTheme from "../App/GastricTheme";
import MesotheliomaTheme from "../App/MesotheliomaTheme";
import OtherTheme from "../App/OtherTheme";
import OvarianTheme from "../App/OvarianTheme";
import PseudomyxomaTheme from "../App/PseudomyxomaTheme";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import appTheme from "../App/AppTheme";
import RegistryList from "./RegistryList";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Typography from "@material-ui/core/Typography";

const drawerWidth = 240;

const styles = theme => ({
    root: {
        display: "flex",
        backgroundColor: "#f0f8ff"
    },
    progress: {
        margin: theme.spacing.unit * 2,
        padding: theme.spacing.unit * 3
    },
    appBarPatient: {
        backgroundColor: "#fff",
        zIndex: theme.zIndex.appBar - 100,
        paddingTop: 75,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    chip: {
        margin: theme.spacing.unit * 1
    },
    menuButton: {},
    hide: {
        display: "none"
    },
    drawer: {
        width: drawerWidth,
        paddingTop: 80,
        flexShrink: 0,
        zIndex: 100,
        whiteSpace: "nowrap"
    },
    drawerOpen: {
        width: drawerWidth,
        paddingTop: 80,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerClose: {
        paddingTop: 80,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
        width: theme.spacing.unit * 6 + 1,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing.unit * 7 + 1
        }
    },
    toolbar: {
        display: "flex",
        color: "primary",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing.unit * 1,
        ...theme.mixins.toolbar
    },
    toolbarPatient: {
        display: "flex",
        ...theme.mixins.toolbar
    },
    appbar: {
        backgroundColor: "#ffffff",
        color: "#0337a7",
        marginTop: 40
    },
    content: {
        display: "flex",
        padding: theme.spacing.unit * 3,
        marginTop: 130
    },
    button: {
        padding: theme.spacing.unit * 1,
        margin: theme.spacing.unit * 1,
        backgroundColor: "#fff"
    },
    title: {
        fontWeight: 700
    },
    item: {
        marginLeft: 20
    }
});

class PatientInformation extends Component {
    constructor(props) {
        super(props);
        this.child = React.createRef();
    }

    state = {
        open: true,
        loading: true,
        selectedIndex: 0,
        toc: "",
        UserAccessLevel: "",
        themeToUse: createMuiTheme(appTheme)
    };

    componentDidMount() {
        const patientId = document.cookie.replace(/(?:(?:^|.*;\s*)patientID\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        const patientTOC = document.cookie.replace(/(?:(?:^|.*;\s*)patientTOC\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        this.setState({ toc: patientTOC });
        let uAccessLevel = document.cookie.replace(/(?:(?:^|.*;\s*)userAccessLevel\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        this.setState({ UserAccessLevel: uAccessLevel });
        this.props.dispatch({ type: "FIND_PATIENT", payload: patientId });
        this.props.dispatch({ type: "GET_PRIMARY_TUMOR", payload: patientId });
        this.props.dispatch({ type: "GET_INTAKE", payload: patientId });
        this.props.dispatch({ type: "GET_PSDSS", payload: patientId });
        this.props.dispatch({ type: "GET_INTERVENTION", payload: patientId });
        this.props.dispatch({ type: "FIND_POST_OP", payload: patientId });
        this.props.dispatch({ type: "FETCH_ADVERSE_EVENT", payload: patientId });
        this.props.dispatch({ type: "FETCH_FOLLOW_UP", payload: patientId });
        this.props.dispatch({ type: "FETCH_FOLLOW_UP_HISTORY", payload: patientId });
        this.updateTheme(patientTOC);
    }

    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };

    handleListItemClick = (event, index) => {
        this.setState({ selectedIndex: index });
    };

    triggerSave = () => {
        this.child.saveClicked();
    };

    updateTheme = patientTOC => {
        switch (patientTOC) {
            case 1:
                this.setState({ themeToUse: ColorectalTheme });
                break;
            case 2:
                this.setState({ themeToUse: AppendixTheme });
                break;
            case 3:
                this.setState({ themeToUse: GastricTheme });
                break;
            case 4:
                this.setState({ themeToUse: OvarianTheme });
                break;
            case 5:
                this.setState({ themeToUse: MesotheliomaTheme });
                break;
            case 6:
                this.setState({ themeToUse: PseudomyxomaTheme });
                break;
            case 7:
                this.setState({ themeToUse: OtherTheme });
                break;
            default:
                this.setState({ themeToUse: appTheme });
        }
    };

    render() {
        const { classes, patient, loading, intervention, cancerOptions } = this.props;
        const { selectedIndex, open, themeToUse } = this.state;

        const patientTOC = document.cookie.replace(/(?:(?:^|.*;\s*)patientTOC\s*\=\s*([^;]*).*$)|^.*$/, "$1");

        if (loading || !cancerOptions) {
            return (
                <>
                    <div className={classes.root} />
                    <Grid container className={classes.progress} spacing={24} alignItems="center" justify="center">
                        <CircularProgress></CircularProgress>
                    </Grid>
                </>
            );
        }

        return (
            <div className={classes.root}>
                <MuiThemeProvider theme={themeToUse}>
                    <CssBaseline />
                    <AppBar
                        position="fixed"
                        color="secondary"
                        className={clsx(classes.appBarPatient, {
                            [classes.appBarShift]: open
                        })}
                    >
                        <Toolbar className={classes.toolbarPatient}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={this.handleDrawerOpen}
                                edge="start"
                                className={clsx(classes.menuButton, {
                                    [classes.hide]: open
                                })}
                            >
                                <MenuIcon />
                            </IconButton>

                            {patient && (
                                <Grid container justify="flex-start">
                                    <Grid item className={classes.item}>
                                        <Typography align="center" variant="h6">
                                            {patient.id}
                                        </Typography>
                                        <Typography className={classes.title} color="secondary" gutterBottom variant="body2">
                                            PATIENT ID
                                        </Typography>
                                    </Grid>
                                    <Grid item className={classes.item}>
                                        <Typography align="center" variant="h6">
                                            {(patient.patient_initials && patient.patient_initials.toUpperCase()) || "Not Entered"}
                                        </Typography>
                                        <Typography className={classes.title} color="secondary" gutterBottom variant="body2">
                                            PATIENT INITIALS
                                        </Typography>
                                    </Grid>
                                    <Grid item className={classes.item}>
                                        <Typography align="center" variant="h6">
                                            {cancerOptions.find(t => t.id == patientTOC).name}
                                        </Typography>
                                        <Typography className={classes.title} color="secondary" gutterBottom variant="body2">
                                            TYPE OF CANCER
                                        </Typography>
                                    </Grid>
                                    <Grid item className={classes.item}>
                                        <Typography align="center" variant="h6">
                                            {intervention
                                                ? String(
                                                      Number(intervention.pci_0) +
                                                          Number(intervention.pci_1) +
                                                          Number(intervention.pci_2) +
                                                          Number(intervention.pci_3) +
                                                          Number(intervention.pci_4) +
                                                          Number(intervention.pci_5) +
                                                          Number(intervention.pci_6) +
                                                          Number(intervention.pci_7) +
                                                          Number(intervention.pci_8) +
                                                          Number(intervention.pci_9) +
                                                          Number(intervention.pci_10) +
                                                          Number(intervention.pci_11) +
                                                          Number(intervention.pci_12)
                                                  )
                                                : "Not Calculated"}
                                        </Typography>
                                        <Typography className={classes.title} color="secondary" gutterBottom variant="body2">
                                            PCI SCORE
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                            <Button variant="contained" color="primary" style={{ marginBottom: 10 }} onClick={this.triggerSave}>
                                Save
                            </Button>
                            {/* <Button disabled variant="contained" color="default">
                                Cancel
                            </Button> */}
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        variant="permanent"
                        className={classNames(classes.drawer, {
                            [classes.drawerOpen]: this.state.open,
                            [classes.drawerClose]: !this.state.open
                        })}
                        classes={{
                            paper: classNames({
                                [classes.drawerOpen]: this.state.open,
                                [classes.drawerClose]: !this.state.open
                            })
                        }}
                        open={this.state.open}
                    >
                        <div className={classes.toolbar}>
                            <IconButton onClick={this.handleDrawerClose}>
                                {themeToUse.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                            </IconButton>
                        </div>
                        <Divider />
                        <RegistryList selectedIndex={selectedIndex} handleListItemClick={this.handleListItemClick} />
                    </Drawer>
                    <main className={classes.content}>
                        <>
                            {selectedIndex === 0 && patient && (
                                <PatientDetail onRef={ref => (this.child = ref)} patientId={patient.id} updateTheme={this.updateTheme} />
                            )}
                            {selectedIndex === 1 && patient && (
                                <PrimaryTumorPage onRef={ref => (this.child = ref)} patientId={patient.id} />
                            )}
                            {selectedIndex === 2 && patient && <IntakePage onRef={ref => (this.child = ref)} patientId={patient.id} />}
                            {selectedIndex === 3 && patient && (
                                <InterventionPage onRef={ref => (this.child = ref)} patientId={patient.id} />
                            )}
                            {selectedIndex === 4 && patient && <PSDSSPage onRef={ref => (this.child = ref)} patientId={patient.id} />}
                            {selectedIndex === 5 && patient && (
                                <PathologyNotesPage onRef={ref => (this.child = ref)} patientId={patient.id} />
                            )}
                            {selectedIndex === 6 && patient && (
                                <OperativeNotesPage onRef={ref => (this.child = ref)} patientId={patient.id} />
                            )}
                            {selectedIndex === 7 && patient && <PostOpPage onRef={ref => (this.child = ref)} patientId={patient.id} />}
                            {selectedIndex === 8 && patient && <FollowUpPage onRef={ref => (this.child = ref)} patientId={patient.id} />}
                        </>
                    </main>
                </MuiThemeProvider>
            </div>
        );
    }
}

const mapStateToProps = reduxState => ({
    patient: reduxState.patientReducer.data,
    loading: reduxState.patientReducer.loading,
    error: reduxState.patientReducer.error,
    intervention: reduxState.interventionReducer.data,
    cancerOptions: reduxState.optionsReducer.cancerOptions
});

PatientInformation.propTypes = {
    classes: PropTypes.object.isRequired,
    // Injected by the documentation to work in an iframe.
    // You won't need it on your project.
    container: PropTypes.object,
    theme: PropTypes.object,
    match: PropTypes.shape({
        isExact: PropTypes.bool,
        path: PropTypes.string.isRequired,
        params: PropTypes.shape({
            patientId: PropTypes.string
        }),
        url: PropTypes.string
    }).isRequired
};

export default connect(mapStateToProps, null, null, { withRef: true })(withStyles(styles)(PatientInformation));
