import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FollowUpDetailDetail from "./FollowUpDetailDetail";
import moment from "moment";
import { Typography } from "@material-ui/core";

const styles = theme => ({
    superCool: {
        backgroundColor: "#eeeeee",
        height: 25
    }
});

class FollowUpDetail extends Component {
    handleCloseError = () => {
        this.setState({
            ...this.state,
            errorOpen: false
        });
        this.props.dispatch({ type: "CLEAR_FOLLOW_UP_HISTORY_ERROR" });
        this.setInitialState();
    };

    render() {
        const { classes, followUpHistory } = this.props;

        return (
            <>
                {followUpHistory.map((history, index) => (
                    <div key={history.id}>
                        <ExpansionPanel defaultExpanded>
                            <ExpansionPanelSummary className={classes.superCool} expandIcon={<ExpandMoreIcon />}>
                                {history.follow_up_date ? (
                                    <Typography variant="h6">Visit on {moment(history.follow_up_date).format("MM-DD-YYYY")}</Typography>
                                ) : (
                                    <Typography variant="h6">Enter Details</Typography>
                                )}
                            </ExpansionPanelSummary>
                            <FollowUpDetailDetail history={history} />
                        </ExpansionPanel>
                    </div>
                ))}
            </>
        );
    }
}

const mapStateToProps = reduxState => ({
    followUpHistory: reduxState.followUpHistory.data
});

export default connect(mapStateToProps)(withStyles(styles)(FollowUpDetail));
