import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";

const styles = theme => ({
    snackbarError: {
        backgroundColor: "#CC0000",
        color: "#FFFFFF"
    },
    snackbarSuccess: {
        backgroundColor: "#00b800",
        color: "#FFFFFF"
    }
});

class Notification extends Component {
    render() {
        const { classes, open, error, message, handleCloseError } = this.props;

        return error ? (
            <Snackbar
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                autoHideDuration={3000}
                ContentProps={{
                    classes: {
                        root: classes.snackbarError
                    }
                }}
                open={open}
                onClose={handleCloseError}
                message={message}
            ></Snackbar>
        ) : (
            <Snackbar
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                autoHideDuration={3000}
                ContentProps={{
                    classes: {
                        root: classes.snackbarSuccess
                    }
                }}
                open={open}
                onClose={handleCloseError}
                message={message}
            ></Snackbar>
        );
    }
}

export default withStyles(styles)(Notification);
