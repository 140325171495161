import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Input from "@material-ui/core/Input";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import ToolbarNewButton from "../Shared/ToolbarNewButton";
import Notification from "../Notification";

const styles = theme => ({
    root: {
        // flexGrow: 1,
        // flexWrap: "wrap",
        // alignItems: "center",
        paddingTop: 75
    },
    progress: {
        margin: theme.spacing.unit * 2,
        padding: theme.spacing.unit * 3
    },
    formControl: {
        margin: theme.spacing.unit
        // minWidth: 500,
        // verticalAlign: 'unset',
        // marginLeft: -50,
        // marginTop: -400
    },
    formControl2: {
        margin: theme.spacing.unit,
        // minWidth: 500,
        verticalAlign: "unset"
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2
    },
    radio: {
        display: "inline-block"
    },
    formLabel: {
        diaplay: "none"
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: "left",
        color: theme.palette.text.secondary
        // width:850,
        // height:600,
        // marginLeft: 250,
    },
    button: {
        // minWidth: 500,
        // marginBottom: 30
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        // padding: "0 8px",
        ...theme.mixins.toolbar
    }
});

class Patients extends Component {
    state = {
        openNewPatient: false,
        errorOpen: true,
        labelWidth: 0,
        name: "",
        patient: {
            user_id: this.props.user.id,
            patient_no: "",
            patient_initials: "",
            dob: "",
            age: 0,
            gender: "",
            hipec_date: null,
            hipec: "",
            referral_date: null,
            toc_id: "",
            diagnosis_date: null,
            alive_on_date: moment(new Date()).format("YYYY-MM-DD"),
            hospital_telephone: "",
            referring_doctor: "",
            current_status: null,
            interval_prime_surgery: 0,
            survival_hipec_death: 0,
            survival_hipec_last_contact: 0,
            interval_diagnosis_pc_hipec: 0,
            hospital_id: ""
        }
    };

    userName() {
        const { title, first_name, last_name } = this.props.user;
        let fullName =
            (title === null ? "" : title) + " " + (first_name === null ? "" : first_name) + " " + (last_name === null ? "" : last_name);
        return fullName;
    }

    componentDidMount() {
        this.props.dispatch({ type: "ALL_MY_PATIENTS", payload: this.props.user.id });
        this.props.dispatch({ type: "GET_OPTIONS" });
    }

    handleNewPatientChange = name => event => {
        this.setState({
            patient: { ...this.state.patient, [name]: event.target.value }
        });
    };

    calculateAge = () => event => {
        let age = Math.floor((new Date() - new Date(event.target.value).getTime()) / 3.15576e10);
        this.setState({ patient: { ...this.state.patient, age: age } });
    };

    handleSearchChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    clickNewPatient = () => {
        const { access_level } = this.props.user;
        if (access_level !== 2) {
            alert("You are a Researcher or Administrator and do not have User Rights to create a new Patient");
        } else {
            this.setState({
                ...this.state,
                errorOpen: true,
                openNewPatient: true,
                patient: { ...this.state.patient, patient_no: this.state.patient.id }
            });
        }
    };

    handleClose = () => {
        this.setState({
            ...this.state,
            openNewPatient: false,
            patient: {}
        });
    };

    handleCloseError = () => {
        this.setState({
            ...this.state,
            errorOpen: false
        });
        this.props.dispatch({ type: "CLEAR_ALL_MY_PATIENTS_ERROR" });
    };

    addPatient = () => {
        this.props.dispatch({
            type: "ADD_PATIENT",
            payload: {
                userId: this.props.user.id,
                patientObject: this.state.patient,
                history: this.props.history
            }
        });
        this.handleClose();
    };

    selectPatient = rowData => {
        this.props.dispatch({ type: "CLEAR_PATIENT" });
        document.cookie = `patientID=${rowData[0]}`;
        document.cookie = `patientTOC=${rowData[8]}`;
        this.props.history.push(`/patient-info`);
    };

    render() {
        const { classes, allPatients, loading, error, addSuccess, currentStatusOptions } = this.props;
        const { errorOpen, openNewPatient } = this.state;

        if (loading || !currentStatusOptions) {
            return (
                <>
                    <div className={classes.root} />
                    <Grid container className={classes.progress} spacing={24} alignItems="center" justify="center">
                        <CircularProgress></CircularProgress>
                    </Grid>
                </>
            );
        }

        if (error) {
            return (
                <>
                    <div className={classes.root} />
                    <Notification open={errorOpen} error={true} handleCloseError={this.handleCloseError} message={error} />
                </>
            );
        }

        const columns = [
            {
                name: "id",
                label: " ",
                options: {
                    display: "false",
                    viewColumns: false
                }
            },
            {
                name: "id",
                label: "Patient ID",
                options: {
                    filter: true,
                    sort: true
                }
            },
            {
                name: "patient_initials",
                label: "Patient Initials",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: tableMeta => {
                        return tableMeta ? tableMeta.toUpperCase() : ``;
                    }
                }
            },
            {
                name: "type_of_cancer",
                label: "Type of Cancer",
                options: {
                    filter: true,
                    sort: true
                }
            },
            {
                name: "hospital_id",
                label: "Hospital ID",
                options: {
                    filter: true,
                    sort: true
                }
            },
            {
                name: "dob",
                label: "Date of Birth",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: value => {
                        return moment(value).format("MM-DD-YYYY");
                    }
                }
            },
            {
                name: "gender",
                label: "Gender",
                options: {
                    filter: true,
                    sort: true
                }
            },
            {
                label: " ",
                name: "action",
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <IconButton className={classes.iconButton} onClick={() => this.selectPatient(tableMeta.rowData)}>
                                <EditIcon className={classes.addIcon} />
                            </IconButton>
                        );
                    }
                }
            },
            {
                name: "toc_id",
                label: "TOC",
                options: {
                    display: "false",
                    viewColumns: false
                }
            }
        ];

        const options = {
            rowsPerPage: 100,
            fixedHeader: true,
            selectableRows: "none",
            viewColumns: true,
            responsive: "scroll",
            filter: true,
            filterType: "textField",
            download: true,
            print: false,
            downloadOptions: {
                filename: "patient-data.csv",
                separator: ",",
                filterOptions: {
                    useDisplayedColumnsOnly: true,
                    useDisplayedRowsOnly: true
                }
            },
            customToolbar: () => {
                return <ToolbarNewButton onClickNew={this.clickNewPatient} tooltipText={"New Patient"} />;
            }
        };

        // const options = {
        //     download: false,
        //     print: false,
        //     fixedHeader: true,
        //     selectableRows: false,
        //     viewColumns: false,
        //     customToolbar: () => {
        //         return <CustomToolbar clickNewPatient={this.clickNewPatient} />;
        //     }
        // };

        return (
            <div className={classes.root}>
                {addSuccess && (
                    <Notification open={errorOpen} error={false} handleCloseError={this.handleCloseError} message={"New Patient Added"} />
                )}
                <Grid container>
                    <Grid item xs={12}>
                        <MUIDataTable title="Patients" data={allPatients} columns={columns} options={options} className={classes.button} />
                    </Grid>
                </Grid>
                <>
                    {/* Add New Patient Pop Up Form */}
                    <Dialog fullWidth disableBackdropClick open={openNewPatient} onClose={this.handleClose}>
                        <DialogContent>
                            <Grid container>
                                <Grid item xs={6}>
                                    {/* Hospital ID */}
                                    <FormControl className={classes.formControl2}>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Hospital ID"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            value={this.state.patient.hospital_id}
                                            margin="normal"
                                            fullWidth={true}
                                            type="string"
                                            onChange={this.handleNewPatientChange("hospital_id", "value")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    {/* Patient Initials Input */}
                                    <FormControl className={classes.formControl2}>
                                        <TextField
                                            required
                                            value={this.state.patient.patient_initials}
                                            id="outlined-required"
                                            label="Patient Initials (Limit 3 Characters)"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            inputProps={{
                                                maxLength: 3
                                            }}
                                            margin="normal"
                                            fullWidth={true}
                                            type="string"
                                            onChange={this.handleNewPatientChange("patient_initials", "value")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    {/* Date of Birth Input */}
                                    <FormControl className={classes.formControl2}>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Date of Birth"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            margin="normal"
                                            type="date"
                                            fullWidth={true}
                                            onChange={this.handleNewPatientChange("dob")}
                                            onBlur={this.calculateAge("age", this.state.patient.dob)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    {/* Age [auto-calculated via Date of Birth Input] */}
                                    <FormControl className={classes.formControl2}>
                                        <TextField
                                            id="outlined-required"
                                            disabled
                                            label="Age"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                            value={this.state.patient.age}
                                            margin="normal"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    {/* Gender Input */}
                                    <FormControl component="fieldset" className={classes.formControl2}>
                                        <FormLabel component="legend" style={{ display: "inline-block" }}>
                                            Gender
                                        </FormLabel>
                                        <RadioGroup
                                            aria-label="Gender"
                                            name="gender"
                                            value={this.state.patient.gender}
                                            onChange={this.handleNewPatientChange("gender", "value")}
                                            style={{ display: "flex", flexDirection: "row" }}
                                        >
                                            <FormControlLabel value="Male" control={<Radio className={classes.radio} />} label="Male" />
                                            <FormControlLabel value="Female" control={<Radio className={classes.radio} />} label="Female" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                {/* Type of Cancer Input */}
                                <Grid item xs={6}>
                                    <FormControl fullWidth className={classes.formControl2}>
                                        <InputLabel shrink htmlFor="outlined-toc-simple">
                                            Type of Cancer
                                        </InputLabel>
                                        <Select
                                            display="flex"
                                            fullWidth={true}
                                            value={this.state.patient.toc_id}
                                            onChange={this.handleNewPatientChange("toc_id", "value")}
                                            input={
                                                <Input name="Type of Cancer" id="outlined-toc-simple" labelWidth={this.state.labelWidth} />
                                            }
                                        >
                                            <MenuItem value={1}>Colorectal Cancer</MenuItem>
                                            <MenuItem value={2}>Appendix</MenuItem>
                                            <MenuItem value={3}>Gastric</MenuItem>
                                            {(this.state.patient.gender === "" || this.state.patient.gender === "Female") && (
                                                <MenuItem value={4}>Ovarian</MenuItem>
                                            )}
                                            <MenuItem value={5}>Mesothelioma</MenuItem>
                                            <MenuItem value={6}>Pseudomyxoma Peritonei</MenuItem>
                                            <MenuItem value={7}>Other</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {/* HIPEC Input */}
                                {/* <FormControl component="fieldset" className={classes.formControl2}>
                                <FormLabel component="legend" style={{display: 'inline-block'}}>HIPEC</FormLabel>
                                <RadioGroup
                                    aria-label="HIPEC"
                                    name="hipec"
                                    value={this.state.patient.hipec}
                                    onChange={this.handleNewPatientChange('hipec', 'value')}
                                    style={{display: 'flex', flexDirection: 'row'}}
                                >
                                    <FormControlLabel value="yes" control={<Radio className={classes.radio}/>} label="Yes" />
                                    <FormControlLabel value="no" control={<Radio className={classes.radio}/>} label="No" />
                                </RadioGroup>
                            </FormControl> */}
                                {/* If HIPEC is yes, show additional inputs */}
                                {/* {this.state.patient.hipec === 'yes' ? (
                            <> */}
                                <Grid item xs={6}>
                                    <FormControl className={classes.formControl2}>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="HIPEC Surgery (Primary) Date"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            margin="normal"
                                            type="date"
                                            value={this.state.patient.hipec_date}
                                            onChange={this.handleNewPatientChange("hipec_date", "value")}
                                        />
                                    </FormControl>
                                </Grid>
                                {/* HIPEC Date of Referral Input */}
                                <Grid item xs={6}>
                                    <FormControl className={classes.formControl2}>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Date of Referral"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            margin="normal"
                                            type="date"
                                            value={this.state.patient.referral_date}
                                            onChange={this.handleNewPatientChange("referral_date", "value")}
                                            onBlur={this.handleNewPatientChange("diagnosis_date", "value")}
                                        />
                                    </FormControl>
                                </Grid>
                                {/* HIPEC Date of Diagnosis Input */}
                                <Grid item xs={6}>
                                    <FormControl className={classes.formControl2}>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Diagnosis Date"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            margin="normal"
                                            type="date"
                                            value={this.state.patient.diagnosis_date}
                                            onChange={this.handleNewPatientChange("diagnosis_date", "value")}
                                        />
                                    </FormControl>
                                </Grid>
                                {/* </>
                            ) : (<></>)} <-- Render nothing if no HIPEC */}
                                {/* Alive on Date Input */}
                                <Grid item xs={6}>
                                    <FormControl className={classes.formControl2}>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Alive on Date"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            margin="normal"
                                            type="date"
                                            value={this.state.patient.alive_on_date}
                                            onChange={this.handleNewPatientChange("alive_on_date", "value")}
                                        />
                                    </FormControl>
                                </Grid>
                                {/* Sensor */}
                                {/* <FormControl component="fieldset" className={classes.formControl2}>
                                <FormLabel component="legend" style={{display: 'inline-block'}}>Sensor</FormLabel>
                                <RadioGroup
                                    aria-label="sensor"
                                    name="sensor"
                                    value={this.state.patient.sensor}
                                    onChange={this.handleNewPatientChange('sensor', 'value')}
                                    style={{display: 'flex', flexDirection: 'row'}}
                                >
                                    <FormControlLabel value="yes" control={<Radio className={classes.radio}/>} label="Yes" />
                                    <FormControlLabel value="no" control={<Radio className={classes.radio}/>} label="No" />
                                </RadioGroup>
                            </FormControl> */}
                                {/* Hospital Tel Input */}
                                <Grid item xs={6}>
                                    <FormControl className={classes.formControl2}>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Hospital Telephone Number"
                                            value={this.state.patient.hospital_telephone}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            margin="normal"
                                            fullWidth={true}
                                            type="string"
                                            onChange={this.handleNewPatientChange("hospital_telephone", "value")}
                                        />
                                    </FormControl>
                                </Grid>
                                {/* Referring Doctor Input */}
                                <Grid item xs={6}>
                                    <FormControl className={classes.formControl2}>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Referring Doctor"
                                            value={this.state.patient.referring_doctor}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            margin="normal"
                                            fullWidth={true}
                                            type="string"
                                            onChange={this.handleNewPatientChange("referring_doctor", "value")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    {/* HIPEC Current Status Input */}
                                    <FormControl fullWidth className={classes.formControl2}>
                                        <InputLabel shrink htmlFor="outlined-age-native-simple">
                                            Current Status
                                        </InputLabel>
                                        <Select
                                            value={this.state.patient.current_status}
                                            onChange={this.handleNewPatientChange("current_status", "value")}
                                            input={<Input name="title" fullWidth id="outlined-age-native-simple" />}
                                        >
                                            {currentStatusOptions.map(opt => {
                                                return (
                                                    <MenuItem key={opt.id} value={opt.id}>
                                                        {opt.option}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {/* Interval Prime Surgery - HIPEC Input */}
                                {/* <FormControl className={classes.formControl2}>
                                <TextField
                                required
                                id="outlined-required"
                                label="Interval Prime Surgery - HIPEC (Number of Days)"
                                InputLabelProps={{shrink: true,}}
                                value={this.state.patient.ipshipec}
                                margin="normal"
                                fullWidth={true}
                                type="string"
                                onChange={this.handleNewPatientChange('ipshipec', 'value')}
                                /></FormControl> */}
                                {/* Survival (HIPEC Death) Input */}
                                {/* <FormControl className={classes.formControl2}>
                                <TextField
                                required
                                id="outlined-required"
                                label="Survival - HIPEC Death (Number of Days)"
                                InputLabelProps={{shrink: true,}}
                                value={this.state.patient.survivalhipecdeath}
                                margin="normal"
                                fullWidth={true}
                                type="string"
                                onChange={this.handleNewPatientChange('survivalhipecdeath', 'value')}
                                />
                            </FormControl> */}
                                {/* Survival (HIPEC Last Contact) Input */}
                                {/* <FormControl className={classes.formControl2}>
                                <TextField
                                required
                                id="outlined-required"
                                label="Survival - HIPEC Last Contact (Number of Days)"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={this.state.patient.survivalhipeclastcontact}
                                margin="normal"
                                                            fullWidth={true}
                                    type="string"
                                    onChange={this.handleNewPatientChange('survivalhipeclastcontact', 'value')}
                                />
                            </FormControl> */}
                                {/* Interval Diagnosis PC-HIPEC Input */}
                                {/* <FormControl className={classes.formControl2}>
                                <TextField
                                required
                                id="outlined-required"
                                label="Interval Diagnosis PC-HIPEC (Number of Days)"
                                InputLabelProps={{shrink: true,}}
                                value={this.state.patient.intervalDiagnosisPcHipec}
                                margin="normal"
                                fullWidth={true}
                                type="string"
                                onChange={this.handleNewPatientChange('intervalDiagnosisPcHipec', 'value')}
                                />
                            </FormControl> */}
                                {/* </Grid> */}
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            {/* Cancel Add New Patient Button */}
                            <Button onClick={this.handleClose} color="primary">
                                Cancel
                            </Button>
                            {/* Finish Add New Patient Button */}
                            <Button
                                disabled={!this.state.patient.gender || !this.state.patient.toc_id || !this.state.patient.dob}
                                onClick={this.addPatient}
                                color="primary"
                            >
                                Add Patient
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            </div>
        );
    }
}

Patients.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = reduxState => ({
    currentStatusOptions: reduxState.optionsReducer.statusOptions,
    allPatients: reduxState.patientReducer.allPatients,
    loading: reduxState.patientReducer.loading,
    error: reduxState.patientReducer.error,
    addSuccess: reduxState.patientReducer.addSuccess,
    user: reduxState.user
});

export default withStyles(styles)(connect(mapStateToProps)(Patients));
