import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import GridItem from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import PropTypes from "prop-types";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Input from "@material-ui/core/Input";

const styles = theme => ({
    formControl: {
        margin: theme.spacing.unit
    }
});

class AdditionalPageInfo extends Component {
    state = {
        labelWidth: 0
    };

    componentDidMount() {
        this.setState({
            //labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
        });
    }

    render() {
        const { state, classes, perfusateOptions, stomaOptions, hipecRegimentOptions, durationOptions, rScoreOptions } = this.props;

        if (!perfusateOptions || !stomaOptions || !hipecRegimentOptions || !durationOptions || !rScoreOptions) return <></>;

        const perfusateOtherID = perfusateOptions.find(p => p.option == "Other").id;

        return (
            <div>
                <Grid container spacing={24}>
                    <GridItem item xs={12}>
                        <Typography variant="h6">Anastomosis Information</Typography>
                        <Divider />
                    </GridItem>
                    <GridItem item xs={12} md={4}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="anastomosis"
                                    checked={this.props.state.anastomosis}
                                    onChange={this.props.handleChangeCheckbox}
                                />
                            }
                            label="Anastomosis"
                        />
                        <br />
                        <TextField
                            onChange={this.props.handleChange}
                            value={this.props.state.anastomosis_number || ""}
                            name="anastomosis_number"
                            margin="dense"
                            id="anastomosis_number"
                            label="Anastomosis Number"
                            type="text"
                            fullwidth="true"
                            className={classes.formControl}
                        />
                        <TextField
                            onChange={this.props.handleChange}
                            value={this.props.state.bloodloss_ml || ""}
                            name="bloodloss_ml"
                            margin="dense"
                            id="bloodloss_ml"
                            label="Bloodloss"
                            type="number"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">ml</InputAdornment>
                            }}
                        />
                        <TextField
                            onChange={this.props.handleChange}
                            value={this.props.state.bloodloss_time || ""}
                            name="bloodloss_time"
                            margin="dense"
                            id="bloodloss_time"
                            label="Cytoreduction Time"
                            type="number"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">minutes</InputAdornment>
                            }}
                        />
                    </GridItem>

                    <GridItem item xs={12} md={4}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="revision_stoma"
                                    checked={this.props.state.revision_stoma}
                                    onChange={this.props.handleChangeCheckbox}
                                    // value={this.props.state.revision_stoma}
                                />
                            }
                            label="Revision Stoma"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="stoma_post_hipec"
                                    checked={this.props.state.stoma_post_hipec}
                                    onChange={this.props.handleChangeCheckbox}
                                    // value={this.props.state.stoma_post_hipec}
                                />
                            }
                            label="Stoma Post-HIPEC"
                        />
                        {/* <DialogContent > */}
                        <FormControl fullWidth={true} margin="dense" className={classes.formControl}>
                            <InputLabel shrink htmlFor="stomaType">
                                Stoma Type
                            </InputLabel>
                            <Select
                                value={this.props.state.stoma_post_hipec_type || ""}
                                onChange={this.props.handleChange}
                                input={<Input name="stoma_post_hipec_type" id="stoma_post_hipec_type" labelwidth={this.state.labelWidth} />}
                            >
                                {stomaOptions.map(opt => {
                                    return (
                                        <MenuItem key={opt.status} value={opt.id}>
                                            {opt.option}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </GridItem>
                    <GridItem item xs={12} md={4}></GridItem>
                </Grid>
                <Grid container spacing={24}>
                    <GridItem item xs={12}>
                        <Typography variant="h6">Detailed HIPEC Information</Typography>
                        <Divider />
                    </GridItem>
                    {/* Left Side Inputs */}
                    <GridItem item xs={12} md={6}>
                        {/* HIPEC Regiment */}
                        <FormControl fullWidth={true} margin="dense" className={classes.formControl}>
                            <InputLabel shrink htmlFor="hipec_regiment">
                                HIPEC Regiment
                            </InputLabel>
                            <Select
                                value={this.props.state.hipec_regiment || ""}
                                onChange={this.props.handleChange}
                                input={<Input name="hipec_regiment" id="hipec_regiment" />}
                            >
                                {hipecRegimentOptions.map(opt => {
                                    return (
                                        <MenuItem key={opt.status} value={opt.id}>
                                            {opt.option}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        {/* Total HIPEC Dose */}
                        <TextField
                            onChange={this.props.handleChange}
                            value={this.props.state.total_dose_mg || ""}
                            name="total_dose_mg"
                            margin="dense"
                            id="total_dose_mg"
                            label="Total HIPEC Dose"
                            type="number"
                            fullWidth={true}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">mg</InputAdornment>
                            }}
                        />
                        {/* Duration */}
                        <FormControl fullWidth={true} margin="dense" className={classes.formControl}>
                            <InputLabel shrink htmlFor="operative_duration">
                                Duration
                            </InputLabel>
                            <Select
                                value={this.props.state.operative_duration || ""}
                                onChange={this.props.handleChange}
                                input={<Input name="operative_duration" id="operative_duration" />}
                            >
                                {durationOptions.map(opt => {
                                    return (
                                        <MenuItem key={opt.option} value={opt.option}>
                                            {opt.option} Minutes
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth={true} margin="dense" className={classes.formControl}>
                            <InputLabel shrink htmlFor="perfusate">
                                Perfusate
                            </InputLabel>
                            <Select
                                value={this.props.state.perfusate || ""}
                                onChange={this.props.handleChange}
                                input={<Input name="perfusate" id="perfusate" labelwidth={this.state.labelWidth} />}
                            >
                                {perfusateOptions &&
                                    perfusateOptions.map(opt => {
                                        return (
                                            <MenuItem key={opt.id} value={opt.id}>
                                                {opt.option}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                            {this.props.state.perfusate == perfusateOtherID && (
                                <TextField
                                    margin="dense"
                                    onChange={this.props.handleChange}
                                    name="perfusate_other"
                                    value={this.props.state.perfusate_other}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    id="perfusate_other"
                                    placeholder="Enter Other Perfusate"
                                />
                            )}
                        </FormControl>
                        {/* Perfusate Volume */}
                        <TextField
                            onChange={this.props.handleChange}
                            value={this.props.state.volume}
                            name="volume"
                            margin="dense"
                            id="volume"
                            label="Perfusate Volume"
                            type="number"
                            fullWidth={true}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">L</InputAdornment>
                            }}
                        />
                        {/* Concentration */}
                        <TextField
                            // onChange={this.props.concentrationCalc}
                            value={this.props.state.concentration_mg_l}
                            name="concentration_mg_l"
                            margin="dense"
                            id="concentration_mg_l"
                            label="Concentration"
                            type="number"
                            fullWidth={true}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">mg/L</InputAdornment>
                            }}
                        />
                        {/* HIPEC Flow Rate */}
                        <FormControl fullWidth={true} margin="dense" className={classes.formControl}>
                            <InputLabel shrink htmlFor="hemorrhage_class">
                                HIPEC Flow Rate
                            </InputLabel>
                            <Select
                                value={this.props.state.hipec_flow_rate || ""}
                                onChange={this.props.handleChange}
                                input={<Input name="hipec_flow_rate" id="hipec_flow_rate" />}
                            >
                                <MenuItem value={1}>{"500-800 ml/min"}</MenuItem>
                                <MenuItem value={2}>{"800-1000 ml/min"}</MenuItem>
                                <MenuItem value={3}>{"1000-1500 ml/min"}</MenuItem>
                                <MenuItem value={4}>{"1500-2000 ml/min"}</MenuItem>
                                <MenuItem value={5}>{"2000+ ml/min"}</MenuItem>
                            </Select>
                        </FormControl>
                    </GridItem>
                    {/* Right Side Inputs */}
                    <GridItem item xs={12} md={6}>
                        {/* R-Score */}
                        <FormControl fullWidth={true} margin="dense" className={classes.formControl}>
                            <InputLabel shrink htmlFor="outlined-age-native-simple">
                                R-Score
                            </InputLabel>
                            <Select
                                value={this.props.state.r_score || ""}
                                onChange={this.props.handleChange}
                                input={<Input name="r_score" id="r_score" />}
                            >
                                {this.props.rScoreOptions.map(opt => {
                                    return (
                                        <MenuItem key={opt.id} value={opt.id}>
                                            {opt.option}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        {/* Hemorrhage Class */}
                        <FormControl fullWidth={true} margin="dense" className={classes.formControl}>
                            <InputLabel shrink htmlFor="hemorrhage_class">
                                Hemorrhage Class
                            </InputLabel>
                            <Select
                                value={this.props.state.hemorrhage_class || ""}
                                onChange={this.props.handleChange}
                                input={<Input name="hemorrhage_class" id="hemorrhage_class" />}
                            >
                                <MenuItem value={1}>{"Class I"}</MenuItem>
                                <MenuItem value={2}>{"Class II"}</MenuItem>
                                <MenuItem value={3}>{"Class III"}</MenuItem>
                                <MenuItem value={4}>{"Class IV"}</MenuItem>
                            </Select>
                        </FormControl>
                        {/* Intracavity Temp */}
                        <TextField
                            onChange={this.props.handleChange}
                            value={this.props.state.intracavity_temp_c || ""}
                            name="intracavity_temp_c"
                            margin="dense"
                            id="intracavity_temp_c"
                            label="Intracavity Temp"
                            type="number"
                            fullWidth={true}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">Celcius</InputAdornment>
                            }}
                        />
                        {/* #1 Other HIPEC Dose */}
                        <TextField
                            onChange={this.props.handleChange}
                            value={this.props.state.total_dose_mg_other1}
                            name="total_dose_mg_other1"
                            margin="dense"
                            id="total_dose_mg_other1"
                            label="#1 Other HIPEC Dose"
                            type="number"
                            fullWidth={true}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">mg</InputAdornment>
                            }}
                        />
                        {/* #1 Other HIPEC Concentration */}
                        <TextField
                            onChange={this.props.handleChange}
                            value={this.props.state.concentration_mg_l_other1}
                            name="concentration_mg_l_other1"
                            margin="dense"
                            id="concentration_mg_l_other1"
                            label="#1 Other HIPEC Concentration"
                            type="number"
                            fullWidth={true}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">mg/L</InputAdornment>
                            }}
                        />
                        {/* #2 Other HIPEC Dose */}
                        <TextField
                            onChange={this.props.handleChange}
                            value={this.props.state.total_dose_mg_other2}
                            name="total_dose_mg_other2"
                            margin="dense"
                            id="total_dose_mg_other2"
                            label="#2 Other HIPEC Dose"
                            type="number"
                            fullWidth={true}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">mg</InputAdornment>
                            }}
                        />
                        {/* #2 Other HIPEC Concentration */}
                        <TextField
                            onChange={this.props.handleChange}
                            value={this.props.state.concentration_mg_l_other2}
                            name="concentration_mg_l_other2"
                            margin="dense"
                            id="concentration_mg_l_other2"
                            label="#2 Other HIPEC Concentration"
                            type="number"
                            fullWidth={true}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">mg/L</InputAdornment>
                            }}
                        />
                    </GridItem>
                    {/* Checkboxes */}
                    <GridItem item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="vasopressers"
                                    checked={this.props.state.vasopressers}
                                    onChange={this.props.handleChangeCheckbox}
                                    // value={this.props.state.status || ""}
                                />
                            }
                            label="Vasopressers"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="albumine"
                                    checked={this.props.state.albumine}
                                    onChange={this.props.handleChangeCheckbox}
                                    // value={this.props.state.status}
                                />
                            }
                            label="Albumine"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="plasma"
                                    checked={this.props.state.plasma}
                                    onChange={this.props.handleChangeCheckbox}
                                    value={this.props.state.status}
                                />
                            }
                            label="Plasma"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="plaquets"
                                    checked={this.props.state.plaquets}
                                    onChange={this.props.handleChangeCheckbox}
                                    value={this.props.state.status}
                                />
                            }
                            label="Plaquets"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="red_blood_cells"
                                    checked={this.props.state.red_blood_cells}
                                    onChange={this.props.handleChangeCheckbox}
                                    value={this.props.state.status}
                                />
                            }
                            label="Red Blood Cells"
                        />
                    </GridItem>
                    {/* </GridItem> */}
                </Grid>
            </div>
        );
    }
}

AdditionalPageInfo.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = reduxState => ({
    stomaOptions: reduxState.optionsReducer.stomaOptions,
    hipecRegimentOptions: reduxState.optionsReducer.hipecRegimentOptions,
    rScoreOptions: reduxState.optionsReducer.rScoreOptions,
    perfusateOptions: reduxState.optionsReducer.perfusateOptions,
    durationOptions: reduxState.optionsReducer.durationOptions
});

export default connect(mapStateToProps)(withStyles(styles)(AdditionalPageInfo));
