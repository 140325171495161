import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import GridItem from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

const styles = theme => ({
    formControl: {
        margin: theme.spacing.unit
    }
});

class ResearcherModal extends Component {
    state = {
        localLoading: true,
        researcher: null,
        localSurgeons: []
    };

    setInitialState = () => {
        const { user, users } = this.props;
        const surgeons = users.filter(u => u.access_level == 2 && u.active === true);
        this.setState(
            {
                ...this.state,
                localLoading: true,
                researcher: user.userId,
                localSurgeons: surgeons.map(obj => ({ ...obj, checked: user.surgeons.some(us => us === obj.id) }))
            },
            () => {
                this.setState({
                    ...this.state,
                    localLoading: false
                });
            }
        );
    };

    componentDidMount = () => {
        this.setInitialState();
    };

    handleSubmit = () => {
        this.props.dispatch({ type: "UPDATE_RESEARCHER_SURGEONS", payload: this.state });
        this.props.onClose();
    };

    handleChangeSurgeon = event => {
        let itemSelected = this.state.localSurgeons.find(s => s.id == event.target.value);
        itemSelected.checked = !itemSelected.checked;
        this.setState({
            ...this.state,
            localSurgeons: this.state.localSurgeons.map(obj => ({ ...obj, itemSelected }))
        });
    };

    render() {
        const { openModal, user, onClose } = this.props;
        const { localLoading, localSurgeons } = this.state;
        if (localLoading) return <p>Loading ...</p>;

        return (
            user &&
            localSurgeons && (
                <Dialog disableBackdropClick open={openModal} onClose={onClose}>
                    <DialogTitle>Surgeons Assigned to {user.fullName}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={24}>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={12}>
                                <FormGroup row>
                                    <Grid container spacing={8}>
                                        {localSurgeons.map((s, index) => (
                                            <GridItem item xs={6} md={4} lg={3} key={index}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            key={index}
                                                            name={s.fullName}
                                                            checked={s.checked}
                                                            onChange={this.handleChangeSurgeon}
                                                            value={s.id}
                                                        />
                                                    }
                                                    label={s.first_name + " " + s.last_name}
                                                />
                                            </GridItem>
                                        ))}
                                    </Grid>
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleSubmit} color="primary">
                            Update
                        </Button>
                    </DialogActions>
                </Dialog>
            )
        );
    } // end return
} // end class ResearcherModal

// export default withStyles(styles)(ResearcherModal);
const mapStateToProps = reduxState => ({
    users: reduxState.allUsersReducer.users,
    loading: reduxState.allUsersReducer.loading,
    error: reduxState.allUsersReducer.error,
    addSuccess: reduxState.allUsersReducer.addSuccess
});

ResearcherModal.propTypes = {
    classes: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(withStyles(styles)(ResearcherModal));
