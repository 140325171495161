import { takeLatest , call, put } from 'redux-saga/effects';
import axios from 'axios';


function* addPathologyNote(action) {
    try {
        yield call(axios.post, `/pathologyNotes`, action.payload);
        yield put({ type: 'GET_PATHOLOGY_NOTES', payload: action.payload.patientId });
    } catch (error) {
        console.log(error);
        yield put({ type: 'PATHOLOGY_NOTES_ERROR', payload: error });
    }
};

function* getPathologyNotes(action) {
    try {
        const response = yield call(axios.get, `/pathologyNotes/${action.payload}`)
        yield put({ type: 'SET_PATHOLOGY_NOTES', payload: response.data })
      }
      catch (error) {
        yield put({ type: 'PATHOLOGY_NOTES_ERROR', payload: error });
        console.log('error with getting request', error);
      }
};

function* pathologyNotesSaga() {
  yield takeLatest('GET_PATHOLOGY_NOTES', getPathologyNotes);
  yield takeLatest('ADD_PATHOLOGY_NOTE', addPathologyNote);
};

export default pathologyNotesSaga;