import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import { Typography, Divider } from "@material-ui/core";

const styles = theme => ({
    gridItem: {
        marginBottom: 10
    },
    formControl: {
        // margin: theme.spacing.unit * 3,
        margin: theme.spacing.unit,
        minWidth: 120
    },
    group: {
        margin: `${theme.spacing.unit}px 0`
    }
});

class IntakePageOther extends Component {
    render() {
        const { classes, asaScoreOptions } = this.props;
        if (!asaScoreOptions) return <></>;
        return (
            <>
                <Grid item xs={12}>
                    <Typography variant="h6">Other Information</Typography>
                    <Divider />
                </Grid>
                <Grid container spacing={24}>
                    <Grid item xs={12} md={4} className={classes.gridItem}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend"></FormLabel>
                            <RadioGroup
                                row
                                aria-label="synch_meta_chronous"
                                name="synch_meta_chronous"
                                className={classes.group}
                                value={String(this.props.intake.synch_meta_chronous)}
                                onChange={this.props.handleChange}
                            >
                                <FormControlLabel value="1" control={<Radio />} label="Peritoneal Metastasis" />
                                <FormControlLabel value="2" control={<Radio />} label="Synchronous" />
                                <FormControlLabel value="3" control={<Radio />} label="Metachronous" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">Prior Surgical Score</FormLabel>
                            <RadioGroup
                                aria-label="pss_score"
                                name="pss_score"
                                className={classes.group}
                                value={String(this.props.intake.pss_score)}
                                onChange={this.props.handleChange}
                            >
                                <FormControlLabel value="1" control={<Radio />} label="PSS-0 (None)" />
                                <FormControlLabel value="2" control={<Radio />} label="PSS-1 (Minimal)" />
                                <FormControlLabel value="3" control={<Radio />} label="PSS-2 (Moderate)" />
                                <FormControlLabel value="4" control={<Radio />} label="PSS-3 (Heavy)" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">ASA Score</FormLabel>
                            <RadioGroup
                                aria-label="asa_score"
                                name="asa_score"
                                className={classes.group}
                                value={String(this.props.intake.asa_score)}
                                onChange={this.props.handleChange}
                            >
                                {asaScoreOptions.map(opt => (
                                    <FormControlLabel
                                        key={opt.id}
                                        value={String(opt.id)}
                                        control={<Radio />}
                                        label={opt.id + " - " + opt.option}
                                    ></FormControlLabel>
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </>
        );
    }
}

const mapStateToProps = reduxState => ({
    asaScoreOptions: reduxState.optionsReducer.asaScoreOptions
});

export default connect(mapStateToProps)(withStyles(styles)(IntakePageOther));
