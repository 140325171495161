import { takeLatest , call, put } from 'redux-saga/effects';
import axios from 'axios';

function* getPrimaryTumor(action) {
    try {
        const response = yield axios.get( `/api/primaryTumor/${action.payload}`);
        yield put({ type: 'SET_PRIMARY_TUMOR', payload: response.data } );
    } catch (error) {
        yield put({ type: 'PRIMARY_TUMOR_ERROR', payload: error });
        console.log(error);
    }
}

function* updatePrimaryTumor(action) {
    try {
        yield call(axios.put, `/api/primaryTumor`, action.payload);
        yield put({ type: 'UPDATE_PRIMARY_TUMOR_SUCCESS', payload: action.payload  });
    }
    catch (error) {
        yield put({ type: 'PRIMARY_TUMOR_ERROR', payload: error });
        console.log('error trying to update db from primaryTumorSaga')
    }
}

function* primaryTumorSaga() {
  yield takeLatest('GET_PRIMARY_TUMOR', getPrimaryTumor);
  yield takeLatest('UPDATE_PRIMARY_TUMOR', updatePrimaryTumor);
}

export default primaryTumorSaga;