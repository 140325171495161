const initialState = {
  data: {},
  loading: false,
  error: ''
};

const primaryTumorReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_PRIMARY_TUMOR":
        return {
          ...state,
          data: {},
          loading: true,
          error: '',
          updateSuccess: false
        };
      case 'SET_PRIMARY_TUMOR':
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: '',
          updateSuccess: false
        };
      case 'PRIMARY_TUMOR_ERROR':
        return {
          ...state,
          loading: false,
          error: 'Error in primary tumor details.',
          updateSuccess: false
        };
      case "SET_PRIMARY_TUMOR_ERROR":
          return {
            ...state,
            loading: false,
            error: action.payload,
            updateSuccess: false
          };
      case 'UPDATE_PRIMARY_TUMOR_SUCCESS':
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: '',
          updateSuccess: true
        };
      case 'CLEAR_PRIMARY_TUMOR_ERROR':
        return {
          ...state,
          loading: false,
          error: '',
          updateSuccess: false
      };
      // case 'UPDATE_INIT_FIELDS':
      //   return action.payload
      default:
        return state;
    }
  };
  
  export default primaryTumorReducer;