import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import GridItem from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";

const styles = theme => ({
    formControl: {
        margin: theme.spacing.unit
    }
});

const scores = [1, 2, 3, 4, 5];

class ClavienScore extends Component {
    renderOptions() {
        return scores.map((opt, i) => {
            return (
                <MenuItem key={i} value={opt}>
                    {opt}
                </MenuItem>
            ); // end return
        }); // end map
    } // end renderTagOptions

    render() {
        const { classes } = this.props;
        return (
            <Grid container spacing={8}>
                <GridItem item xs={12}>
                    <FormControl margin="dense" className={classes.formControl}>
                        <Select
                            value={String(this.props.clavienScore)}
                            disabled={!this.props.checked}
                            id={String(this.props.id)}
                            input={
                                <Input value={String(this.props.clavienScore)} name={String(this.props.id)} id={String(this.props.id)} />
                            }
                            onChange={this.props.handleChangeClavienScore}
                        >
                            {this.renderOptions()}
                        </Select>
                    </FormControl>
                </GridItem>
            </Grid>
        );
    } // end return
} // end class TagSelector

export default withStyles(styles)(ClavienScore);
