const initialState = {
    data: [],
    loading: false,
    error: ""
};

const allResearcherDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_RESEARCH_DATA":
            return {
                ...state,
                data: [],
                loading: true,
                error: "",
                updateSuccess: false
            };
        case "SET_RESEARCH_DATA":
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: "",
                updateSuccess: false
            };
        case "GET_RESEARCH_ERROR":
            return {
                ...state,
                data: [],
                loading: false,
                error: "Error loading research data.",
                updateSuccess: false
            };
        case "CLEAR_RESEARCH_DATA":
            return {
                ...state,
                data: [],
                loading: false,
                error: "",
                updateSuccess: false
            };
        default:
            return state;
    }
};

export default allResearcherDataReducer;
