const initialState = {
    data: {},
    loading: false,
    error: ''
  };

const intakeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_INTAKE':
            return {
                ...state,
                data: {},
                loading: true,
                error: '',
                updateSuccess: false
            };
        case 'SET_INTAKE':
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: '',
                updateSuccess: false
                };     
        case 'INTAKE_ERROR':
                return {
                    ...state,
                    loading: false,
                    error: 'Error in intake details.',
                    updateSuccess: false
                };     
        case 'UPDATE_INTAKE_SUCCESS':
                return {
                    ...state,
                    data: action.payload,
                    loading: false,
                    error: '',
                    updateSuccess: true
                    };   
        case 'CLEAR_INTAKE_ERROR':
                return {
                    ...state,
                    loading: false,
                    error: '',
                    updateSuccess: false
                };   
        default:
            return state;
    }
  };
  
  
  export default intakeReducer;