import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import { Divider, Typography } from "@material-ui/core";

const styles = theme => ({
    gridItem: {
        marginBottom: 10
    },
    root: {
        display: "flex",
        flexWrap: "wrap",
        margin: "dense"
    }
});

class IntakePageNeoAdjuvant extends Component {
    render() {
        const { classes, adjChemoOptions, biologicalOptions, intake } = this.props;
        if (!adjChemoOptions || !biologicalOptions) return <></>;
        const adjChemoOtherID = adjChemoOptions.find(p => p.option == "Other").id;
        const biologicalOtherID = biologicalOptions.find(p => p.option == "Other").id;
        return (
            <>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Neoadjuvant Information</Typography>
                        <Divider />
                    </Grid>
                    <Grid item sm={6} xs={12} className={classes.gridItem}>
                        <FormControl className={classes.root} autoComplete="off">
                            <InputLabel shrink htmlFor="neo_adj_chemotherapy_type">
                                Chemotherapy Type
                            </InputLabel>
                            <Select
                                value={intake.neo_adj_chemotherapy_type}
                                onChange={this.props.handleChange}
                                input={<Input name="neo_adj_chemotherapy_type" id="neo_adj_chemotherapy_type" />}
                            >
                                {adjChemoOptions.map(adj => (
                                    <MenuItem key={adj.id} value={adj.id}>
                                        {adj.option}
                                    </MenuItem>
                                ))}
                            </Select>
                            {intake.neo_adj_chemotherapy_type === adjChemoOtherID && (
                                <TextField
                                    margin="dense"
                                    onChange={this.props.handleChange}
                                    name="neo_adj_chemotherapy_other_type"
                                    value={intake.neo_adj_chemotherapy_other_type}
                                    id="neo_adj_chemotherapy_other_type"
                                    placeholder="Enter Other Chemo Type"
                                />
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item sm={6} xs={12} className={classes.gridItem}>
                        <FormControl className={classes.root} autoComplete="off">
                            <TextField
                                margin="dense"
                                onChange={this.props.handleChange}
                                name="neo_adj_chemotherapy_cycles"
                                value={intake.neo_adj_chemotherapy_cycles}
                                id="neo_adj_chemotherapy_cyclesInput"
                                label="Cycles (n)"
                                type="number"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sm={6} xs={12} className={classes.gridItem}>
                        <FormControl className={classes.root} autoComplete="off">
                            <InputLabel shrink htmlFor="biological_id">
                                Biological
                            </InputLabel>
                            <Select
                                value={intake.biological_id}
                                onChange={this.props.handleChange}
                                input={<Input name="biological_id" id="biological_id" />}
                            >
                                {biologicalOptions.map(adj => (
                                    <MenuItem key={adj.id} value={adj.id}>
                                        {adj.option}
                                    </MenuItem>
                                ))}
                            </Select>
                            {intake.biological_id === biologicalOtherID && (
                                <TextField
                                    margin="dense"
                                    onChange={this.props.handleChange}
                                    name="biological_other"
                                    value={intake.biological_other}
                                    id="biological_otherInput"
                                    placeholder="Enter Other Biological"
                                />
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} className={classes.gridItem}>
                        <TextField
                            fullWidth={true}
                            onChange={this.props.handleChange}
                            value={intake.notes}
                            name="notes"
                            multiline
                            rows="5"
                            variant="outlined"
                            label="Notes"
                        />
                    </Grid>
                </Grid>
            </>
        );
    }
}

const mapStateToProps = reduxState => ({
    adjChemoOptions: reduxState.optionsReducer.adjChemoOptions,
    biologicalOptions: reduxState.optionsReducer.biologicalOptions
});

export default connect(mapStateToProps)(withStyles(styles)(IntakePageNeoAdjuvant));
