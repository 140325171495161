import { createMuiTheme } from "@material-ui/core/styles";

const appTheme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: "#ed1c24"
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: "#f69296"
        },
        background: {
            paper: "#fff",
            default: "#fff"
        }
        // error: will use the default color
    },
    typography: {
        useNextVariants: true,
        fontSize: 14,
        fontFamily: '"Lato", Arial, Helvetica, sans-serif'
    },
    overrides: {
        MuiExpansionPanelSummary: {
            // Name of the component ⚛️ / style sheet
            root: {
                // Name of the rule
                // backgroundColor: '#888888', // Some CSS
            }
        },
        MuiAppBar: {
            root: {
                padding: 5
            }
        },
        MuiToolbar: {
            root: {
                alignItems: "flex-end"
            }
        },
        MuiListItemText: {
            primary: {
                color: "#565656"
            }
        },
        MuiListItemIcon: {
            root: {
                color: "#565656"
            }
        },
        MuiTableRow: {
            root: {
                height: 20
            }
        },
        MuiTableCell: {
            root: {
                padding: 1,
                "&:last-child": {
                    paddingRight: 5
                }
            }
        }
    }
});

export default appTheme;
