import { createMuiTheme } from "@material-ui/core/styles";

const appTheme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: "#48a4d6",
            text: {
                primary: "#565656",
                secondary: "#00000"
            }
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: "#48a4d6"
        },
        background: {
            paper: "#fff",
            default: "#fff"
        }
        // error: will use the default color
    },
    typography: {
        useNextVariants: true,
        fontSize: 14,
        fontFamily: '"Lato", Arial, Helvetica, sans-serif'
    },
    overrides: {
        MuiAppBar: {
            root: {
                padding: 5
            }
        },
        MuiToolbar: {
            root: {
                alignItems: "flex-end"
            }
        },
        MuiListItemText: {
            primary: {
                color: "#565656"
            }
        },
        MuiListItemIcon: {
            root: {
                color: "#565656"
            }
        },
        MuiTableCell: {
            root: {
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 10,
                paddingRight: 10
            },
            body: {
                fontSize: "1rem"
            },
            head: {
                fontSize: "1rem"
            }
        },
        MUIDataTableBodyCell: {
            root: {
                textAlign: "center"
            },
            emptyTitle: {
                textAlign: "left"
            }
        },
        MUIDataTableBody: {
            emptyTitle: {
                textAlign: "left"
            }
        },
        // emptyTitle
        MUIDataTableHeadCell: {
            root: {
                textAlign: "center"
            },
            data: {
                textAlign: "center"
            },
            sortLabelRoot: {
                textAlign: "center"
            },
            sortAction: {
                display: "inline"
            },
            toolButton: {
                display: "inline"
            }
        }
    }
});

export default appTheme;
