import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";

const styles = theme => ({
    formControl: {
        margin: theme.spacing.unit
    }
});

class SystemicLocation extends Component {
    renderOptions(recSystemicOptions) {
        //
        return recSystemicOptions.map((opt, i) => {
            return (
                <MenuItem key={i} value={opt.id}>
                    {opt.option}
                </MenuItem>
            ); // end return
        }); // end map
    } // end renderOptions

    render() {
        const { classes, recSystemicOptions } = this.props;
        if (!recSystemicOptions) return <></>;
        return (
            <FormControl fullWidth={true} margin="dense" className={classes.formControl}>
                <InputLabel shrink htmlFor="location">
                    Systemic Location
                </InputLabel>
                <Select
                    value={this.props.recurrence.syst_location}
                    input={<Input value={this.props.recurrence.syst_location} name="syst_location" id="syst_location" />}
                    onChange={this.props.handleChange}
                >
                    {this.renderOptions(recSystemicOptions)}
                </Select>
            </FormControl>
        );
    } // end return
} // end class SystemicLocation

const mapReduxStateToProps = reduxState => ({
    recSystemicOptions: reduxState.optionsReducer.recSystemicOptions
});

export default connect(mapReduxStateToProps)(withStyles(styles)(SystemicLocation));
