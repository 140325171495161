import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Input from "@material-ui/core/Input";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import MetastasisLocation from "./MetastasisLocation";
import moment from "moment";

const styles = theme => ({
    container: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        margin: theme.spacing.unit * 2,
        width: 200
    },
    dense: {
        marginTop: 19
    },
    menu: {
        width: 200
    },
    formControl: {
        minWidth: 120
    },
    root: {
        display: "flex",
        flexWrap: "wrap",
        margin: "dense"
    },
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 1
    }
});

class PrimaryTumorDetails extends Component {
    render() {
        const {
            classes,
            loading,
            primary_tumor,
            patientTypeOfCancer,
            patientDiagnosisDate,
            locationOptions,
            differentiationOptions,
            histologicalOptions
        } = this.props;

        if (loading || !locationOptions || !differentiationOptions || !histologicalOptions) {
            return <></>;
        }
        const cancerSpecificOptions = locationOptions.filter(opt => opt.toc_id === patientTypeOfCancer);

        return (
            <div>
                <Grid container spacing={24}>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Card className={classes.paper}>
                            <CardHeader title="Diagnosis"></CardHeader>
                            <TextField
                                disabled
                                value={moment(patientDiagnosisDate).format("YYYY-MM-DD")}
                                name="patientDiagnosisDate"
                                label="Primary Diagnosis Date"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                type="date"
                                className={classes.formControl}
                                fullWidth={true}
                            />
                            {patientTypeOfCancer === 4 ? (
                                <></>
                            ) : (
                                <FormControl className={classes.formControl} autoComplete="off" fullWidth>
                                    <InputLabel shrink htmlFor="primary_location">
                                        Primary Location
                                    </InputLabel>
                                    <Select
                                        value={primary_tumor.primary_location ? primary_tumor.primary_location : ""}
                                        onChange={this.props.handleChange}
                                        input={
                                            <Input value={primary_tumor.primary_location} name="primary_location" id="primary_location" />
                                        }
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {cancerSpecificOptions.map(location => (
                                            <MenuItem key={location.id} value={location.id}>
                                                {location.option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Card className={classes.paper}>
                            <CardHeader title="TNM Scale"></CardHeader>
                            {patientTypeOfCancer === 4 || patientTypeOfCancer === 4 ? (
                                <FormControl className={classes.formControl} autoComplete="off" fullWidth>
                                    <InputLabel shrink htmlFor="tumor">
                                        Tumor
                                    </InputLabel>

                                    <Select
                                        value={primary_tumor.tumor}
                                        onChange={this.props.handleChange}
                                        input={<Input name="tumor" id="tumor" />}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={"Tx"}>{"Tx"}</MenuItem>
                                        <MenuItem value={"T0"}>{"T0"}</MenuItem>
                                        <MenuItem value={"T1"}>{"T1"}</MenuItem>
                                        <MenuItem value={"T1a"}>{"T1a"}</MenuItem>
                                        <MenuItem value={"T1b"}>{"T1b"}</MenuItem>
                                        <MenuItem value={"T1c"}>{"T1c"}</MenuItem>
                                        <MenuItem value={"T2a"}>{"T2a"}</MenuItem>
                                        <MenuItem value={"T2b"}>{"T2b"}</MenuItem>
                                        <MenuItem value={"T2c"}>{"T2c"}</MenuItem>
                                        <MenuItem value={"T3"}>{"T3"}</MenuItem>
                                        <MenuItem value={"T3a"}>{"T3a"}</MenuItem>
                                        <MenuItem value={"T3b"}>{"T3b"}</MenuItem>
                                        <MenuItem value={"T3c"}>{"T3c"}</MenuItem>
                                        <MenuItem value={"T4"}>{"T4"}</MenuItem>
                                    </Select>
                                </FormControl>
                            ) : (
                                <FormControl className={classes.formControl} autoComplete="off" fullWidth>
                                    <InputLabel shrink htmlFor="tumor">
                                        Tumor Scale
                                    </InputLabel>
                                    <Select
                                        value={primary_tumor.tumor}
                                        onChange={this.props.handleChange}
                                        input={<Input value={primary_tumor.tumor} name="tumor" id="tumor" />}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={"X"}>{"T-X"}</MenuItem>
                                        <MenuItem value={0 || "0"}>{"T-0"}</MenuItem>
                                        <MenuItem value={1 || "1"}>{"T-1"}</MenuItem>
                                        <MenuItem value={2 || "2"}>{"T-2"}</MenuItem>
                                        <MenuItem value={3 || "3"}>{"T-3"}</MenuItem>
                                        <MenuItem value={4 || "4"}>{"T-4"}</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                            <FormControl className={classes.formControl} autoComplete="off" fullWidth>
                                <InputLabel shrink htmlFor="node">
                                    Node Scale
                                </InputLabel>
                                <Select
                                    value={primary_tumor.node}
                                    onChange={this.props.handleChange}
                                    input={
                                        <Input
                                            // value={primary_tumor.node}
                                            name="node"
                                            id="node"
                                        />
                                    }
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={"X"}>{"N-X"}</MenuItem>
                                    <MenuItem value={0 || "0"}>{"N-0"}</MenuItem>
                                    <MenuItem value={1 || "1"}>{"N-1"}</MenuItem>
                                    <MenuItem value={2 || "2"}>{"N-2"}</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl className={classes.formControl} autoComplete="off" fullWidth>
                                <InputLabel shrink htmlFor="metastasis">
                                    Metastasis Scale
                                </InputLabel>
                                <Select
                                    value={primary_tumor.metastasis ? primary_tumor.metastasis : ""}
                                    onChange={this.props.handleChange}
                                    input={
                                        <Input
                                            // value={primary_tumor.metastasis}
                                            name="metastasis"
                                            id="metastasis"
                                        />
                                    }
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={"X"}>{"M-X"}</MenuItem>
                                    <MenuItem value={0 || "0"}>{"M-0"}</MenuItem>
                                    <MenuItem value={1 || "1"}>{"M-1"}</MenuItem>
                                </Select>
                            </FormControl>
                        </Card>
                    </Grid>
                </Grid>

                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Card className={classes.paper}>
                            <CardHeader title="Metastasis Location"></CardHeader>
                            {/* <Grid container spacing={24}> */}
                            <MetastasisLocation
                                primary_tumor={primary_tumor}
                                handleChange={this.props.handleChange}
                                handleChangeCheckbox={this.props.handleChangeCheckbox}
                            />
                            {/* </Grid> */}
                        </Card>
                    </Grid>
                </Grid>

                <Card className={classes.paper}>
                    <CardHeader title="Differentiation"></CardHeader>
                    <Grid container spacing={24}>
                        <Grid item md={3} xs={12} className={classes.gridItem}>
                            <FormControl className={classes.root} autoComplete="off">
                                <InputLabel shrink htmlFor="differentiation">
                                    Differentiation
                                </InputLabel>
                                <Select
                                    value={primary_tumor.differentiation}
                                    onChange={this.props.handleChange}
                                    input={
                                        <Input
                                            // value={primary_tumor.differentiation}
                                            name="differentiation"
                                            id="differentiation"
                                        />
                                    }
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {differentiationOptions.map(dif => (
                                        <MenuItem key={dif.id} value={dif.id}>
                                            {dif.option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormLabel component="legend">Mucinous</FormLabel>
                                <RadioGroup
                                    aria-label="mucinous"
                                    name="mucinous"
                                    className={classes.group}
                                    value={primary_tumor.mucinous ? String(primary_tumor.mucinous) : ""}
                                    onChange={this.props.handleChange}
                                >
                                    <FormControlLabel value="1" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="2" control={<Radio />} label="No" />
                                    <FormControlLabel value="3" control={<Radio />} label="Unknown" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.gridItem}>
                            <FormControl className={classes.root} autoComplete="off">
                                <InputLabel shrink htmlFor="histological_type">
                                    Mucinous Classification
                                </InputLabel>
                                <Select
                                    disabled={String(primary_tumor.mucinous) !== "1"}
                                    value={primary_tumor.histological_type ? primary_tumor.histological_type : ""}
                                    onChange={this.props.handleChange}
                                    input={
                                        <Input value={primary_tumor.histological_type} name="histological_type" id="histological_type" />
                                    }
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {histologicalOptions.map(hist => (
                                        <MenuItem key={hist.id} value={hist.id}>
                                            {hist.option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Card>
            </div>
        );
    }
}
const mapStateToProps = reduxState => ({
    patientTypeOfCancer: reduxState.patientReducer.data.toc_id,
    locationOptions: reduxState.optionsReducer.primaryLocationOptions,
    differentiationOptions: reduxState.optionsReducer.differentiationOptions,
    histologicalOptions: reduxState.optionsReducer.histologicalOptions
});

export default connect(mapStateToProps)(withStyles(styles)(PrimaryTumorDetails));
