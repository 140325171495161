import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";

const styles = theme => ({
    formControl: {
        margin: theme.spacing.unit
    }
});

class Status extends Component {
    renderOptions(currentStatusOptions) {
        return currentStatusOptions.map((opt, i) => {
            return (
                <MenuItem key={i} value={opt.id}>
                    {opt.option}
                </MenuItem>
            ); // end return
        }); // end map
    } // end renderOptions

    render() {
        const { classes, currentStatusOptions } = this.props;
        if (!currentStatusOptions) return <></>;
        return (
            <FormControl fullWidth={true} margin="dense" className={classes.formControl}>
                <InputLabel shrink htmlFor="status">
                    Status
                </InputLabel>
                <Select
                    value={this.props.recurrence.status}
                    input={<Input value={this.props.recurrence.status} name="status" id="status" />}
                    onChange={this.props.handleChange}
                >
                    {this.renderOptions(currentStatusOptions)}
                </Select>
            </FormControl>
        );
    } // end return
} // end class Location

const mapReduxStateToProps = reduxState => ({
    currentStatusOptions: reduxState.optionsReducer.statusOptions
});

export default connect(mapReduxStateToProps)(withStyles(styles)(Status));
