import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";

const myOptions = [
    { id: 1, status: "Death of Disease" },
    { id: 2, status: "Alive with Disease" },
    { id: 3, status: "No Evidence of Disease" },
    { id: 4, status: "Treatment Related Death" }
];

const styles = theme => ({
    formControl: {
        margin: theme.spacing.unit * 2
    }
});

class DischargeStatus extends Component {
    state = {
        labelWidth: 0
    };

    renderOptions() {
        //
        return myOptions.map((option, i) => {
            return (
                <MenuItem key={i} value={option.id}>
                    {option.status}
                </MenuItem>
            ); // end return
        }); // end map
    } // end renderTagOptions

    render() {
        const { classes } = this.props;

        return (
            <FormControl fullWidth={true} margin="dense" className={classes.formControl}>
                <InputLabel shrink htmlFor="status_at_discharge">
                    Discharge Status
                </InputLabel>
                <Select
                    fullWidth={true}
                    label="Discharge Status"
                    value={this.props.status_at_discharge || ""}
                    input={
                        <Input
                            value={this.props.status_at_discharge || ""}
                            name="status_at_discharge"
                            id="status_at_discharge"
                            //labelWidth={this.state.labelWidth}
                        />
                    }
                    onChange={this.props.handleChange}
                >
                    {this.renderOptions()}
                </Select>
            </FormControl>
        );
    } // end return
} // end class TagSelector

export default withStyles(styles)(DischargeStatus);
