import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import GridItem from "@material-ui/core/Grid";
import { Divider } from "@material-ui/core";

const styles = theme => ({
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: "center",
        color: theme.palette.text.secondary
    }
});

class ResectionDropdown extends Component {
    render() {
        const { resectionOptions, gender } = this.props;
        if (!resectionOptions) return <></>;
        return (
            <Grid container spacing={24}>
                <GridItem item xs={12}>
                    <Typography variant="h6">Resection Information</Typography>
                    <Divider />
                    {resectionOptions.map(
                        opt =>
                            (gender !== "Male" || (gender === "Male" && opt.option !== "Ovaries" && opt.option !== "Uterus")) && (
                                <FormControlLabel
                                    style={{ width: 200 }}
                                    control={
                                        <Checkbox
                                            key={opt.id}
                                            name={opt.db_name}
                                            checked={this.props.state[opt.db_name]}
                                            onChange={this.props.handleChangeCheckbox}
                                        />
                                    }
                                    label={opt.option}
                                />
                            )
                        // </GridItem>
                    )}
                </GridItem>
            </Grid>
        );
    }
}

ResectionDropdown.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = reduxState => ({
    resectionOptions: reduxState.optionsReducer.resectionOptions,
    gender: reduxState.patientReducer.data.gender
});

export default connect(mapStateToProps)(withStyles(styles)(ResectionDropdown));
