import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import { withStyles } from "@material-ui/core/styles";

const defaultToolbarStyles = {
    fab: {
        marginLeft: 10
        // padding: 10
    }
};

class ToolbarNewButton extends React.Component {
    render() {
        const { classes, onClickNew, tooltipText } = this.props;

        return (
            <>
                <Tooltip title={tooltipText}>
                    <Fab size="small" color="primary" aria-label="Add" className={classes.fab} onClick={onClickNew}>
                        <AddIcon />
                    </Fab>
                </Tooltip>
            </>
        );
    }
}

export default withStyles(defaultToolbarStyles, { name: "ToolbarNewButton" })(ToolbarNewButton);
