const initialState = {
    data: {},
    loading: false,
    error: ''
  };

const psdssReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_PSDSS':
                return {
                    ...state,
                    data: {},
                    loading: true,
                    error: '',
                    updateSuccess: false
                };
        case 'SET_PSDSS':
                return {
                    ...state,
                    data: action.payload,
                    loading: false,
                    error: '',
                    updateSuccess: false
                    };
        case 'PSDSS_ERROR':
                return {
                    ...state,
                    loading: false,
                    error: 'Error in intake details.',
                    updateSuccess: false
                };     
        case 'UPDATE_PSDSS_SUCCESS':
                return {
                    ...state,
                    data: action.payload,
                    loading: false,
                    error: '',
                    updateSuccess: true
                    };   
        case 'CLEAR_PSDSS_ERROR':
                return {
                    ...state,
                    loading: false,
                    error: '',
                    updateSuccess: false
                };   
        default:
            return state;
    }
  };
  
  
  export default psdssReducer;