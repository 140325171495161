import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import CardHeader from "@material-ui/core/CardHeader";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import moment from "moment";
import Card from "@material-ui/core/Card";

const styles = theme => ({
    gridItem: {
        marginBottom: 30
    },
    root: {
        display: "flex",
        flexWrap: "wrap",
        margin: "dense"
    },
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2
    }
});

class PrimaryTumorTreatment extends Component {
    render() {
        const { classes, loading, primary_tumor, surgeryOptions, settingOptions, adjChemoOptions, biologicalOptions } = this.props;

        if (loading || !surgeryOptions || !settingOptions || !adjChemoOptions || !biologicalOptions) {
            return <></>;
        }

        const adjChemoOtherID = adjChemoOptions.find(p => p.option == "Other").id;
        const biologicalOtherID = biologicalOptions.find(p => p.option == "Other").id;

        return (
            <>
                <Card className={classes.paper}>
                    <CardHeader title="Primary Surgery"></CardHeader>
                    <Grid container spacing={24}>
                        <Grid item md={4} xs={12} className={classes.gridItem}>
                            <TextField
                                label="HIPEC Surgery (Primary) Date"
                                type="date"
                                className={classes.root}
                                disabled
                                name="date_prime_surgery"
                                value={moment(this.props.patient.hipec_date).format("YYYY-MM-DD")}
                            />
                        </Grid>
                        {/* Intervention Surgery 1 Input */}
                        <Grid item md={4} xs={12} className={classes.gridItem}>
                            <FormControl className={classes.root} autoComplete="off">
                                <InputLabel shrink htmlFor="intervention_surgery_1">
                                    Intervention Surgery 1
                                </InputLabel>
                                <Select
                                    value={primary_tumor.intervention_surgery_1 ? primary_tumor.intervention_surgery_1 : ""}
                                    onChange={this.props.handleChange}
                                    input={
                                        <Input
                                            name="intervention_surgery_1"
                                            id="interventionSurgery1"
                                            value={primary_tumor.intervention_surgery_1}
                                        />
                                    }
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {surgeryOptions
                                        .filter(opt => opt.type == 1)
                                        .map(opt => (
                                            <MenuItem key={opt.id} value={opt.id}>
                                                {opt.option}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* Intervention Surgery 2 Input */}
                        <Grid item md={4} xs={12} className={classes.gridItem}>
                            <FormControl className={classes.root} autoComplete="off">
                                <InputLabel shrink htmlFor="intervention_surgery_2">
                                    Intervention Surgery 2
                                </InputLabel>
                                <Select
                                    value={primary_tumor.intervention_surgery_2 ? primary_tumor.intervention_surgery_2 : ""}
                                    onChange={this.props.handleChange}
                                    input={
                                        <Input
                                            name="intervention_surgery_2"
                                            id="interventionSurgery2"
                                            value={primary_tumor.intervention_surgery_2}
                                        />
                                    }
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {surgeryOptions
                                        .filter(opt => opt.type == 2)
                                        .map(opt => (
                                            <MenuItem key={opt.id} value={opt.id}>
                                                {opt.option}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12} className={classes.gridItem}>
                            <FormControl className={classes.root} autoComplete="off">
                                <InputLabel shrink htmlFor="prime_tumor_surgery">
                                    Primary Tumor Surgery
                                </InputLabel>
                                <Select
                                    value={primary_tumor.prime_tumor_surgery ? primary_tumor.prime_tumor_surgery : ""}
                                    onChange={this.props.handleChange}
                                    input={<Input name="prime_tumor_surgery" id="prime_tumor_surgery" />}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {surgeryOptions
                                        .filter(opt => opt.type == 0)
                                        .map(opt => (
                                            <MenuItem key={opt.id} value={opt.id}>
                                                {opt.option}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12} className={classes.gridItem}>
                            <FormControl className={classes.root} autoComplete="off">
                                <InputLabel shrink htmlFor="setting">
                                    Setting
                                </InputLabel>
                                <Select
                                    value={primary_tumor.setting ? primary_tumor.setting : ""}
                                    onChange={this.props.handleChange}
                                    input={<Input name="setting" id="setting" value={primary_tumor.setting} />}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {settingOptions.map(set => (
                                        <MenuItem key={set.id} value={set.id}>
                                            {set.option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12} className={classes.gridItem}>
                            <TextField
                                disabled={String(primary_tumor.setting) !== "2"}
                                margin="dense"
                                onChange={this.props.handleChange}
                                name="reason_acute"
                                value={String(primary_tumor.setting) !== "2" ? "" : primary_tumor.reason_acute}
                                id="reason_acuteInput"
                                label="Reason for Urgency"
                                className={classes.root}
                            />
                        </Grid>
                    </Grid>
                </Card>
                <Card className={classes.paper}>
                    <CardHeader title="Adjuvant Chemotherapy" />
                    <Grid container spacing={24}>
                        <Grid item md={4} xs={12} className={classes.gridItem}>
                            <FormControl className={classes.root} autoComplete="off">
                                <InputLabel shrink htmlFor="adj_chemotherapy_type">
                                    Adjuvant Chemotherapy Type
                                </InputLabel>
                                <Select
                                    value={primary_tumor.adj_chemotherapy_type ? primary_tumor.adj_chemotherapy_type : ""}
                                    onChange={this.props.handleChange}
                                    input={<Input name="adj_chemotherapy_type" id="type" value={primary_tumor.adj_chemotherapy_type} />}
                                >
                                    {adjChemoOptions.map(adj => (
                                        <MenuItem key={adj.id} value={adj.id}>
                                            {adj.option}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {primary_tumor.adj_chemotherapy_type === adjChemoOtherID && (
                                    <TextField
                                        margin="dense"
                                        onChange={this.props.handleChange}
                                        name="adj_chemotherapy_other_type"
                                        value={primary_tumor.adj_chemotherapy_other_type}
                                        id="adj_chemotherapy_other_typeInput"
                                        placeholder="Enter Other Chemotherapy Type"
                                    />
                                )}
                            </FormControl>
                        </Grid>

                        <Grid item md={4} xs={12} className={classes.gridItem}>
                            <FormControl className={classes.root} autoComplete="off">
                                <TextField
                                    margin="dense"
                                    onChange={this.props.handleChange}
                                    name="adj_chemotherapy_cycles"
                                    type="number"
                                    value={primary_tumor.adj_chemotherapy_cycles}
                                    id="adj_chemotherapy_cyclesInput"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">n</InputAdornment>
                                    }}
                                    label="Cycles"
                                />
                            </FormControl>
                        </Grid>

                        <Grid item md={4} xs={12} className={classes.gridItem}>
                            <FormControl className={classes.root} autoComplete="off">
                                <InputLabel shrink htmlFor="biological">
                                    Biological
                                </InputLabel>
                                <Select
                                    value={primary_tumor.biological}
                                    onChange={this.props.handleChange}
                                    input={<Input name="biological" id="biological" />}
                                >
                                    {biologicalOptions.map(adj => (
                                        <MenuItem key={adj.id} value={adj.id}>
                                            {adj.option}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {primary_tumor.biological === biologicalOtherID && (
                                    <TextField
                                        margin="dense"
                                        onChange={this.props.handleChange}
                                        name="biological_other"
                                        value={primary_tumor.biological_other}
                                        id="biological_otherInput"
                                        placeholder="Enter Other Biological"
                                    />
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} className={classes.gridItem}>
                            <FormControl fullWidth className={classes.root} autoComplete="off">
                                <TextField
                                    //   fullwidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onChange={this.props.handleChange}
                                    value={primary_tumor.primary_tumor_notes}
                                    name="primary_tumor_notes"
                                    multiline
                                    rows="5"
                                    variant="outlined"
                                    label="Notes"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Card>
            </>
        );
    }
}

PrimaryTumorTreatment.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = reduxState => ({
    patient: reduxState.patientReducer.data,
    surgeryOptions: reduxState.optionsReducer.surgeryOptions,
    settingOptions: reduxState.optionsReducer.settingOptions,
    adjChemoOptions: reduxState.optionsReducer.adjChemoOptions,
    biologicalOptions: reduxState.optionsReducer.biologicalOptions
});

export default connect(mapStateToProps)(withStyles(styles)(PrimaryTumorTreatment));
