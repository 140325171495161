import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

// worker Saga: will be fired on "FETCH_FOLLOW_UP" actions
function* fetchFollowUp(action) {
  try {
    const response = yield axios.get(`api/follow_up/${action.payload}`);
    yield put({ type: 'SET_FOLLOW_UP', payload: response.data });
  } catch (error) {
    yield put({ type: 'FOLLOW_UP_ERROR', payload: error });
    console.log('follow up get request failed', error);
  }
}

// worker Saga: will be fired on "UPDATE_FOLLOW_UP" actions
function* updateFollowUp(action) {
  try {
      yield call(axios.put, `/api/follow_up`, action.payload);
      yield put({type: 'UPDATE_FOLLOW_UP_SUCCESS', payload: action.payload});
  } catch (error) {
      console.log(error);
      yield put({ type: 'FOLLOW_UP_ERROR', payload: error });
  }
}

function* followUpSaga() {
  yield takeLatest('FETCH_FOLLOW_UP', fetchFollowUp);
  yield takeLatest('UPDATE_FOLLOW_UP', updateFollowUp);
}

export default followUpSaga;
