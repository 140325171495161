import moment from "moment";

/// Could be a way of using this ..
// const docs = [{"name": "Doc 1", "type": "PDF"}, {"name": "Doc 2", "type": "JPG"}];

// <MUIDataTable
//     title="Documents"
//     data={docs.map(item => {
//         return [
//             item.name,
//             item.type,
//         ]
//     })}
//     columns={Object.keys(docs)}
// />

export const researchColumns = [
    {
        name: "name",
        label: "Type of Cancer",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "current_status",
        label: "Current Status",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "dob",
        label: "Patient DOB",
        options: {
            download: true,
            filter: true,
            sort: true,
            customBodyRender: value => {
                if (value) {
                    return moment(value).format("MM-DD-YYYY");
                }
            }
        }
    },
    {
        name: "patient_age",
        label: "Current Age",
        options: {
            download: true,
            filter: true,
            sort: true
        }
    },
    {
        name: "gender",
        label: "Gender",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "pci_score",
        label: "PCI Score",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "referral_date",
        label: "Referral Date",
        options: {
            filter: false,
            sort: true,
            customBodyRender: value => {
                if (value) {
                    return moment(value).format("MM-DD-YYYY");
                }
            }
        }
    },
    {
        name: "hospital_id",
        label: "Hospital ID",
        options: {
            filter: false,
            sort: true
        }
    },
    {
        name: "hipec_date",
        label: "HIPEC Date",
        options: {
            filter: false,
            sort: true,
            customBodyRender: value => {
                if (value) {
                    return moment(value).format("MM-DD-YYYY");
                }
            }
        }
    },
    {
        name: "diagnosis_date",
        label: "Diagnosis Date",
        options: {
            filter: false,
            sort: true,
            customBodyRender: value => {
                if (value) {
                    return moment(value).format("MM-DD-YYYY");
                }
            }
        }
    },
    {
        name: "hospital_telephone",
        label: "Hospital Phone",
        options: {
            filter: false,
            sort: true
        }
    },
    {
        name: "alive_on_date",
        label: "Alive On Date",
        options: {
            filter: false,
            sort: true,
            customBodyRender: value => {
                if (value) {
                    return moment(value).format("MM-DD-YYYY");
                }
            }
        }
    },
    {
        name: "patient_notes",
        label: "Patient Notes",
        options: {
            filter: false,
            sort: true
        }
    },
    {
        name: "interval_diagnosis_pc_hipec",
        label: "Diagnosis-HIPEC (Days)",
        options: {
            filter: false,
            sort: true
        }
    },
    //   p.interval_prime_surgery,
    //   p.survival_hipec_last_contact,
    //   p.survival_hipec_death,
    //   p.interval_diagnosis_pc_hipec,
    {
        name: "primary_location",
        label: "Primary Location",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "tumor",
        label: "Tumor",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "node",
        label: "Node",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "metastasis",
        label: "Metastasis",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "m_location",
        label: "Metastasis Location",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "differentiation",
        label: "Differentiation",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "mucinous",
        label: "Mucinous",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "histological_type",
        label: "Histology",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "prime_tumor_surgery",
        label: "Primary Tumor Surgery",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "intervention_surgery_1",
        label: "Intervention Surgery 1",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "intervention_surgery_2",
        label: "Intervention Surgery 2",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "primary_tumor_notes",
        label: "Primary Tumor Notes",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "setting",
        label: "Setting",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "reason_acute",
        label: "Reason Acute",
        options: {
            filter: false,
            sort: true
        }
    },
    {
        name: "adj_chemotherapy_type",
        label: "Adjuvant Chemotherapy",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "adj_chemotherapy_cycles",
        label: "Num Cycles",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "primary_tumor_biological",
        label: "Biological",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "weight_kg",
        label: "Weight(kg)",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "length_m",
        label: "Length(m)",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "bmi_auto",
        label: "BMI",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "cea_mg_l",
        label: "CEA(mg/L)",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "ca125_u_ml",
        label: "CA125(u/ml)",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "cta",
        label: "CTA",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "leucocyte",
        label: "Leucoctye",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "smoking",
        label: "Smoker",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "diabetes",
        label: "Diabetic",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "insulin_dependent",
        label: "Insulin Dependent",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "cardiovascular",
        label: "Cardiovascular",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "hypertension",
        label: "Hypertension",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "neo_adj_chemotherapy_type",
        label: "Neoadjuvant Chemotherapy Type",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "neo_adj_chemotherapy_cycles",
        label: "Neoadjuvant Cycles",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "neo_biological",
        label: "Neoadjuvant Biological",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "intake_notes",
        label: "Intake Notes",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "diagnostic_scopy",
        label: "Diagnostic Scopy",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "date_scopy",
        label: "Diagnostic Scopy Date",
        options: {
            filter: true,
            sort: true,
            customBodyRender: value => {
                if (value) {
                    return moment(value).format("MM-DD-YYYY");
                }
            }
        }
    },
    {
        name: "scopy_conclusion",
        label: "Diagnostic Scopy Conclusion",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "scopy_notes",
        label: "Diagnostic Scopy Notes",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "synch_meta_chronous",
        label: "Synch/Metachronus",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "pss_score",
        label: "Prior Surgical Score",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "asa_score",
        label: "ASA Score",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "diagnostic_ct",
        label: "Diagnostic CT",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "date_ct",
        label: "Diagnostic CT Date",
        options: {
            filter: true,
            sort: true,
            customBodyRender: value => {
                if (value) {
                    return moment(value).format("MM-DD-YYYY");
                }
            }
        }
    },
    {
        name: "ct_conclusion",
        label: "Diagnostic CT Conclusion",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "ct_notes",
        label: "Diagnostic CT Notes",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "diagnostic_mri",
        label: "Diagnostic MRI",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "date_mri",
        label: "Diagnostic MRI Date",
        options: {
            filter: true,
            sort: true,
            customBodyRender: value => {
                if (value) {
                    return moment(value).format("MM-DD-YYYY");
                }
            }
        }
    },
    {
        name: "mri_conclusion",
        label: "Diagnostic MRI Conclusion",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "mri_notes",
        label: "Diagnostic MRI Notes",
        options: {
            filter: true,
            sort: true
        }
    },

    {
        name: "surgeon_1",
        label: "Surgeon 1",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "surgeon_2",
        label: "Surgeon 2",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "hipec_type",
        label: "HIPEC Type",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "reason_oc",
        label: "Reason No HIPEC",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "anastomosis",
        label: "Anastomosis",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "anastomosis_number",
        label: "Anastomosis Number",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "revision_stoma",
        label: "Revision Stoma",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "stoma_post_hipec",
        label: "Stoma Post HIPEC",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "bloodloss_ml",
        label: "Bloodloss(ml)",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "bloodloss_time",
        label: "Bloodloss(time)",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "hemorrhage_class",
        label: "Hemorrhage Class",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "vasopressers",
        label: "Vasopressers",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "albumine",
        label: "Albumine",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "red_blood_cells",
        label: "Red Blood Cells",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "plaquets",
        label: "Plaquets",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "plasma",
        label: "Plasma",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "hipec_regiment",
        label: "HIPEC Regiment",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "total_dose_mg",
        label: "Total Dose(mg)",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "total_dose_mg_other1",
        label: "Other Dose 1(mg)",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "total_dose_mg_other2",
        label: "Other Dose 2(mg)",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "perfusate",
        label: "Perfusate",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "intracavity_temp_c",
        label: "Intracavity Temp(c)",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "operative_duration",
        label: "Operative Duration",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "volume",
        label: "Volume",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "concentration_mg_l",
        label: "Concentration(mg/L)",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "concentration_mg_l_other1",
        label: "Other Concentration(mg/L) 1",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "concentration_mg_l_other2",
        label: "Other Concentration(mg/L) 2",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "r_score",
        label: "R Score",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "central_score",
        label: "Central Lesion Size",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "rightupper_score",
        label: "Right Upper Lesion Size",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "epigastrium_score",
        label: "Epigastrium Lesion Size",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "leftupper_score",
        label: "Left Upper Lesion Size",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "leftflank_score",
        label: "Left Flank Lesion Size",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "leftlower_score",
        label: "Left Lower Lesion Size",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "pelvis_score",
        label: "Pelvis Lesion Size",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "rightlower_score",
        label: "Right Lower Lesion Size",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "rightflank_score",
        label: "Right Flank Lesion Size",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "upperjejunum_score",
        label: "Upper Jejunum Lesion Size",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "lowerjejunum_score",
        label: "Lower Jejunum Lesion Size",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "upperilium_score",
        label: "Upper Ilium Lesion Size",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "lowerilium_score",
        label: "Lower Ilium Lesion Size",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "clinical",
        label: "Clinical Evaluation",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "histology",
        label: "Histology Score",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "synchronous_liver_treatment",
        label: "Synchronous Liver Treatment",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "timing",
        label: "Liver Treatment Timing",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "date_treatment",
        label: "Liver Treatment Date",
        options: {
            filter: true,
            sort: true,
            customBodyRender: value => {
                if (value) {
                    return moment(value).format("MM-DD-YYYY");
                }
            }
        }
    },
    {
        name: "treatment_type",
        label: "Liver Treatment Type",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "psdss_notes",
        label: "Liver Treatment Notes",
        options: {
            filter: true,
            sort: true
        }
    },

    {
        name: "operative_notes",
        label: "Operative Notes",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "path_notes",
        label: "Pathology Notes",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "icu_stays",
        label: "ICU Stay(days)",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "mcu_stays",
        label: "MCU Stays (days)",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "hospital_stays",
        label: "Hospital Stays (days)",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "postop_notes",
        label: "Post Op Notes",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "reoperation",
        label: "Reoperation",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "num_reoperations",
        label: "Num of Reoperations",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "hospital_mortality",
        label: "Hospital Mortality",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "status_at_discharge",
        label: "Status at Discharge",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "discharge_notes",
        label: "Discharge Notes",
        options: {
            filter: true,
            sort: true
        }
    },
    {
        name: "adverse_events_list",
        label: "Adverse Events",
        options: {
            filter: true,
            sort: true
        }
    }
];
