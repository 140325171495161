import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Typography, Divider } from "@material-ui/core";

const styles = theme => ({
    gridItem: {
        padding: 0
    },
    gridItemClose: {
        margin: 0,
        padding: `0 !important`
    },
    formControl: {
        margin: theme.spacing.unit * 0,
        padding: 0
    },
    group: {
        margin: theme.spacing.unit * 1,
        padding: `0 !important`
    },
    container: {
        display: "flex",
        flexWrap: "wrap",
        margin: theme.spacing.unit * 1,
        alignItems: "stretch"
    },
    textField: {
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2
    }
});

class IntakePageGeneral extends Component {
    render() {
        const { classes } = this.props;
        return (
            <>
                <Grid container className={classes.container} spacing={8}>
                    <Grid item xs={12}>
                        <Typography variant="h6">General Health</Typography>
                        <Divider />
                    </Grid>
                    {/* Smoking */}
                    <Grid item xs={12} className={classes.gridItemClose}>
                        <FormControl className={classes.formControl}>
                            <RadioGroup
                                row
                                aria-label="Smoking"
                                name="smoking"
                                className={classes.group}
                                value={this.props.intake.smoking}
                                onChange={this.props.handleChange}
                            >
                                <FormControlLabel
                                    labelPlacement="start"
                                    value="1"
                                    control={<Radio />}
                                    label="SMOKING?                 Yes"
                                />
                                <FormControlLabel labelPlacement="start" value="2" control={<Radio />} label="No" />
                                <FormControlLabel labelPlacement="start" value="3" control={<Radio />} label="Unknown" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} className={classes.gridItemClose}>
                        {/* Diabetes */}
                        <FormControl className={classes.formControl}>
                            <RadioGroup
                                row
                                aria-label="diabetes"
                                name="diabetes"
                                className={classes.group}
                                value={this.props.intake.diabetes}
                                onChange={this.props.handleChange}
                            >
                                <FormControlLabel
                                    labelPlacement="start"
                                    value="1"
                                    control={<Radio />}
                                    label="DIABETES?                Yes"
                                />
                                <FormControlLabel labelPlacement="start" value="2" control={<Radio />} label="No" />
                                <FormControlLabel labelPlacement="start" value="3" control={<Radio />} label="Unknown" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} className={classes.gridItemClose}>
                        {/* Insulin Dependent */}
                        <FormControl className={classes.formControl}>
                            <RadioGroup
                                row
                                aria-label="insulinDep"
                                name="insulin_dependent"
                                className={classes.group}
                                value={this.props.intake.insulin_dependent}
                                onChange={this.props.handleChange}
                            >
                                <FormControlLabel
                                    labelPlacement="start"
                                    value="1"
                                    control={<Radio />}
                                    label="INSULIN DEPENDENT?       Yes"
                                />
                                <FormControlLabel labelPlacement="start" value="2" control={<Radio />} label="No" />
                                <FormControlLabel labelPlacement="start" value="3" control={<Radio />} label="Unknown" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} className={classes.gridItemClose}>
                        {/* Cardiovasular */}
                        <FormControl className={classes.formControl}>
                            <RadioGroup
                                row
                                aria-label="cardiovasc"
                                name="cardiovascular"
                                className={classes.group}
                                value={this.props.intake.cardiovascular}
                                onChange={this.props.handleChange}
                            >
                                <FormControlLabel
                                    labelPlacement="start"
                                    value="1"
                                    control={<Radio />}
                                    label="CARDIOVASCULAR?          Yes"
                                />
                                <FormControlLabel labelPlacement="start" value="2" control={<Radio />} label="No" />
                                <FormControlLabel labelPlacement="start" value="3" control={<Radio />} label="Unknown" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} className={classes.gridItemClose}>
                        {/* Hypertension */}
                        <FormControl className={classes.formControl}>
                            <RadioGroup
                                row
                                aria-label="Hypertension"
                                name="hypertension"
                                className={classes.group}
                                value={this.props.intake.hypertension}
                                onChange={this.props.handleChange}
                            >
                                <FormControlLabel labelPlacement="start" value="1" control={<Radio />} label="HYPERTENSION? Yes" />
                                <FormControlLabel labelPlacement="start" value="2" control={<Radio />} label="No" />
                                <FormControlLabel labelPlacement="start" value="3" control={<Radio />} label="Unknown" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default withStyles(styles)(IntakePageGeneral);
