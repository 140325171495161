import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import ToolbarNewButton from "../Shared/ToolbarNewButton";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import MUIDataTable from "mui-datatables";
import Notification from "../Notification";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import ResearcherModal from "./ResearcherModal";

const styles = theme => ({
    root: {
        flexGrow: 1,
        flexWrap: "wrap",
        alignItems: "center",
        paddingTop: 75
    },
    container: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit
    },
    menu: {
        width: 200
    },
    button: {
        margin: theme.spacing.unit
    },
    icon: {
        margin: theme.spacing.unit * 2
    }
});

const accessLevelOptions = [
    { id: 1, option: "Researcher" },
    { id: 2, option: "Surgeon" },
    { id: 3, option: "Admin" }
];

class ManageUsersPage extends Component {
    state = {
        user: {
            userId: "",
            title: "",
            firstName: "",
            lastName: "",
            accessLevel: "",
            active: true,
            username: "",
            password: "",
            surgeons: [],
            resetPassword: false
        },
        usernameLoaded: "",
        fullWidth: true,
        labelWidth: 40,
        open: false,
        errorOpen: false,
        openNewUser: false,
        openEditUser: false,
        openResearcher: false,
        loaded: false
    };

    componentDidMount() {
        this.props.dispatch({ type: "GET_ALL_USERS" });
        this.props.dispatch({ type: "GET_OPTIONS" });
    }

    handleChange = event => {
        this.setState({
            user: {
                ...this.state.user,
                [event.target.name]: event.target.value
            }
        });
    };

    selectResearcher = rowData => {
        this.setState({
            user: {
                ...this.state.user,
                userId: rowData[1],
                title: rowData[3],
                firstName: rowData[4],
                lastName: rowData[5],
                accessLevel: rowData[6],
                active: rowData[7],
                username: rowData[2],
                resetPassword: false,
                fullName: rowData[4] + " " + rowData[5],
                surgeons: rowData[10]
            },
            errorOpen: true,
            openResearcher: true,
            loaded: true
        });
    };

    selectUser = rowData => {
        this.setState({
            user: {
                ...this.state.user,
                userId: rowData[1],
                title: rowData[3],
                firstName: rowData[4],
                lastName: rowData[5],
                accessLevel: rowData[6],
                active: rowData[7],
                username: rowData[2],
                resetPassword: false
            },
            openEditUser: true,
            openNewUser: false,
            loaded: true,
            usernameLoaded: rowData[2]
        });
    };

    handleClose = () => {
        this.setState({
            ...this.state,
            openNewUser: false,
            openEditUser: false,
            loaded: false,
            user: {}
        });
    };

    handleCloseResearcher = () => {
        this.setState({
            ...this.state,
            openResearcher: false,
            openNewUser: false,
            openEditUser: false,
            loaded: false,
            user: {}
        });
    };

    handleChangeCheckbox = event => {
        this.setState({
            user: {
                ...this.state.user,
                resetPassword: event.target.checked
            }
        });
    };

    handleCloseError = () => {
        this.setState({
            ...this.state,
            errorOpen: false
        });
        this.props.dispatch({ type: "CLEAR_USERS_ERROR" });
    };

    handleSubmit = event => {
        event.preventDefault();
        if (!this.checkUserName()) {
            this.props.dispatch({ type: this.state.openNewUser ? "ADD_NEW_USER" : "UPDATE_USER", payload: this.state.user });
            /// set some sort of alert
            this.handleClose();
        }
    };

    checkUserName = () => {
        if (this.state.openResearcher) return false;
        if (this.state.openEditUser && this.state.user.username === this.state.usernameLoaded) {
            return false;
        }
        const duplicateUserName = this.props.users.some(u => u.username === this.state.user.username);
        if (duplicateUserName) {
            alert("Username already exists, please enter another username.");
        }
        return duplicateUserName;
    };

    clickNewUser = () => {
        this.setState({
            ...this.state,
            errorOpen: true,
            openNewUser: true,
            openEditUser: false,
            user: {}
        });
    };

    render() {
        const { classes, users, loading, error, addSuccess, updateResearchersSuccess } = this.props;
        const { errorOpen, openNewUser, openEditUser, openResearcher, loaded } = this.state;

        if (loading) {
            return (
                <>
                    <div className={classes.toolbar} />
                    <Grid container className={classes.progress} spacing={24} alignItems="center" justify="center">
                        <CircularProgress></CircularProgress>
                    </Grid>
                </>
            );
        }

        if (error) {
            return (
                <>
                    <div className={classes.toolbar} />
                    <Notification open={errorOpen} error={true} handleCloseError={this.handleCloseError} message={error} />
                </>
            );
        }

        const columns = [
            {
                name: "id",
                label: " ",
                options: {
                    display: "exluded",
                    viewColumns: false
                }
            },
            {
                name: "id",
                label: "User ID",
                options: {
                    filter: true,
                    sort: true
                }
            },
            {
                name: "username",
                label: "Username",
                options: {
                    filter: true,
                    sort: true
                }
            },
            {
                name: "title",
                label: "Title",
                options: {
                    filter: true,
                    sort: true
                }
            },
            {
                name: "first_name",
                label: "First Name",
                options: {
                    filter: true,
                    sort: true
                }
            },
            {
                name: "last_name",
                label: "Last Name",
                options: {
                    filter: true,
                    sort: true
                }
            },
            {
                name: "access_level",
                label: "Access Level",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: tableMeta => {
                        return tableMeta ? accessLevelOptions.find(t => t.id === tableMeta).option : ``;
                    }
                }
            },
            {
                name: "active",
                label: "Active",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: tableMeta => {
                        return tableMeta ? "Yes" : "No";
                    }
                }
            },
            {
                label: "Edit",
                name: "action",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <IconButton className={classes.iconButton} onClick={() => this.selectUser(tableMeta.rowData)}>
                                <EditIcon className={classes.addIcon} />
                            </IconButton>
                        );
                    }
                }
            },
            {
                label: "Assign",
                name: "actionResearcher",
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return tableMeta.rowData[6] === 1 ? (
                            <IconButton className={classes.iconButton} onClick={() => this.selectResearcher(tableMeta.rowData)}>
                                <SupervisorAccountIcon className={classes.addIcon} />
                            </IconButton>
                        ) : (
                            ""
                        );
                    }
                }
            },
            {
                name: "surgeons",
                label: " ",
                options: {
                    display: "exluded",
                    viewColumns: false
                }
            }
        ];

        const options = {
            download: false,
            print: false,
            selectableRows: "none",
            viewColumns: false,
            fixedHeader: true,
            customToolbar: () => {
                return <ToolbarNewButton onClickNew={this.clickNewUser} tooltipText={"New User"} />;
            }
        };

        return (
            <div className={classes.root}>
                {addSuccess && (
                    <Notification open={errorOpen} error={false} handleCloseError={this.handleCloseError} message={"New User Added"} />
                )}
                {updateResearchersSuccess && (
                    <Notification
                        open={true}
                        error={false}
                        handleCloseError={this.handleCloseError}
                        message={"Surgeons Assigned to Researcher"}
                    />
                )}
                {accessLevelOptions && (
                    <Grid container>
                        <Grid item xs={12}>
                            <MUIDataTable title="Users" data={users} columns={columns} options={options} />
                        </Grid>
                    </Grid>
                )}

                {users && openResearcher && (
                    <ResearcherModal openModal={openResearcher} user={this.state.user} users={users} onClose={this.handleCloseResearcher} />
                )}

                <Dialog disableBackdropClick open={openNewUser || (openEditUser && loaded)} onClose={this.handleClose}>
                    <DialogTitle>{this.state.openNewUser ? "Add User" : "Edit User"}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={24}>
                            <Grid item xs={2}>
                                <FormControl fullWidth margin="normal" className={classes.formControl}>
                                    <InputLabel
                                        shrink
                                        ref={ref => {
                                            this.InputLabelRef = ref;
                                        }}
                                        htmlFor="outlined-age-native-simple"
                                    >
                                        Title
                                    </InputLabel>
                                    <Select
                                        value={this.state.user.title ? this.state.user.title : ""}
                                        onChange={this.handleChange}
                                        input={<Input name="title" labelWidth={this.state.labelWidth} id="outlined-age-native-simple" />}
                                    >
                                        <MenuItem value="None">None</MenuItem>
                                        <MenuItem value="Dr.">Dr.</MenuItem>
                                        <MenuItem value="Mr.">Mr.</MenuItem>
                                        <MenuItem value="Mrs.">Mrs.</MenuItem>
                                        <MenuItem value="Miss">Miss</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={5}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        id="outlined-name"
                                        label="First Name"
                                        className={classes.textField}
                                        value={this.state.user.firstName}
                                        onChange={this.handleChange}
                                        name="firstName"
                                        margin="normal"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={5}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        id="outlined-name"
                                        label="Last Name"
                                        className={classes.textField}
                                        value={this.state.user.lastName}
                                        onChange={this.handleChange}
                                        name="lastName"
                                        margin="normal"
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <InputLabel shrink htmlFor="outlined-age-native-simple">
                                        Access Level
                                    </InputLabel>
                                    <Select
                                        value={this.state.user.accessLevel || ""}
                                        onChange={this.handleChange}
                                        input={<Input name="accessLevel" id="outlined-age-native-simple" />}
                                    >
                                        <MenuItem value="3">Admin</MenuItem>
                                        <MenuItem value="2">Surgeon</MenuItem>
                                        <MenuItem value="1">Researcher</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <InputLabel shrink htmlFor="outlined-age-native-simple">
                                        Active User?
                                    </InputLabel>
                                    <Select
                                        value={this.state.user.active}
                                        onChange={this.handleChange}
                                        input={<Input name="active" id="outlined-age-native-simple" />}
                                    >
                                        <MenuItem value={true}>True</MenuItem>
                                        <MenuItem value={false}>False</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        id="outlined-name"
                                        label="Username"
                                        className={classes.textField}
                                        value={this.state.user.username}
                                        onChange={this.handleChange}
                                        onBlur={this.checkUserName}
                                        name="username"
                                        margin="normal"
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                {openEditUser && (
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="resetPassword"
                                                    checked={this.state.user.resetPassword}
                                                    onChange={this.handleChangeCheckbox}
                                                />
                                            }
                                            label="Reset Password?"
                                        />
                                    </FormGroup>
                                )}
                                {((openEditUser && this.state.user.resetPassword) || openNewUser) && (
                                    <FormControl fullWidth className={classes.formControl}>
                                        <TextField
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            id="outlined-name"
                                            label="Password"
                                            className={classes.textField}
                                            value={this.state.user.password}
                                            onChange={this.handleChange}
                                            name="password"
                                            margin="normal"
                                        />
                                    </FormControl>
                                )}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button
                            disabled={
                                !this.state.user.firstName ||
                                !this.state.user.lastName ||
                                !this.state.user.accessLevel ||
                                !this.state.user.username ||
                                (!this.state.user.password && this.state.user.resetPassword & this.state.openEditUser) ||
                                (!this.state.user.password && this.state.openNewUser) ||
                                this.checkUserName()
                            }
                            onClick={this.handleSubmit}
                            color="primary"
                        >
                            {this.state.openNewUser ? "Add" : "Update"}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = reduxState => ({
    users: reduxState.allUsersReducer.users,
    loading: reduxState.allUsersReducer.loading,
    error: reduxState.allUsersReducer.error,
    addSuccess: reduxState.allUsersReducer.addSuccess,
    updateResearchersSuccess: reduxState.allUsersReducer.updateResearchersSuccess
});

ManageUsersPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(withStyles(styles)(ManageUsersPage));
