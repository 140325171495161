const initialState = {
    primaryLocationOptions: [],
    metastasisOptions: [],
    surgeryOptions: [],
    loading: false,
    error: ""
};

const optionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_PRIMARY_LOCATION_OPTIONS":
            return {
                ...state,
                primaryLocationOptions: action.payload,
                loading: false,
                error: ""
            };
        case "SET_METASTASIS_OPTIONS":
            return {
                ...state,
                metastasisOptions: action.payload,
                loading: false,
                error: ""
            };
        case "SET_RESECTION_OPTIONS":
            return {
                ...state,
                resectionOptions: action.payload,
                loading: false,
                error: ""
            };
        case "SET_METASTASIS_LOCATION_OPTIONS":
            return {
                ...state,
                metastasisLocationOptions: action.payload,
                loading: false,
                error: ""
            };
        case "SET_SURGERY_OPTIONS":
            return {
                ...state,
                surgeryOptions: action.payload,
                loading: false,
                error: ""
            };
        case "SET_CANCER_OPTIONS":
            return {
                ...state,
                cancerOptions: action.payload,
                loading: false,
                error: ""
            };
        case "SET_PERFUSATE_OPTIONS":
            return {
                ...state,
                perfusateOptions: action.payload,
                loading: false,
                error: ""
            };
        case "SET_STATUS_OPTIONS":
            return {
                ...state,
                statusOptions: action.payload,
                loading: false,
                error: ""
            };
        case "SET_DIFFERENTIATION_OPTIONS":
            return {
                ...state,
                differentiationOptions: action.payload,
                loading: false,
                error: ""
            };
        case "SET_HISTOLOGICAL_OPTIONS":
            return {
                ...state,
                histologicalOptions: action.payload,
                loading: false,
                error: ""
            };
        case "SET_SETTING_OPTIONS":
            return {
                ...state,
                settingOptions: action.payload,
                loading: false,
                error: ""
            };
        case "SET_ADJ_CHEMO_OPTIONS":
            return {
                ...state,
                adjChemoOptions: action.payload,
                loading: false,
                error: ""
            };
        case "SET_BIOLOGICAL_OPTIONS":
            return {
                ...state,
                biologicalOptions: action.payload,
                loading: false,
                error: ""
            };
        case "SET_ASA_SCORE_OPTIONS":
            return {
                ...state,
                asaScoreOptions: action.payload,
                loading: false,
                error: ""
            };
        case "SET_TIMING_OPTIONS":
            return {
                ...state,
                timingOptions: action.payload,
                loading: false,
                error: ""
            };
        case "SET_TREATMENT_OPTIONS":
            return {
                ...state,
                treatmentOptions: action.payload,
                loading: false,
                error: ""
            };
        case "SET_HIPEC_TYPE_OPTIONS":
            return {
                ...state,
                hipecTypeOptions: action.payload,
                loading: false,
                error: ""
            };
        case "SET_OPEN_CLOSE_OPTIONS":
            return {
                ...state,
                openCloseOptions: action.payload,
                loading: false,
                error: ""
            };
        case "SET_STOMA_OPTIONS":
            return {
                ...state,
                stomaOptions: action.payload,
                loading: false,
                error: ""
            };
        case "SET_HIPEC_REGIMENT_OPTIONS":
            return {
                ...state,
                hipecRegimentOptions: action.payload,
                loading: false,
                error: ""
            };
        case "SET_DURATION_OPTIONS":
            return {
                ...state,
                durationOptions: action.payload,
                loading: false,
                error: ""
            };
        case "SET_R_SCORE_OPTIONS":
            return {
                ...state,
                rScoreOptions: action.payload,
                loading: false,
                error: ""
            };
        case "SET_RECURRENCE_MODALITY_OPTIONS":
            return {
                ...state,
                recModalityOptions: action.payload,
                loading: false,
                error: ""
            };
        case "SET_RECURRENCE_LOCATION_OPTIONS":
            return {
                ...state,
                recLocationOptions: action.payload,
                loading: false,
                error: ""
            };
        case "SET_RECURRENCE_SYSTEMIC_OPTIONS":
            return {
                ...state,
                recSystemicOptions: action.payload,
                loading: false,
                error: ""
            };
        case "SET_RECURRENCE_TREATMENT_OPTIONS":
            return {
                ...state,
                recTreatmentOptions: action.payload,
                loading: false,
                error: ""
            };
        default:
            return state;
    }
};

export default optionsReducer;
