import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";

import moment from "moment";

const styles = theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing.unit * 3,
        overflowX: "auto",
        display: "flex",
        flexWrap: "wrap",
        margin: "dense"
    },
    gridItem: {
        marginBottom: 10
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 220
    },
    formControlNotes: {
        margin: theme.spacing.unit,
        fullwidth: true
    },
    group: {
        margin: `${theme.spacing.unit}px 0`
    },
    table: {
        paddingBottom: 20
    },
    headingElements: {
        marginTop: 10,
        marginBottom: 30
    },
    containerPaper: {
        padding: 20
    }
});

class PSDSSPageData extends Component {
    render() {
        const { classes, timingOptions, treatmentOptions } = this.props;
        if (!timingOptions || !treatmentOptions) return <></>;
        return (
            <Grid container direction="row" justify="flex-start">
                <Grid item xs={12} className={classes.gridItem}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="synchronous_liver_treatment"
                                checked={this.props.psdssInfo.synchronous_liver_treatment}
                                onChange={this.props.handleChangeCheckbox}
                            />
                        }
                        label="Synchronous Liver Metastases Treatment"
                    />
                </Grid>
                <Grid item xs={12} md={4} className={classes.gridItem}>
                    <TextField
                        className={classes.formControl}
                        label="Treatment Date"
                        disabled={!this.props.psdssInfo.synchronous_liver_treatment}
                        type="date"
                        dense="true"
                        onChange={this.props.handleChange}
                        name="date_treatment"
                        value={moment(this.props.psdssInfo.date_treatment).format("YYYY-MM-DD")}
                    />
                </Grid>
                <Grid item xs={12} md={4} className={classes.gridItem}>
                    <FormControl margin="dense" className={classes.formControl}>
                        <InputLabel shrink htmlFor="timing">
                            Timing
                        </InputLabel>
                        <Select
                            value={this.props.psdssInfo.timing || ""}
                            onChange={this.props.handleChange}
                            input={<Input disabled={!this.props.psdssInfo.synchronous_liver_treatment} name="timing" id="timing" />}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {timingOptions.map(time => (
                                <MenuItem key={time.id} value={time.id}>
                                    {time.option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4} className={classes.gridItem}>
                    <FormControl margin="dense" className={classes.formControl}>
                        <InputLabel shrink htmlFor="type">
                            Type
                        </InputLabel>
                        <Select
                            value={this.props.psdssInfo.treatment_type || ""}
                            onChange={this.props.handleChange}
                            input={
                                <Input disabled={!this.props.psdssInfo.synchronous_liver_treatment} name="treatment_type" id="typeSLM" />
                            }
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {treatmentOptions.map(type => (
                                <MenuItem key={type.id} value={type.id}>
                                    {type.option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} className={classes.gridItem}>
                    <FormControl fullWidth className={classes.formControlNotes}>
                        <TextField
                            disabled={!this.props.psdssInfo.synchronous_liver_treatment}
                            onChange={this.props.handleChange}
                            value={this.props.psdssInfo.notes || ""}
                            name="notes"
                            multiline
                            rows="5"
                            variant="outlined"
                            label="Notes"
                        />
                    </FormControl>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = reduxState => ({
    timingOptions: reduxState.optionsReducer.timingOptions,
    treatmentOptions: reduxState.optionsReducer.treatmentOptions
});

export default connect(mapStateToProps)(withStyles(styles)(PSDSSPageData));
