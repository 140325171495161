import { takeLatest , call, put } from 'redux-saga/effects';
import axios from 'axios';


function* getPSDSS(action) {     
    try {
        const response = yield axios.get(`/api/psdss/${action.payload}`);
        yield put({type: 'SET_PSDSS', payload: response.data});
    } catch (error) {
        yield put({ type: 'PSDSS_ERROR', payload: error });
        console.log(error);
    }
}

function* updatePSDSS(action) {
    try{
        yield call(axios.put, `/api/psdss/${action.payload.patient_id}`, action.payload)
        yield put({type: 'UPDATE_PSDSS_SUCCESS', payload: action.payload});
    }
    catch(error){
        yield put({ type: 'PSDSS_ERROR', payload: error });
        console.log('error trying to update db from psdssSaga')
    }
}


function* psdssSaga() {
  yield takeLatest('GET_PSDSS', getPSDSS);
  yield takeLatest('UPDATE_PSDSS', updatePSDSS);
}

export default psdssSaga;