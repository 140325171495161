import { takeLatest, call, put } from "redux-saga/effects";
import axios from "axios";

function* getOptions(action) {
    try {
        const response = yield call(axios.get, `/options/primary_location`);
        yield put({ type: "SET_PRIMARY_LOCATION_OPTIONS", payload: response.data });
    } catch (error) {
        yield put({ type: "OPTIONS_ERROR", payload: error });
        console.log("error with getting request", error);
    }
    try {
        const response = yield call(axios.get, `/options/metastasis`);
        yield put({ type: "SET_METASTASIS_OPTIONS", payload: response.data });
    } catch (error) {
        yield put({ type: "OPTIONS_ERROR", payload: error });
        console.log("error with getting request", error);
    }
    try {
        const response = yield call(axios.get, `/options/resection`);
        yield put({ type: "SET_RESECTION_OPTIONS", payload: response.data });
    } catch (error) {
        yield put({ type: "OPTIONS_ERROR", payload: error });
        console.log("error with getting request", error);
    }
    try {
        const response = yield call(axios.get, `/options/metalocation`);
        yield put({ type: "SET_METASTASIS_LOCATION_OPTIONS", payload: response.data });
    } catch (error) {
        yield put({ type: "OPTIONS_ERROR", payload: error });
        console.log("error with getting request", error);
    }
    try {
        const response = yield call(axios.get, `/options/surgery`);
        yield put({ type: "SET_SURGERY_OPTIONS", payload: response.data });
    } catch (error) {
        yield put({ type: "OPTIONS_ERROR", payload: error });
        console.log("error with getting request", error);
    }
    try {
        const response = yield call(axios.get, `/options/cancer`);
        yield put({ type: "SET_CANCER_OPTIONS", payload: response.data });
    } catch (error) {
        yield put({ type: "OPTIONS_ERROR", payload: error });
        console.log("error with getting request", error);
    }
    try {
        const response = yield call(axios.get, `/options/perfusate`);
        yield put({ type: "SET_PERFUSATE_OPTIONS", payload: response.data });
    } catch (error) {
        yield put({ type: "OPTIONS_ERROR", payload: error });
        console.log("error with getting request", error);
    }
    try {
        const response = yield call(axios.get, `/options/status`);
        yield put({ type: "SET_STATUS_OPTIONS", payload: response.data });
    } catch (error) {
        yield put({ type: "OPTIONS_ERROR", payload: error });
        console.log("error with getting request", error);
    }
    try {
        const response = yield call(axios.get, `/options/differentiation`);
        yield put({ type: "SET_DIFFERENTIATION_OPTIONS", payload: response.data });
    } catch (error) {
        yield put({ type: "OPTIONS_ERROR", payload: error });
        console.log("error with getting request", error);
    }
    try {
        const response = yield call(axios.get, `/options/histological`);
        yield put({ type: "SET_HISTOLOGICAL_OPTIONS", payload: response.data });
    } catch (error) {
        yield put({ type: "OPTIONS_ERROR", payload: error });
        console.log("error with getting request", error);
    }
    try {
        const response = yield call(axios.get, `/options/setting`);
        yield put({ type: "SET_SETTING_OPTIONS", payload: response.data });
    } catch (error) {
        yield put({ type: "OPTIONS_ERROR", payload: error });
        console.log("error with getting request", error);
    }
    try {
        const response = yield call(axios.get, `/options/adjchemo`);
        yield put({ type: "SET_ADJ_CHEMO_OPTIONS", payload: response.data });
    } catch (error) {
        yield put({ type: "OPTIONS_ERROR", payload: error });
        console.log("error with getting request", error);
    }
    try {
        const response = yield call(axios.get, `/options/biological`);
        yield put({ type: "SET_BIOLOGICAL_OPTIONS", payload: response.data });
    } catch (error) {
        yield put({ type: "OPTIONS_ERROR", payload: error });
        console.log("error with getting request", error);
    }
    try {
        const response = yield call(axios.get, `/options/asascore`);
        yield put({ type: "SET_ASA_SCORE_OPTIONS", payload: response.data });
    } catch (error) {
        yield put({ type: "OPTIONS_ERROR", payload: error });
        console.log("error with getting request", error);
    }
    try {
        const response = yield call(axios.get, `/options/timing`);
        yield put({ type: "SET_TIMING_OPTIONS", payload: response.data });
    } catch (error) {
        yield put({ type: "OPTIONS_ERROR", payload: error });
        console.log("error with getting request", error);
    }
    try {
        const response = yield call(axios.get, `/options/treatment`);
        yield put({ type: "SET_TREATMENT_OPTIONS", payload: response.data });
    } catch (error) {
        yield put({ type: "OPTIONS_ERROR", payload: error });
        console.log("error with getting request", error);
    }
    try {
        const response = yield call(axios.get, `/options/hipectype`);
        yield put({ type: "SET_HIPEC_TYPE_OPTIONS", payload: response.data });
    } catch (error) {
        yield put({ type: "OPTIONS_ERROR", payload: error });
        console.log("error with getting request", error);
    }
    try {
        const response = yield call(axios.get, `/options/openclose`);
        yield put({ type: "SET_OPEN_CLOSE_OPTIONS", payload: response.data });
    } catch (error) {
        yield put({ type: "OPTIONS_ERROR", payload: error });
        console.log("error with getting request", error);
    }
    try {
        const response = yield call(axios.get, `/options/stoma`);
        yield put({ type: "SET_STOMA_OPTIONS", payload: response.data });
    } catch (error) {
        yield put({ type: "OPTIONS_ERROR", payload: error });
        console.log("error with getting request", error);
    }
    try {
        const response = yield call(axios.get, `/options/regiment`);
        yield put({ type: "SET_HIPEC_REGIMENT_OPTIONS", payload: response.data });
    } catch (error) {
        yield put({ type: "OPTIONS_ERROR", payload: error });
        console.log("error with getting request", error);
    }
    try {
        const response = yield call(axios.get, `/options/duration`);
        yield put({ type: "SET_DURATION_OPTIONS", payload: response.data });
    } catch (error) {
        yield put({ type: "OPTIONS_ERROR", payload: error });
        console.log("error with getting request", error);
    }
    try {
        const response = yield call(axios.get, `/options/rscore`);
        yield put({ type: "SET_R_SCORE_OPTIONS", payload: response.data });
    } catch (error) {
        yield put({ type: "OPTIONS_ERROR", payload: error });
        console.log("error with getting request", error);
    }
    try {
        const response = yield call(axios.get, `/options/recmodality`);
        yield put({ type: "SET_RECURRENCE_MODALITY_OPTIONS", payload: response.data });
    } catch (error) {
        yield put({ type: "OPTIONS_ERROR", payload: error });
        console.log("error with getting request", error);
    }
    try {
        const response = yield call(axios.get, `/options/reclocation`);
        yield put({ type: "SET_RECURRENCE_LOCATION_OPTIONS", payload: response.data });
    } catch (error) {
        yield put({ type: "OPTIONS_ERROR", payload: error });
        console.log("error with getting request", error);
    }
    try {
        const response = yield call(axios.get, `/options/recsystemic`);
        yield put({ type: "SET_RECURRENCE_SYSTEMIC_OPTIONS", payload: response.data });
    } catch (error) {
        yield put({ type: "OPTIONS_ERROR", payload: error });
        console.log("error with getting request", error);
    }
    try {
        const response = yield call(axios.get, `/options/rectreatment`);
        yield put({ type: "SET_RECURRENCE_TREATMENT_OPTIONS", payload: response.data });
    } catch (error) {
        yield put({ type: "OPTIONS_ERROR", payload: error });
        console.log("error with getting request", error);
    }
}

function* OptionsSaga() {
    yield takeLatest("GET_OPTIONS", getOptions);
}

export default OptionsSaga;
