const initialState = {
  data: {},
  loading: false,
  error: ''
};

const followUpReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_FOLLOW_UP':
        return {
            ...state,
            data: {},
            loading: true,
            error: '',
            updateSuccess: false
        };
    case 'SET_FOLLOW_UP':
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: '',
          updateSuccess: false
          };
    case 'FOLLOW_UP_ERROR':
        return {
            ...state,
            loading: false,
            error: 'Error in followup information.',
            updateSuccess: false
        };
    case 'UPDATE_FOLLOW_UP_SUCCESS':
        return {
            ...state,
            data: action.payload,
            loading: false,
            error: '',
            updateSuccess: true
            };   
    case 'CLEAR_FOLLOW_UP_ERROR':
        return {
            ...state,
            loading: false,
            error: '',
            updateSuccess: false
        }; 
    default:
      return state;
  }
};

// followUp will be on the redux state at:
// state.followUp
export default followUpReducer;