import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

// worker Saga: will be fired on "FETCH_ADVERSE_EVENT" actions
function* fetchAdverseEvent(action) {
  try {
    const response = yield axios.get(`api/adverse_event/${action.payload}`);
    yield put({ type: 'SET_ADVERSE_EVENT', payload: response.data });
  } catch (error) {
    yield put({ type: 'SET_ADVERSE_EVENT_ERROR', payload: error });
    console.log('Adverse event get request failed', error);
  }
}

// worker Saga: will be fired on "UPDATE_ADVERSE_EVENT" actions
function* updateAdverseEvent(action) {
  let events = action.payload.adverse_events.filter(function(eventIn) {
    return (eventIn.checked === true && eventIn.clavien_score != null);
  });
  let eventsToSend = {
    patient_id: action.payload.patient_id,
    arrayEventOptionIds: [],
    arrayPatientIds: [],
    arrayClavienScores: [],
  }
  events.forEach(element => {
    eventsToSend.arrayEventOptionIds.push(element.id);
    eventsToSend.arrayPatientIds.push(element.patient_id);
    eventsToSend.arrayClavienScores.push(element.clavien_score);
  });
  
  try {
      yield call(axios.post, `/api/adverse_event`, eventsToSend);
      yield put({ type: 'UPDATE_ADVERSE_EVENT_SUCCESS', payload: action.payload });
    } catch (error) {
      yield put({ type: 'SET_ADVERSE_EVENT_ERROR', payload: error });
        console.log(error);
  }
}

function* adverseEventSaga() {
  yield takeLatest('UPDATE_ADVERSE_EVENT', updateAdverseEvent);
  yield takeLatest('FETCH_ADVERSE_EVENT', fetchAdverseEvent);
}

export default adverseEventSaga;
