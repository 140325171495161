const initialState = {
    data: [],
    loading: false,
    error: ''
  };

const operativeNotesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_OPERATIVE_NOTES':
            return {
                ...state,
                data: [],
                loading: true,
                error: '',
                updateSuccess: false
            };
        case 'ADD_OPERATIVE_NOTES_SUCCESS':
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: '',
                updateSuccess: true
            };
        case 'SET_OPERATIVE_NOTES':
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: '',
                updateSuccess: false
                };     
        case 'OPERATIVE_NOTES_ERROR':
                return {
                    ...state,
                    loading: false,
                    error: 'Error in operative notes.',
                    updateSuccess: false
                };
        case 'CLEAR_OPERATIVE_NOTES_ERROR':
                return {
                    ...state,
                    loading: false,
                    error: '',
                    updateSuccess: false
                };  
        default:
            return state;
    }
  };

  
  export default operativeNotesReducer;