import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import FollowUpDetailRecurrence from "./FollowUpDetailRecurrence";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import moment from "moment";
import Notification from "../Notification";

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit
    },
    button: {
        margin: `${theme.spacing.unit * 3}px`
    }
});

class FollowUpDetailDetail extends Component {
    state = {
        changesMade: false,
        errorOpen: true,
        id: 0,
        patient_id: 0,
        follow_up_id: null,
        follow_up_date: null,
        evidence_of_disease: false,
        follow_up_notes: null,
        recurrence: false,
        followup_cea: null,
        rec_modality: null,
        syst_location: false,
        last_contact: null,
        treatment: null,
        date_treatment: null,
        status: null,
        treatment_notes: null,
        location: null,
        date_of_recurrence: null
    };

    componentDidMount() {
        this.setInitialState();
    }

    setInitialState = () => {
        const { history } = this.props;

        this.setState({
            changesMade: false,
            errorOpen: true,
            id: history.id,
            patient_id: history.patient_id,
            follow_up_id: history.id,
            follow_up_date: history.follow_up_date,
            evidence_of_disease: history.evidence_of_disease,
            follow_up_notes: history.follow_up_notes,
            recurrence: history.recurrence,
            followup_cea: history.followup_cea,
            rec_modality: history.rec_modality,
            syst_location: history.syst_location,
            last_contact: history.last_contact,
            treatment: history.treatment,
            date_treatment: history.date_treatment,
            status: history.status,
            treatment_notes: history.treatment_notes,
            location: history.location,
            date_of_recurrence: history.date_of_recurrence
        });
    };

    // Called when the input field changes
    handleChange = event => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
            changesMade: true
        });
    };

    // Called when the input field changes
    handleChangeCheckbox = event => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.checked,
            changesMade: true
        });
    };

    updateFollowUpHistory = () => {
        if (this.state.changesMade) {
            this.props.dispatch({ type: "UPDATE_FOLLOW_UP_HISTORY", payload: this.state });
            this.setState({
                ...this.state,
                changesMade: false
            });
        }
    };

    handleCloseError = () => {
        this.setState({
            ...this.state,
            errorOpen: false
        });
        this.props.dispatch({ type: "CLEAR_FOLLOW_UP_HISTORY_ERROR" });
        this.setInitialState();
    };

    componentWillUnmount() {
        if (this.state.changesMade) {
            this.updateFollowUpHistory();
        }
    }

    render() {
        const { classes, error, updateSuccess } = this.props;
        const { errorOpen } = this.state;

        return (
            <>
                {error && <Notification open={errorOpen} error={true} handleCloseError={this.handleCloseError} message={error} />}
                {updateSuccess && (
                    <Notification
                        open={errorOpen}
                        error={false}
                        handleCloseError={this.handleCloseError}
                        message="Followup Information Saved"
                    />
                )}
                <ExpansionPanelDetails>
                    <Grid container spacing={24}>
                        <Grid item xs={6} md={3}>
                            <TextField
                                name="follow_up_date"
                                label="Follow Up Date"
                                className={classes.textField}
                                value={moment(this.state.follow_up_date).format("YYYY-MM-DD")}
                                // fullWidth
                                type="date"
                                onChange={this.handleChange}
                                margin="dense"
                            />
                        </Grid>

                        <Grid item xs={6} md={3}>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="evidence_of_disease"
                                            checked={this.state.evidence_of_disease}
                                            onChange={this.handleChangeCheckbox}
                                            //value={this.state.evidence_of_disease}
                                        />
                                    }
                                    label="Evidence of Disease"
                                />
                            </FormGroup>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="recurrence"
                                            checked={this.state.recurrence}
                                            onChange={this.handleChangeCheckbox}
                                            value={this.state.recurrence}
                                        />
                                    }
                                    label="Recurrence"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                InputLabelProps={{
                                    shrink: true
                                }}
                                variant="outlined"
                                name="follow_up_notes"
                                label="Follow Up Notes"
                                className={classes.textField}
                                value={this.state.follow_up_notes}
                                rows={8}
                                multiline
                                fullWidth
                                onChange={this.handleChange}
                                margin="dense"
                            />
                        </Grid>
                        <Divider variant="middle" />
                        {/* display recurrence information only if evidence of disease */}
                        {this.state.recurrence && (
                            <FollowUpDetailRecurrence
                                recurrence={this.state}
                                handleChange={this.handleChange}
                                handleChangeCheckbox={this.handleChangeCheckbox}
                            />
                        )}
                    </Grid>
                </ExpansionPanelDetails>
                <ExpansionPanelActions>
                    <Button onClick={this.updateFollowUpHistory} className={classes.button} variant="contained" color="primary">
                        Update
                    </Button>
                </ExpansionPanelActions>
            </>
        );
    }
}

const mapStateToProps = reduxState => ({
    error: reduxState.followUpHistory.error,
    updateSuccess: reduxState.followUpHistory.updateSuccess
});

export default connect(mapStateToProps)(withStyles(styles)(FollowUpDetailDetail));
