import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import FollowUpDetail from "./FollowUpDetail";
import Paper from "@material-ui/core/Paper";
import AdjuvantChemotherapy from "./AdjuvantChemotherapy";
import AddFollowUpDialog from "./AddFollowUpDialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import Notification from "../Notification";
import { Typography } from "@material-ui/core";

const styles = theme => ({
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2
    },
    divider: {
        marginTop: 10,
        marginBottom: 10
    },
    progress: {
        margin: theme.spacing.unit * 2,
        padding: theme.spacing.unit * 3
    },
    toolbar: {
        display: "flex",
        color: "primary",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing.unit * 1,
        ...theme.mixins.toolbar
    }
});

class FollowUpPage extends Component {
    state = {
        changesMade: false,
        localLoading: true,
        errorOpen: true,
        id: 0,
        patient_id: 0,
        follow_up_adj_chemotherapy_type: null,
        follow_up_adj_chemotherapy_cycles: null,
        follow_up_adj_chemotherapy_other_type: null,
        follow_up_adj_chemotherapy_other_cycles: null,
        follow_up_biological: null,
        follow_up_biological_other: null,
        evidence_of_disease: false,
        last_contact: null,
        date_of_death: null,
        follow_up_notes: null
    };

    setInitialState = () => {
        const {
            id,
            follow_up_adj_chemotherapy_type,
            follow_up_adj_chemotherapy_cycles,
            follow_up_adj_chemotherapy_other_type,
            follow_up_adj_chemotherapy_other_cycles,
            follow_up_biological,
            follow_up_biological_other,
            evidence_of_disease,
            last_contact,
            date_of_death,
            follow_up_notes
        } = this.props.followUp;
        const { patientId } = this.props;

        this.setState(
            {
                changesMade: false,
                errorOpen: true,
                id: id,
                patient_id: patientId,
                follow_up_adj_chemotherapy_type: follow_up_adj_chemotherapy_type,
                follow_up_adj_chemotherapy_cycles: follow_up_adj_chemotherapy_cycles,
                follow_up_adj_chemotherapy_other_type: follow_up_adj_chemotherapy_other_type,
                follow_up_adj_chemotherapy_other_cycles: follow_up_adj_chemotherapy_other_cycles,
                follow_up_biological: follow_up_biological,
                follow_up_biological_other: follow_up_biological_other,
                evidence_of_disease: evidence_of_disease,
                last_contact: last_contact,
                date_of_death: date_of_death,
                follow_up_notes: follow_up_notes
            },
            () => {
                this.setState({
                    ...this.state,
                    localLoading: false
                });
            }
        );
    };

    componentDidMount() {
        this.props.onRef(this);
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.setInitialState();
    }

    handleCloseError = () => {
        this.setState({
            ...this.state,
            errorOpen: false
        });
        this.props.dispatch({ type: "CLEAR_FOLLOW_UP_ERROR" });
        this.setInitialState();
    };

    saveClicked = () => {
        if (this.state.changesMade) {
            this.props.dispatch({
                type: "UPDATE_FOLLOW_UP",
                payload: this.state
            });
            this.setState({
                ...this.state,
                changesMade: false
            });
        }
    };

    // Called when the input field changes
    handleChange = event => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
            changesMade: true
        });
    };

    // Called when the input field changes
    handleChangeCheckbox = event => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.checked,
            changesMade: true
        });
    };

    componentWillUnmount() {
        if (this.state.changesMade) {
            if (
                window.confirm(
                    "You have made changes that have not been saved. Click on OK to save these changes or Cancel to discard them."
                )
            ) {
                this.saveClicked();
            }
        }
        this.props.onRef(undefined);
    }

    render() {
        const { classes, loading, error, updateSuccess, followUp, followUpHistory } = this.props;
        const { localLoading, errorOpen } = this.state;

        if (loading || localLoading) {
            return (
                <>
                    <div className={classes.toolbar} />
                    <Grid container className={classes.progress} spacing={24} alignItems="center" justify="center">
                        <CircularProgress></CircularProgress>
                    </Grid>
                </>
            );
        }

        return (
            <>
                <Grid container>
                    {error && <Notification open={errorOpen} error={true} handleCloseError={this.handleCloseError} message={error} />}
                    {updateSuccess && (
                        <Notification
                            open={errorOpen}
                            error={false}
                            handleCloseError={this.handleCloseError}
                            message="Followup Information Saved"
                        />
                    )}
                    <Grid container>
                        <Grid container>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <AdjuvantChemotherapy adjuvant_chemo={this.state} handleChange={this.handleChange} />
                                </Paper>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="h6">Follow Up History</Typography>
                        </Grid>
                        <Grid item xs={6} style={{ alignContent: "flex-end" }}>
                            {followUp.id && <AddFollowUpDialog />}
                        </Grid>
                        <Grid item xs={12}>
                            <Divider variant="middle" className={classes.divider} />
                        </Grid>
                        {followUpHistory && <FollowUpDetail />}
                    </Grid>
                </Grid>
            </>
        );
    }
}

const mapStateToProps = reduxState => ({
    followUp: reduxState.followUp.data,
    followUpHistory: reduxState.followUpHistory.data,
    patientId: reduxState.patientReducer.data.id,
    loading: reduxState.followUp.loading,
    error: reduxState.followUp.error,
    updateSuccess: reduxState.followUp.updateSuccess
});

export default connect(mapStateToProps, null, null, { withRef: true })(withStyles(styles)(FollowUpPage));
