import axios from "axios";
import { put, call, takeLatest } from "redux-saga/effects";

// worker Saga: will be fired on "FETCH_USER" actions
function* fetchUser() {
    try {
        const config = {
            headers: { "Content-Type": "application/json" },
            withCredentials: true
        };

        // the config includes credentials which
        // allow the server session to recognize the user
        // If a user is logged in, this will return their information
        // from the server session (req.user)
        const response = yield axios.get("api/user", config);

        // now that the session has given us a user object
        // with an id and username set the client-side user object to let
        // the client-side code know the user is logged in
        yield put({ type: "SET_USER", payload: response.data });
    } catch (error) {
        console.log("User get request failed", error);
    }
}

function* getAllUsers() {
    try {
        const response = yield call(axios.get, `/getAllUsers`);
        yield put({ type: "SET_ALL_USERS", payload: response.data });
    } catch (error) {
        console.log("error with getting request", error);
    }
}

function* getAllUsersAfterUpdateResearcher() {
    try {
        const response = yield call(axios.get, `/getAllUsers`);
        yield put({ type: "SET_ALL_USERS_AFTER_UPDATE_RESEARCHERS", payload: response.data });
    } catch (error) {
        console.log("error with getting request", error);
    }
}

function* addNewUser(action) {
    try {
        yield call(axios.post, "/addNewUser", action.payload);
        yield put({ type: "GET_ALL_USERS" });
    } catch (error) {
        console.log(error);
        alert("Unable to add new user");
    }
}

function* updateUser(action) {
    try {
        yield call(axios.put, `/editUser/${action.payload.userId}`, action.payload);
        yield put({ type: "GET_ALL_USERS" });
    } catch (error) {
        console.log("there was an error with your POST", error);
        yield put({ type: "SET_USER_ERROR", payload: "Error updating this user. Please try again." });
    }
}

// worker Saga: will be fired on "UPDATE_RESEARCHER_SURGEONS" actions
function* updateResearcherSurgeons(action) {
    let surgeons = action.payload.localSurgeons.filter(function(surgeon) {
        return surgeon.checked === true;
    });
    let researcher = {
        id: action.payload.researcher,
        arraySurgeons: surgeons.map(s => s.id)
    };
    try {
        yield call(axios.post, `/researcher_surgeons`, researcher);
        yield put({ type: "UPDATE_RESEARCHER_SURGEONS_SUCCESS", payload: action.payload });
    } catch (error) {
        yield put({ type: "SET_RESEARCHER_SURGEONS_ERROR", payload: error });
        console.log(error);
    }
}

function* userSaga() {
    yield takeLatest("FETCH_USER", fetchUser);
    yield takeLatest("GET_ALL_USERS", getAllUsers);
    yield takeLatest("ADD_NEW_USER", addNewUser);
    yield takeLatest("UPDATE_USER", updateUser);
    yield takeLatest("UPDATE_RESEARCHER_SURGEONS", updateResearcherSurgeons);
    yield takeLatest("UPDATE_RESEARCHER_SURGEONS_SUCCESS", getAllUsersAfterUpdateResearcher);
}

export default userSaga;
