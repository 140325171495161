import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ClavienScore from "./ClavienScore";
import Checkbox from "@material-ui/core/Checkbox";
import GridItem from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

const styles = theme => ({
    formControl: {
        margin: theme.spacing.unit
    }
});

class SeriousAdverseEvent extends Component {
    render() {
        const { adverse_events, adverse_event_other, otherEventID, handleChangeAdverseEvent, handleChangeClavienScore } = this.props;

        return (
            <FormGroup row>
                <Grid container spacing={8}>
                    {adverse_events.map((event, index) => (
                        <GridItem item xs={6} md={4} lg={3} key={index}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        key={index}
                                        name={event.name}
                                        checked={adverse_events[index].checked ? true : false}
                                        onChange={handleChangeAdverseEvent}
                                        value={String(event.id)}
                                    />
                                }
                                label={event.name}
                            />
                            {event.id == otherEventID && adverse_events[index].checked && (
                                <TextField
                                    margin="dense"
                                    onChange={this.handleChange}
                                    name="adverse_event_other"
                                    value={adverse_event_other}
                                    id="adverse_event_other"
                                    placeholder="Enter Other Adverse Event"
                                />
                            )}
                            <ClavienScore
                                checked={event.checked}
                                clavienScore={!event.clavien_score ? 0 : event.clavien_score}
                                id={event.id}
                                handleChangeClavienScore={handleChangeClavienScore}
                            />
                        </GridItem>
                    ))}
                </Grid>
            </FormGroup>
        );
    } // end return
} // end class SeriousAdverseEvent

export default withStyles(styles)(SeriousAdverseEvent);
