import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import ReOperation from "./ReoperationSelector";
import Mortality from "./MortalitySelector";
import DischargeStatus from "./DischargeStatus";
import SeriousAdverseEvents from "./SeriousAdverseEvents";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Notification from "../Notification";
import { Typography, Divider } from "@material-ui/core";

const styles = theme => ({
    container: {
        // display: "flex",
        // flexWrap: "wrap",
        // alignItems: "stretch",
        // marginTop: 40
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit
    },
    expanded: {
        backgroundColor: "#cccccc",
        minHeight: 0,
        marginTop: 0,
        marginBottom: 0
    },
    dense: {
        marginTop: 19
    },
    menu: {
        width: 200
    },
    group: {
        flexDirection: "row"
    },
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2
    },
    alignment: {
        // minWidth: 320,
        margin: theme.spacing.unit * 1
    },
    tableCell: {
        paddingRight: 4,
        paddingLeft: 15
    }
});

const adverseEventScale = [
    { name: "1", description: "Mild AE" },
    { name: "2", description: "Moderate AE" },
    { name: "3", description: "Severe AE" },
    { name: "4", description: "Life-threatening or disabling AE" },
    { name: "5", description: "Death related to AE" }
];

class PostOpPage extends Component {
    state = {
        localLoading: true,
        changesMade: false,
        errorOpen: true,
        patient_id: 0,
        id: 0,
        icu_stays: null,
        mcu_stays: null,
        hospital_stays: null,
        notes: "",
        serious_adverse_event: false,
        score: 0,
        reoperation: "",
        hospital_mortality: "",
        status_at_discharge: "",
        discharge_notes: "",
        adverse_event_other: "",
        showAdverseEventOther: false,
        adverse_events: []
    };

    setInitialState = () => {
        const { patientId, postOp, adverseEvents } = this.props;
        const {
            id,
            icu_stays,
            mcu_stays,
            hospital_stays,
            notes,
            serious_adverse_event,
            score,
            reoperation,
            hospital_mortality,
            status_at_discharge,
            discharge_notes,
            adverse_event_other,
            num_reoperations
        } = postOp;
        this.setState(
            {
                ...this.state,
                localLoading: true,
                changesMade: false,
                errorOpen: true,
                id: id,
                patient_id: patientId,
                icu_stays: icu_stays,
                mcu_stays: mcu_stays,
                hospital_stays: hospital_stays,
                notes: notes,
                serious_adverse_event: serious_adverse_event,
                score: score,
                reoperation: reoperation,
                hospital_mortality: hospital_mortality,
                status_at_discharge: status_at_discharge,
                discharge_notes: discharge_notes,
                adverse_event_other: adverse_event_other,
                adverse_events: adverseEvents,
                num_reoperations: num_reoperations,
                showAdverseEventOther: adverseEvents.find(e => e.name === "Other Event") || false
            },
            () => {
                this.setState({
                    ...this.state,
                    localLoading: false
                });
            }
        );
    };

    componentDidMount() {
        this.props.onRef(this);
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.setInitialState();
    }

    componentWillUnmount() {
        if (this.state.changesMade) {
            if (
                window.confirm(
                    "You have made changes that have not been saved. Click on OK to save these changes or Cancel to discard them."
                )
            ) {
                this.saveClicked();
            }
        }
        this.props.onRef(undefined);
    }

    // Called when the input field changes
    handleChange = event => {
        this.setState({
            ...this.state,
            changesMade: true,
            [event.target.name]: event.target.value
        });
    };

    // Called when the input field changes
    handleChangeCheckbox = event => {
        this.setState({
            ...this.state,
            changesMade: true,
            [event.target.name]: event.target.checked
        });
    };

    handleChangeAdverseEvent = event => {
        // 1. Make a shallow copy of the items
        let adverseEventsToUpdate = [...this.state.adverse_events];
        // 2. Make a shallow copy of the item you want to mutate
        let item = { ...adverseEventsToUpdate[event.target.value - 1] };
        // 3. Replace the property you're interested in
        item.checked = !item.checked;
        // if checked off then remove the clavien score
        if (item.checked === false) {
            item.clavien_score = null;
        }
        // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
        adverseEventsToUpdate[event.target.value - 1] = item;
        // 5. Set the state to our new copy
        this.setState({
            ...this.state,
            changesMade: true,
            adverse_events: adverseEventsToUpdate,
            showAdverseEventOther: adverseEventsToUpdate.find(e => e.name === "Other Event") || false
        });
    };

    handleChangeClavienScore = event => {
        // 1. Make a shallow copy of the items
        let adverse_events = [...this.state.adverse_events];
        // 2. Make a shallow copy of the item you want to mutate
        let item = { ...adverse_events[event.target.name - 1] };
        // 3. Replace the property you're intested in
        item.clavien_score = event.target.value;
        item.patient_id = this.props.patientId;
        // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
        adverse_events[event.target.name - 1] = item;
        // 5. Set the state to our new copy
        this.setState({
            ...this.state,
            changesMade: true,
            adverse_events: adverse_events
        });
    };

    handleCloseError = () => {
        this.setState({
            ...this.state,
            errorOpen: false
        });
        this.props.dispatch({ type: "CLEAR_POST_OP_ERROR" });
        this.props.dispatch({ type: "CLEAR_ADVERSE_EVENT_ERROR" });
        this.setInitialState();
    };

    saveClicked = () => {
        if (this.state.changesMade) {
            this.props.dispatch({ type: "UPDATE_POST_OP", payload: this.state });
            this.props.dispatch({ type: "UPDATE_ADVERSE_EVENT", payload: this.state });
            this.setState({
                ...this.state,
                changesMade: false
            });
        }
    };

    render() {
        const { classes, loading, error, updateSuccess, aeLoading, aeError, aeUpdateSuccess } = this.props;
        const {
            localLoading,
            errorOpen,
            icu_stays,
            hospital_stays,
            hospital_mortality,
            reoperation,
            notes,
            discharge_notes,
            status_at_discharge,
            adverse_events,
            showAdverseEventOther,
            adverse_event_other,
            num_reoperations
        } = this.state;

        if (loading || aeLoading || localLoading || !adverse_events) {
            return (
                <>
                    <div className={classes.toolbar} />
                    <Grid container className={classes.progress} spacing={24} alignItems="center" justify="center">
                        <CircularProgress></CircularProgress>
                    </Grid>
                </>
            );
        }

        return (
            <>
                <Grid container>
                    {error && (
                        <Notification
                            open={errorOpen}
                            error={true}
                            handleCloseError={this.handleCloseError}
                            message="Error loading post op information."
                        />
                    )}
                    {aeError && (
                        <Notification
                            open={errorOpen}
                            error={true}
                            handleCloseError={this.handleCloseError}
                            message="Error loading adverse events."
                        />
                    )}
                    {updateSuccess && aeUpdateSuccess && (
                        <Notification
                            open={errorOpen}
                            error={false}
                            handleCloseError={this.handleCloseError}
                            message="Post Op Information Saved"
                        />
                    )}
                    <Grid container>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <Typography variant="h6">Post Operative Details</Typography>
                                <Divider />
                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            name="icu_stays"
                                            label="ICU Stay"
                                            type="number"
                                            className={classes.textField}
                                            value={icu_stays || ""}
                                            onChange={this.handleChange}
                                            margin="dense"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">days</InputAdornment>
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                        <TextField
                                            name="hospital_stays"
                                            label="Hospital Stay"
                                            type="number"
                                            className={classes.textField}
                                            value={hospital_stays || ""}
                                            onChange={this.handleChange}
                                            margin="dense"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">days</InputAdornment>
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            name="notes"
                                            label="Notes"
                                            className={classes.textField}
                                            value={notes || ""}
                                            multiline
                                            rows="4"
                                            fullWidth
                                            onChange={this.handleChange}
                                            margin="dense"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} md={7} lg={8}>
                                <Paper className={classes.paper}>
                                    <Typography variant="h6">Adverse Events</Typography>
                                    <Divider />
                                    <SeriousAdverseEvents
                                        adverse_events={adverse_events}
                                        handleChangeAdverseEvent={this.handleChangeAdverseEvent}
                                        handleChangeClavienScore={this.handleChangeClavienScore}
                                        adverse_event_other={adverse_event_other}
                                        otherEventID={adverse_events.find(e => e.name === "Other Event").id}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={5} lg={4}>
                                <Paper className={classes.paper}>
                                    <Typography variant="h6">CTCAE Classification Scale</Typography>
                                    <Divider />

                                    <Table className={classes.table} size="small">
                                        <TableBody>
                                            {adverseEventScale.map(row => (
                                                <TableRow key={row.name}>
                                                    <TableCell className={classes.tableCell} align="left">
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell} align="left">
                                                        {row.description}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Grid container>
                                <Grid item xs={6} md={4} className={classes.alignment}>
                                    <ReOperation className={classes.alignment} reoperation={reoperation} handleChange={this.handleChange} />
                                </Grid>
                                <Grid item xs={6} md={4} className={classes.alignment}>
                                    <TextField
                                        name="num_reoperations"
                                        label="# of Reoperations"
                                        type="number"
                                        className={classes.textField}
                                        value={num_reoperations || ""}
                                        onChange={this.handleChange}
                                        margin="dense"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={6} md={4} className={classes.alignment}>
                                    <Mortality
                                        className={classes.alignment}
                                        hospital_mortality={hospital_mortality}
                                        handleChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={6} md={4} className={classes.alignment}>
                                    <DischargeStatus
                                        className={classes.alignment}
                                        status_at_discharge={status_at_discharge}
                                        handleChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        name="discharge_notes"
                                        label="Discharge Notes"
                                        className={classes.textField}
                                        value={discharge_notes || ""}
                                        multiline
                                        rows="4"
                                        fullWidth
                                        onChange={this.handleChange}
                                        margin="dense"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </>
        );
    }
}

const mapStateToProps = reduxState => ({
    patientId: reduxState.patientReducer.data.id,
    postOp: reduxState.postOp.data,
    loading: reduxState.postOp.loading,
    error: reduxState.postOp.error,
    updateSuccess: reduxState.postOp.updateSuccess,
    adverseEvents: reduxState.adverseEvents.data,
    aeLoading: reduxState.adverseEvents.loading,
    aeError: reduxState.adverseEvents.error,
    aeUpdateSuccess: reduxState.adverseEvents.updateSuccess
});

export default connect(mapStateToProps, null, null, { withRef: true })(withStyles(styles)(PostOpPage));
