import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import PrimaryTumorDetails from "./PrimaryTumorDetails";
import PrimaryTumorTreatment from "./PrimaryTumorTreatment";
import Notification from "../Notification";

const styles = theme => ({
    gridItem: {
        marginBottom: 30
    },
    dropDown: {
        fullWidth: true
    },
    progress: {
        margin: theme.spacing.unit * 2,
        padding: theme.spacing.unit * 3
    },
    formControl: {
        margin: theme.spacing.unit * 1
    },
    group: {
        margin: `${theme.spacing.unit}px 0`
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        ...theme.mixins.toolbar
    }
});

class PrimaryTumorPage extends Component {
    state = {
        open: false,
        patient_id: 0,
        localLoading: true,
        errorOpen: true,
        changesMade: false,
        date_primary_diagnosis: null, 
        primary_location: "",
        tumor_type: "",
        tumor: "",
        node: "",
        metastasis: "",
        m_location: "",
        differentiation: "",
        mucinous: "3", 
        histological_type: "",
        date_prime_surgery: null, 
        intervention_surgery_1: "",
        intervention_surgery_2: "",
        setting: 0,
        reason_acute: "",
        prime_tumor_surgery: "",
        adj_chemotherapy_type: "",
        adj_chemotherapy_cycles: "",
        adj_chemotherapy_other_type: "",
        adj_chemotherapy_other_cycles: "",
        biological: "",
        biological_other: "",
        primary_tumor_notes: "",
        ///metastasis location from here down
        pc: false,
        liver: false,
        lung: false,
        ovaries: false,
        uterus: false,
        omentum: false,
        rectum: false,
        sigmoid: false,
        left_colon: false,
        transverse_colon: false,
        right_colon: false,
        ileocaecal: false,
        appendix: false,
        duodenum: false,
        jejunum: false,
        ileum: false,
        gallbladder: false,
        stomach: false,
        spleen: false,
        diagphram_l: false,
        diagphram_r: false,
        pancreas: false,
        bladder: false,
        ureter: false,
        lymphnodes: false,
        left_peritoneum: false,
        right_peritoneum: false,
        peritoneum: false,
        pelvis: false,
        other: false,
        other_location: null
    };

    setValuesForPrimaryTumor = () => {
        const {
            primary_location,
            tumor,
            node,
            metastasis,
            m_location,
            differentiation,
            mucinous,
            histological_type,
            date_prime_surgery,
            intervention_surgery_1,
            intervention_surgery_2,
            setting,
            reason_acute,
            prime_tumor_surgery,
            adj_chemotherapy_type,
            adj_chemotherapy_cycles,
            adj_chemotherapy_other_cycles,
            adj_chemotherapy_other_type,
            biological,
            biological_other,
            primary_tumor_notes,
            tumor_type,
            pc,
            liver,
            lung,
            ovaries,
            uterus,
            omentum,
            rectum,
            sigmoid,
            left_colon,
            transverse_colon,
            right_colon,
            ileocaecal,
            appendix,
            duodenum,
            jejunum,
            ileum,
            gallbladder,
            stomach,
            spleen,
            diagphram_l,
            diagphram_r,
            pancreas,
            bladder,
            urether,
            lymphnodes,
            left_peritoneum,
            right_peritoneum,
            peritoneum,
            pelvis,
            other,
            other_location
        } = this.props.primaryTumor;

        this.setState(
            {
                ...this.state,
                localLoading: true,
                patient_id: this.props.patientId,
                changesMade: false,
                errorOpen: true,
                primary_location: primary_location,
                tumor: tumor,
                node: node,
                metastasis: metastasis,
                m_location: m_location,
                differentiation: differentiation,
                mucinous: mucinous,
                histological_type: histological_type,
                date_prime_surgery: date_prime_surgery, 
                intervention_surgery_1: intervention_surgery_1,
                intervention_surgery_2: intervention_surgery_2,
                setting: setting,
                reason_acute: reason_acute,
                prime_tumor_surgery: prime_tumor_surgery,
                adj_chemotherapy_type: adj_chemotherapy_type,
                adj_chemotherapy_cycles: adj_chemotherapy_cycles,
                adj_chemotherapy_other_type: adj_chemotherapy_other_type,
                adj_chemotherapy_other_cycles: adj_chemotherapy_other_cycles,
                biological: biological,
                biological_other: biological_other,
                primary_tumor_notes: primary_tumor_notes,
                tumor_type: tumor_type,
                //m_location
                pc: pc,
                liver: liver,
                lung: lung,
                ovaries: ovaries,
                uterus: uterus,
                omentum: omentum,
                rectum: rectum,
                sigmoid: sigmoid,
                left_colon: left_colon,
                transverse_colon: transverse_colon,
                right_colon: right_colon,
                ileocaecal: ileocaecal,
                appendix: appendix,
                duodenum: duodenum,
                jejunum: jejunum,
                ileum: ileum,
                gallbladder: gallbladder,
                stomach: stomach,
                spleen: spleen,
                diagphram_l: diagphram_l,
                diagphram_r: diagphram_r,
                pancreas: pancreas,
                bladder: bladder,
                ureter: urether,
                lymphnodes: lymphnodes,
                left_peritoneum: left_peritoneum,
                right_peritoneum: right_peritoneum,
                peritoneum: peritoneum,
                pelvis: pelvis,
                other: other,
                other_location: other_location
            },
            () => {
                this.setState({
                    ...this.state,
                    localLoading: false
                });
            }
        );
    };

    saveClicked = () => {
        if (this.state.changesMade) {
            this.props.dispatch({ type: "UPDATE_PRIMARY_TUMOR", payload: this.state });
            this.setState({
                ...this.state,
                changesMade: false
            });
        }
    };

    handleChange = event => {
        this.setState({
            ...this.state,
            changesMade: true,
            [event.target.name]: event.target.value ? event.target.value : null
        });
    };

    handleChangeCheckbox = event => {
        this.setState({
            ...this.state,
            changesMade: true,
            [event.target.name]: event.target.checked
        });
    };

    handleCloseError = () => {
        this.setState({
            ...this.state,
            errorOpen: false
        });
        this.props.dispatch({ type: "CLEAR_PRIMARY_TUMOR_ERROR" });
        this.setValuesForPrimaryTumor();
    };

    handleYes = () => {};

    componentDidMount() {
        this.props.onRef(this);
        this.setValuesForPrimaryTumor();
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    componentWillUnmount() {
        if (this.state.changesMade) {
            if (
                window.confirm(
                    "You have made changes that have not been saved. Click on OK to save these changes or Cancel to discard them."
                )
            ) {
                this.saveClicked();
            }
        }
        this.props.onRef(undefined);
    }

    render() {
        const { classes, loading, error, updateSuccess, patientDiagnosisDate } = this.props;
        const { localLoading, errorOpen } = this.state;

        if (loading || localLoading) {
            return (
                <>
                    <div className={classes.toolbar} />
                    <Grid container className={classes.progress} spacing={24} alignItems="center" justify="center">
                        <CircularProgress></CircularProgress>
                    </Grid>
                </>
            );
        }

        return (
            <div className={classes.container}>
                {error && <Notification open={errorOpen} error={true} handleCloseError={this.handleCloseError} message={error} />}
                {updateSuccess && (
                    <Notification open={errorOpen} error={false} handleCloseError={this.handleCloseError} message="Primary Tumor Saved" />
                )}
                {!loading && (
                    <PrimaryTumorDetails
                        loading={loading}
                        primary_tumor={this.state}
                        patientDiagnosisDate={patientDiagnosisDate}
                        handleChange={this.handleChange}
                        handleChangeCheckbox={this.handleChangeCheckbox}
                    />
                )}
                {!loading && <PrimaryTumorTreatment loading={loading} primary_tumor={this.state} handleChange={this.handleChange} />}
            </div>
        );
    }
}

const mapStateToProps = reduxState => ({
    primaryTumor: reduxState.primaryTumorReducer.data,
    patientId: reduxState.patientReducer.data.id,
    patientDiagnosisDate: reduxState.patientReducer.data.diagnosis_date,
    loading: reduxState.primaryTumorReducer.loading,
    error: reduxState.primaryTumorReducer.error,
    updateSuccess: reduxState.primaryTumorReducer.updateSuccess
});

export default connect(mapStateToProps, null, null, { withRef: true })(withStyles(styles)(PrimaryTumorPage));
