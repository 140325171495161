import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";

const styles = theme => ({
    formControl: {
        margin: theme.spacing.unit * 2
    }
});

class ReoperationSelector extends Component {
    render() {
        const { classes } = this.props;

        return (
            <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">Reoperation</FormLabel>
                <RadioGroup
                    row
                    aria-label="reoperation"
                    name="reoperation"
                    value={this.props.reoperation}
                    className={classes.group}
                    onChange={this.props.handleChange}
                >
                    <FormControlLabel value="1" control={<Radio />} label="Yes" />
                    <FormControlLabel value="2" control={<Radio />} label="No" />
                    <FormControlLabel value="3" control={<Radio />} label="Unknown" />
                </RadioGroup>
            </FormControl>
        );
    } // end return
} // end class TagSelector

export default withStyles(styles)(ReoperationSelector);
