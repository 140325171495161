import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Input from "@material-ui/core/Input";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import Notification from "../Notification";
import { Card, CardHeader, CardContent, Divider } from "@material-ui/core";

const styles = theme => ({
    container: {
        // display: "flex",
        // flexWrap: "wrap",
        // alignItems: "stretch",
        // marginTop: 85
    },
    card: {
        // maxWidth: 400
        margin: theme.spacing.unit * 2
    },
    table: {
        minWidth: 700
    },
    paper: {
        padding: theme.spacing.unit * 2
    },
    input: {
        float: "right"
    },
    inputLabel: {
        float: "left"
    },
    textField: {
        marginBottom: 20
    },
    select: {
        marginBottom: 20
    }
});

class PatientDetail extends Component {
    state = {
        localLoading: true,
        errorOpen: true,
        changesMade: false,
        id: 0,
        patient_no: "",
        patient_initials: "",
        dob: "",
        dobdate: "",
        age: 0,
        gender: "",
        hipec_date: "",
        hipec: "",
        referral_date: "",
        toc_id: "",
        diagnosis_date: "",
        alive_on_date: "",
        hospital_telephone: "",
        referring_doctor: "",
        current_status: "",
        interval_prime_surgery: "",
        survival_hipec_death: "",
        survival_hipec_last_contact: "",
        interval_diagnosis_pc_hipec: "",
        hospital_id: ""
    };

    setValuesForPatient = () => {
        const {
            id,
            patient_initials,
            dob,
            age,
            gender,
            hipec_date,
            referral_date,
            toc_id,
            diagnosis_date,
            alive_on_date,
            hospital_telephone,
            referring_doctor,
            current_status,
            interval_diagnosis_pc_hipec,
            interval_prime_surgery,
            survival_hipec_death,
            survival_hipec_last_contact,
            hospital_id
        } = this.props.patient;
        const today = new Date();

        this.setState(
            {
                ...this.state,
                localLoading: true,
                errorOpen: true,
                changesMade: false,
                id: id,
                patient_initials: patient_initials ? patient_initials.toUpperCase() : "",
                dob: moment(dob).format("YYYY-MM-DD"),
                age: age,
                gender: gender,
                hipec_date: hipec_date ? moment(hipec_date).format("YYYY-MM-DD") : null,
                referral_date: referral_date ? moment(referral_date).format("YYYY-MM-DD") : null,
                toc_id: toc_id,
                diagnosis_date: diagnosis_date ? moment(diagnosis_date).format("YYYY-MM-DD") : null,
                alive_on_date: alive_on_date ? moment(alive_on_date).format("YYYY-MM-DD") : moment(today).format("YYYY-MM-DD"),
                hospital_telephone: hospital_telephone !== null ? hospital_telephone : "",
                referring_doctor: referring_doctor !== null ? referring_doctor : "",
                current_status: current_status,
                interval_prime_surgery: interval_prime_surgery,
                survival_hipec_death: survival_hipec_death,
                survival_hipec_last_contact: survival_hipec_last_contact,
                interval_diagnosis_pc_hipec: interval_diagnosis_pc_hipec,
                hospital_id: hospital_id !== null ? hospital_id : ""
            },
            () => {
                document.cookie = `patientTOC=${toc_id}`;
                this.props.updateTheme(toc_id);
                this.setState({ ...this.state, localLoading: false });
            }
        );
    };

    handlePatientChange = name => event => {
        if (name === "toc_id") {
            document.cookie = `patientTOC=${event.target.value}`;
            this.props.updateTheme(event.target.value);
        }
        this.setState({ ...this.state, [name]: event.target.value, changesMade: true });
    };

    handleCloseError = () => {
        this.setState({ ...this.state, errorOpen: false });
        this.props.dispatch({ type: "CLEAR_PATIENT_ERROR" });
        this.setValuesForPatient();
    };

    componentDidMount = () => {
        this.props.onRef(this);
        this.setValuesForPatient();
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    };

    componentWillUnmount = () => {
        if (this.state.changesMade) {
            if (
                window.confirm(
                    "You have made changes that have not been saved. Click on OK to save these changes or Cancel to discard them."
                )
            ) {
                this.saveClicked();
            } else {
                document.cookie = `patientTOC=${this.props.patient.toc_id}`;
                this.props.updateTheme(this.props.patient.toc_id);
            }
        }
        this.props.onRef(undefined);
    };

    saveClicked = () => {
        if (this.state.changesMade === true) {
            this.props.dispatch({ type: "UPDATE_PATIENT", payload: this.state });
        }
    };

    render() {
        const { classes, loading, error, updateSuccess, typesOfCancerOptions, currentStatusOptions } = this.props;
        const { localLoading, errorOpen } = this.state;

        if (loading || localLoading || !typesOfCancerOptions || !currentStatusOptions) {
            return <CircularProgress></CircularProgress>;
        }

        return (
            <>
                {error && (
                    <Notification
                        open={errorOpen}
                        error={true}
                        handleCloseError={this.handleCloseError}
                        message="Error loading patient information."
                    />
                )}
                {updateSuccess && (
                    <Notification
                        open={errorOpen}
                        error={false}
                        handleCloseError={this.handleCloseError}
                        message="Patient Information Saved"
                    />
                )}
                <Grid container className={classes.container}>
                    <Grid item xs={12} sm={6}>
                        <Card className={classes.card}>
                            <CardHeader title="Patient Details" />
                            <Divider></Divider>
                            <CardContent>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Patient Initials (Limit 3 Characters)"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        maxLength: 3
                                    }}
                                    value={this.state.patient_initials}
                                    margin="normal"
                                    fullWidth={true}
                                    type="string"
                                    onChange={this.handlePatientChange("patient_initials", "value")}
                                />
                                <TextField
                                    className={classes.textField}
                                    required
                                    name="dob"
                                    id="outlined-required"
                                    label="Date of Birth"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    type="date"
                                    fullWidth={true}
                                    value={this.state.dob}
                                    onChange={this.handlePatientChange("dob", "value")}
                                />
                                {/* Age [auto-calculated via Date of Birth Input] */}
                                <TextField
                                    className={classes.textField}
                                    disabled
                                    name="age"
                                    id="outlined"
                                    label="Age"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    type="number"
                                    fullWidth={true}
                                    value={Math.floor((new Date() - new Date(this.state.dob).getTime()) / 3.15576e10)}
                                />
                                {/* Gender Input */}
                                <FormLabel component="legend" style={{ display: "inline-block" }}>
                                    Gender
                                </FormLabel>
                                <RadioGroup
                                    aria-label="Gender"
                                    name="gender"
                                    value={this.state.gender}
                                    onChange={this.handlePatientChange("gender", "value")}
                                    style={{ display: "flex", flexDirection: "row" }}
                                >
                                    <FormControlLabel value="Male" control={<Radio className={classes.radio} />} label="Male" />
                                    <FormControlLabel value="Female" control={<Radio className={classes.radio} />} label="Female" />
                                </RadioGroup>
                                <InputLabel shrink htmlFor="outlined-toc-simple" className={classes.inputLabel}>
                                    Type of Cancer
                                </InputLabel>
                                <Select
                                    className={classes.select}
                                    label="Type of Cancer"
                                    // InputLabelProps={{
                                    //   shrink: true,
                                    // }}
                                    fullWidth={true}
                                    value={this.state.toc_id}
                                    onChange={this.handlePatientChange("toc_id", "value")}
                                    input={
                                        <Input
                                            name="Type of Cancer"
                                            id="outlined-toc-simple"
                                            // labelWidth={this.state.labelWidth}
                                        />
                                    }
                                >
                                    <MenuItem value={1} name={"CRC"}>
                                        Colorectal Cancer
                                    </MenuItem>
                                    <MenuItem value={2} name={"Appendix"}>
                                        Appendix
                                    </MenuItem>
                                    <MenuItem value={3} name={"Gastric"}>
                                        Gastric
                                    </MenuItem>
                                    {this.state.gender === "Female" && (
                                        <MenuItem value={4} name={"Ovarian"}>
                                            Ovarian
                                        </MenuItem>
                                    )}
                                    <MenuItem value={5} name={"Mesothelioma"}>
                                        Mesothelioma
                                    </MenuItem>
                                    <MenuItem value={6} name={"PMP"}>
                                        Pseudomyxoma Peritonei
                                    </MenuItem>
                                    <MenuItem value={7} name={"Other"}>
                                        Other
                                    </MenuItem>
                                </Select>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Card className={classes.card}>
                            <CardHeader title="Hospital Details" />
                            <Divider></Divider>
                            <CardContent>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Hospital ID"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    value={this.state.hospital_id}
                                    margin="normal"
                                    fullWidth={true}
                                    type="string"
                                    onChange={this.handlePatientChange("hospital_id", "value")}
                                />{" "}
                                {/* Hospital Tel Input */}
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Hospital Telephone Number"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    margin="normal"
                                    fullWidth={true}
                                    type="string"
                                    value={this.state.hospital_telephone}
                                    onChange={this.handlePatientChange("hospital_telephone", "value")}
                                />
                                {/* Referring Doctor Input */}
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Referring Doctor"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    margin="normal"
                                    fullWidth={true}
                                    type="string"
                                    value={this.state.referring_doctor}
                                    onChange={this.handlePatientChange("referring_doctor", "value")}
                                />
                            </CardContent>
                        </Card>
                        <Card className={classes.card}>
                            <CardHeader title="Other Details" />
                            <Divider></Divider>
                            <CardContent>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="HIPEC Surgery (Primary) Date"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    margin="normal"
                                    type="date"
                                    fullWidth={true}
                                    value={this.state.hipec_date}
                                    onChange={this.handlePatientChange("hipec_date", "value")}
                                />
                                {/* HIPEC Date of Referral Input */}
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Date of Referral"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    margin="normal"
                                    type="date"
                                    fullWidth={true}
                                    value={this.state.referral_date}
                                    onChange={this.handlePatientChange("referral_date", "value")}
                                />
                                {/* HIPEC Date of Diagnosis Input */}
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Diagnosis Date"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    margin="normal"
                                    type="date"
                                    fullWidth={true}
                                    value={this.state.diagnosis_date}
                                    onChange={this.handlePatientChange("diagnosis_date", "value")}
                                />
                                {/* Alive on Date Input */}
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Alive on Date"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    margin="normal"
                                    type="date"
                                    fullWidth={true}
                                    value={this.state.alive_on_date}
                                    onChange={this.handlePatientChange("alive_on_date", "value")}
                                />

                                {/* HIPEC Current Status Input */}

                                <InputLabel shrink htmlFor="outlined-age-native-simple" className={classes.inputLabel}>
                                    Current Status
                                </InputLabel>
                                <Select
                                    value={this.state.current_status}
                                    onChange={this.handlePatientChange("current_status", "value")}
                                    input={<Input name="title" fullWidth id="outlined-age-native-simple" />}
                                >
                                    {currentStatusOptions.map(opt => {
                                        return (
                                            <MenuItem key={opt.id} value={opt.id}>
                                                {opt.option}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                {/* Interval Prime Surgery - HIPEC Input */}

                {/* <TextField
                                required
                                id="outlined-required"
                                label="Interval Prime Surgery - HIPEC (Number of Days)"
                                InputLabelProps={{shrink: true,}}
                                value={this.state.interval_prime_surgery}
                                margin="normal"
                                fullWidth={true}
                                type="string"
                                onChange={this.handlePatientChange('interval_prime_surgery', 'value')}
                                /> */}
                {/* Survival (HIPEC Death) Input */}

                {/* <TextField
                                required
                                id="outlined-required"
                                label="Survival - HIPEC Death (Number of Days)"
                                InputLabelProps={{shrink: true,}}
                                value={this.state.survival_hipec_death}
                                margin="normal"
                                fullWidth={true}
                                type="string"
                                onChange={this.handlePatientChange('survival_hipec_death', 'value')}
                                /> */}

                {/* Survival (HIPEC Last Contact) Input */}

                {/* <TextField
                                required
                                id="outlined-required"
                                label="Survival - HIPEC Last Contact (Number of Days)"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={this.state.survival_hipec_last_contact}
                                margin="normal"
                                                            fullWidth={true}
                                    type="string"
                                    onChange={this.handlePatientChange('survival_hipec_last_contact', 'value')}
                                /> */}

                {/* Interval Diagnosis PC-HIPEC Input */}

                {/* <TextField
                                required
                                id="outlined-required"
                                label="Interval Diagnosis PC-HIPEC (Number of Days)"
                                InputLabelProps={{shrink: true,}}
                                value={this.state.interval_diagnosis_pc_hipec}
                                margin="normal"
                                fullWidth={true}
                                type="string"
                                onChange={this.handlePatientChange('interval_diagnosis_pc_hipec', 'value')}
                                /> */}

                {/* Finish Update Patient Button */}
                {/* <Button onClick={this.saveClicked} color="primary">
                            Update Patient Information
                        </Button> */}
                {/* </div> */}
            </>
        );
    }
}

PatientDetail.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = reduxState => ({
    patient: reduxState.patientReducer.data,
    loading: reduxState.patientReducer.loading,
    error: reduxState.patientReducer.error,
    updateSuccess: reduxState.patientReducer.updateSuccess,
    currentStatusOptions: reduxState.optionsReducer.statusOptions,
    typesOfCancerOptions: reduxState.optionsReducer.cancerOptions
});

export default withStyles(styles)(connect(mapStateToProps, null, null, { withRef: true })(PatientDetail));
