import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Typography, Divider } from "@material-ui/core";

const styles = theme => ({
});

class IntakePageGeneral extends Component {
    render() {
        return (
            <>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Typography variant="h6">General Information</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            onChange={this.props.handleChange}
                            name="weight_kg"
                            value={this.props.intake.weight_kg}
                            type="number"
                            id="patientWeightInput"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">Kg</InputAdornment>
                            }}
                            label="Weight"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            onChange={this.props.handleChange}
                            name="length_m"
                            value={this.props.intake.length_m}
                            type="number"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">M</InputAdornment>
                            }}
                            id="lengthInput"
                            label="Height"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">BMI {this.props.intake.bmi_auto}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            onChange={this.props.handleChange}
                            name="ca125_u_ml"
                            value={this.props.intake.ca125_u_ml}
                            id="ca125_u_ml"
                            label="CA125"
                            type="number"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">u/mL</InputAdornment>
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            onChange={this.props.handleChange}
                            name="ca199_u_ml"
                            value={this.props.intake.ca199_u_ml}
                            id="ca199_u_ml"
                            label="CA19.9"
                            type="number"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">u/mL</InputAdornment>
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            onChange={this.props.handleChange}
                            name="cea_mg_l"
                            value={this.props.intake.cea_mg_l}
                            id="cea"
                            label="CEA"
                            type="number"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">mg/L</InputAdornment>
                            }}
                        />
                    </Grid>
                    {/* CTA - only if ovarian */}
                    {this.props.patient.toc_id === 4 ? (
                        <Grid item xs={6}>
                            <TextField
                                onChange={this.props.handleChange}
                                name="cta"
                                value={this.props.intake.cta}
                                id="cta"
                                type="number"
                                label="CTA"
                            />
                        </Grid>
                    ) : (
                        ""
                    )}
                    <Grid item xs={6}>
                        <TextField
                            onChange={this.props.handleChange}
                            name="leucocyte"
                            value={this.props.intake.leucocyte}
                            id="leucocyte"
                            label="Leucocyte"
                            type="number"
                        />
                    </Grid>
                </Grid>
            </>
        );
    }
}

const mapStateToProps = reduxState => ({
    patient: reduxState.patientReducer.data
});

export default connect(mapStateToProps)(withStyles(styles)(IntakePageGeneral));
