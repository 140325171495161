import React, { Component } from 'react'
import { connect } from 'react-redux';
import ResectionDropdown from '../InterventionPage/ResectionDropdown';
import AdditionalPageInfo from '../InterventionPage/AdditionalPageInfo';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import GridItem from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import {  Typography,  FormGroup, FormControlLabel, Checkbox, Divider } from '@material-ui/core';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Input from '@material-ui/core/Input';
import CircularProgress from '@material-ui/core/CircularProgress';
import Notification from '../Notification';

const styles = theme => ({
    paper: {
        padding: theme.spacing.unit * 1,
        color: theme.palette.text.secondary,
    },
    tableCell: {
        paddingRight: 4,
        paddingLeft: 15
    },
    cell: {
        paddingLeft: 25
    },
    tableRow: {
        height: 100
        },
    image: {
        paddingBottom: 10,
        paddingTop: 10
    },
    pci: {
        fontSize: theme.typography.pxToRem(30),
    },
    img: {
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
        padding: '10px',
    }
  });

    const lesionSizeScore = [
        { option: '0', score: 0 },
        { option: '1', score: 1 },
        { option: '2', score: 2 },
        { option: '3', score: 3 },
    ];

  class InterventionPage extends Component {
    state = {
        changesMade: false,
        localLoading: true,
        errorOpen: true,
        patient_id: null,
        pci_0: 0,
        pci_1: 0,
        pci_2: 0,
        pci_3: 0,
        pci_4: 0,
        pci_5: 0,
        pci_6: 0,
        pci_7: 0,
        pci_8: 0,
        pci_9: 0,
        pci_10: 0,
        pci_11: 0,
        pci_12: 0,
        pci_score: 0,
        surgeon_1: '',
        surgeon_2:'',
        surgeon_3: '',
        no_hipec: null,
        hipec_type:'',
        reason_oc:'',
        expanded1: true,
        expanded2: false,
        ///additionalPageInfo from here down
        checked: '',
        anastomosis: false ,
        anastomosis_number: '', 
        revision_stoma: false,
        stoma_post_hipec_type:'',
        bloodloss_ml:'',
        volume: '',
        hipec_regiment: '',
        hipec_flow_rate: '',
        bloodloss_time: '',
        concentration_mg_l: '',
        r_score: '',
        operative_duration: '',
        stoma_post_hipec: false,
        hemorrhage_class: '',
        vasopressers: false,
        albumine: false,
        red_blood_cells: false,
        plaquets: false,
        plasma: false,
        total_dose_mg: '',
        total_dose_mg_other1: '',
        total_dose_mg_other2: '',
        perfusate: '',
        perfusate_other: '',
        intracavity_temp_c: '',
        concentration_mg_l_other1: '',
        concentration_mg_l_other2: '',
        ///ResectionDropdown from here down
        interventionId: '',
        ovaries: false,
        uterus: false,
        omentum:false,
        rectum:false,
        sigmoid:false,
        left_colon:false,
        transverse_colon:false,
        right_colon:false,
        ileocaecal: false,
        appendix:false,
        duodenum:false,
        jejunum: false,
        ileum:false,
        gallbladder: false,
        stomach: false,
        spleen:false,
        diagphram_l:false,
        diagphram_r:false,
        pancreas:false,
        bladder:false,
        ureter:false,
        lymphnodes:false,
        left_peritoneum:false,
        right_peritoneum:false,
        peritoneum:false,
        pelvis:false,
        labelWidth: 0,
    };

    componentDidMount() {
        this.props.onRef(this);
        this.setInitialState();
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    };

    setInitialState = () => {
        const { pci_0, pci_1, pci_2, pci_3, pci_4, pci_5, pci_6, pci_7,
            pci_8, pci_9, pci_10, pci_11, pci_12, pci_score, surgeon_1, surgeon_2,
            surgeon_3, no_hipec, hipec_type, reason_oc, anastomosis, anastomosis_number, 
            revision_stoma, stoma_post_hipec_type, bloodloss_ml , volume , hipec_regiment ,
            hipec_flow_rate , bloodloss_time, concentration_mg_l, r_score, operative_duration,
            stoma_post_hipec, hemorrhage_class, vasopressers, albumine, red_blood_cells,
            plaquets, plasma, total_dose_mg, total_dose_mg_other1, total_dose_mg_other2,
            perfusate, perfusate_other, intracavity_temp_c, concentration_mg_l_other1, concentration_mg_l_other2, intervention_id,
            ovaries, uterus, omentum, rectum , sigmoid, left_colon, transverse_colon,
            right_colon, ileocaecal, appendix, duodenum, jejunum, ileum, gallbladder,
            stomach, spleen, diagphram_l, diagphram_r, pancreas, bladder, urether,
            lymphnodes, left_peritoneum, right_peritoneum, peritoneum, pelvis } = this.props.intervention;

            this.setState({
                changesMade: false,
                errorOpen: true,
                patient_id: this.props.patientId,
                pci_0: pci_0 ? pci_0 : 0,
                pci_1:  pci_1 ? pci_1 : 0,
                pci_2:  pci_2 ? pci_2 : 0,
                pci_3:  pci_3 ? pci_3 : 0,
                pci_4:   pci_4 ? pci_4 : 0,
                pci_5:  pci_5 ? pci_5 : 0,
                pci_6:  pci_6 ? pci_6 : 0,
                pci_7:  pci_7 ? pci_7 : 0,
                pci_8:  pci_8 ? pci_8 : 0,
                pci_9:  pci_9 ? pci_9 : 0,
                pci_10:  pci_10 ? pci_10 : 0,
                pci_11:  pci_11 ? pci_11 : 0,
                pci_12:  pci_12 ? pci_12 : 0,
                pci_score:  pci_score,
                surgeon_1:  surgeon_1,
                surgeon_2: surgeon_2,
                surgeon_3:  surgeon_3,
                no_hipec:  no_hipec,
                hipec_type:  hipec_type,
                reason_oc: reason_oc,
                anastomosis:  anastomosis,
                anastomosis_number:  anastomosis_number, 
                revision_stoma:  revision_stoma,
                stoma_post_hipec_type:  stoma_post_hipec_type,
                bloodloss_ml:  bloodloss_ml,
                volume:  volume,
                hipec_regiment:  hipec_regiment,
                hipec_flow_rate:  hipec_flow_rate,
                bloodloss_time:  bloodloss_time,
                concentration_mg_l:  concentration_mg_l,
                r_score:  r_score,
                operative_duration:  operative_duration,
                stoma_post_hipec:  stoma_post_hipec, 
                hemorrhage_class:  hemorrhage_class,
                vasopressers:  vasopressers,
                albumine:  albumine,
                red_blood_cells:  red_blood_cells,
                plaquets:  plaquets,
                plasma:  plasma,
                total_dose_mg:  total_dose_mg,
                total_dose_mg_other1:  total_dose_mg_other1,
                total_dose_mg_other2:  total_dose_mg_other2,
                perfusate:  perfusate,
                perfusate_other: perfusate_other,
                intracavity_temp_c:  intracavity_temp_c,
                concentration_mg_l_other1:  concentration_mg_l_other1,
                concentration_mg_l_other2:  concentration_mg_l_other2,
                interventionId: intervention_id,
                ovaries:  ovaries,
                uterus:  uterus,
                omentum: omentum,
                rectum: rectum,
                sigmoid: sigmoid,
                left_colon: left_colon,
                transverse_colon: transverse_colon,
                right_colon: right_colon,
                ileocaecal:  ileocaecal,
                appendix: appendix,
                duodenum: duodenum,
                jejunum:  jejunum,
                ileum: ileum,
                gallbladder:  gallbladder,
                stomach:  stomach,
                spleen: spleen,
                diagphram_l: diagphram_l,
                diagphram_r: diagphram_r,
                pancreas: pancreas,
                bladder: bladder,
                ureter: urether,
                lymphnodes: lymphnodes,
                left_peritoneum: left_peritoneum,
                right_peritoneum: right_peritoneum,
                peritoneum: peritoneum,
                pelvis: pelvis
            },
            () =>
              {
                this.setState({
                    ...this.state, 
                    localLoading: false
                });
              }
            );
    };

    calculatePCI = () => {
        const { pci_0, pci_1, pci_2, pci_3, pci_4, pci_5, pci_6, pci_7, pci_8, pci_9, pci_10, pci_11, pci_12} = this.state;
        this.setState({
            ...this.state,
            changesMade: true,
            pci_score: Number(pci_0) + Number(pci_1) + Number(pci_2) + Number(pci_3) + Number(pci_4) + Number(pci_5) + Number(pci_6) + Number(pci_7) + Number(pci_8) + Number(pci_9) + Number(pci_10) + Number(pci_11) + Number(pci_12)
        })
    };

    handlePCIChange = (event) => {
        this.setState({
            ...this.state,
            changesMade: true,
            [event.target.name]: event.target.value,
        },
        this.calculatePCI);
      };

    calculateConcentration = () => {
        const { total_dose_mg, total_dose_mg_other1, total_dose_mg_other2, volume } = this.state;
        let hipec, other1, other2 = null;
        if (total_dose_mg) {
            hipec = Number(Number(total_dose_mg) / Number(volume)).toFixed(2)
        };
        if (total_dose_mg_other1) {
            other1 = Number(Number(total_dose_mg_other1) / Number(volume)).toFixed(2)
        };
        if (total_dose_mg_other2) {
            other2 = Number(Number(total_dose_mg_other2) / Number(volume)).toFixed(2)
        };
        this.setState({
            changesMade: true,
            concentration_mg_l: hipec,
            concentration_mg_l_other1: other1,
            concentration_mg_l_other2: other2 
        })
    };

    handleChange = (event) => {
        this.setState({
            ...this.state,
            changesMade: true,
            [event.target.name]: event.target.value,
        },
        this.calculateConcentration);
      };

    saveClicked = () => {
        if (this.state.changesMade){ 
            this.props.dispatch({ type: 'UPDATE_INTERVENTION', 
                payload: this.state});
            }
            this.setState({
                ...this.state,
                changesMade: false,
            })
    };
    
    handleChangeCheckbox = (event) => {
        if (event.target.name === 'no_hipec') {
            if (event.target.checked) {
                this.setState({
                    ...this.state,
                    changesMade: true,
                    [event.target.name]: event.target.checked,
                    hipec_type: 4
                })
            } else {
                this.setState({
                    ...this.state,
                    changesMade: true,
                    [event.target.name]: event.target.checked,
                    hipec_type: null,
                    reason_oc: null
                })
            }
        } else {
            this.setState({
                ...this.state,
                changesMade: true,
                [event.target.name]: event.target.checked,
            });
        }
    }

    handleCloseError = () => {
        this.setState({
            ...this.state, 
            errorOpen: false
        });
        this.props.dispatch({type: 'CLEAR_INTERVENTION_ERROR'});
        this.setInitialState();
    };

    componentWillUnmount () {
        if (this.state.changesMade) {
            if (window.confirm('You have made changes that have not been saved. Click on OK to save these changes or Cancel to discard them.')) {
                this.saveClicked();
            };
        };
        this.props.onRef(undefined);
    }

    render() {
        const { classes, loading, error, updateSuccess, hipecTypeOptions, openCloseOptions} = this.props;
        const { localLoading, errorOpen } = this.state;

        if (loading || localLoading || !hipecTypeOptions || !openCloseOptions) {
            return <>
              <div className={classes.toolbar} />
              <Grid container className={classes.progress} spacing={24} alignItems="center" justify="center">
                <CircularProgress></CircularProgress>
              </Grid>
            </>
        }

        return (
            <>
            {
            error && <Notification open={errorOpen} error={true} handleCloseError={this.handleCloseError} message={error}/>
            }
            {
            updateSuccess && <Notification open={errorOpen} error={false} handleCloseError={this.handleCloseError} message='Intervention Saved'/>
            }
            <Grid container spacing={24}>
                <Grid item xs={12} md={3}>
                    <Paper className={classes.paper}>
                        <Typography variant="h4" align="center" color="primary">PCI Score {this.state.pci_score}</Typography>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Region</TableCell>
                                    <TableCell className={classes.cell}>Lesion Size/Score</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            0 - Central
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                            <Select 
                                                fullWidth={true}
                                                value={this.state.pci_0}
                                                onChange={this.handlePCIChange}
                                                input={
                                                <Input
                                                    value={this.state.pci_0}
                                                    name="pci_0"
                                                    id="pci_0"
                                                />
                                                }
                                            >
                                                {lesionSizeScore.map( score => {
                                                    return(
                                                        <MenuItem key={score.score} value={score.score} >{score.option}</MenuItem>
                                                    )
                                                })}
                                                
                                            </Select>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                        1 - Right Upper
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                            <Select 
                                                fullWidth={true}
                                                value={this.state.pci_1}
                                                onChange={this.handlePCIChange}
                                                input={
                                                <Input
                                                    value={this.state.pci_1}
                                                    name="pci_1"
                                                    id="pci_1"
                                                />
                                                }
                                            >
                                                {lesionSizeScore.map( score => {
                                                    return(
                                                        <MenuItem key={score.score} value={score.score} >{score.option}</MenuItem>
                                                    )
                                                })}
                                                
                                            </Select>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                        2 - Epigastrium
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                            <Select 
                                                fullWidth={true}
                                                value={this.state.pci_2}
                                                onChange={this.handlePCIChange}
                                                input={
                                                <Input
                                                    value={this.state.pci_2}
                                                    name="pci_2"
                                                    id="pci_2"
                                                />
                                                }
                                            >
                                                {lesionSizeScore.map( score => {
                                                    return(
                                                        <MenuItem key={score.score} value={score.score} >{score.option}</MenuItem>
                                                    )
                                                })}
                                                
                                            </Select>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                        3 - Left Upper
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                            <Select 
                                                fullWidth={true}
                                                value={this.state.pci_3}
                                                onChange={this.handlePCIChange}
                                                input={
                                                <Input
                                                    value={this.state.pci_3}
                                                    name="pci_3"
                                                    id="pci_3"
                                                />
                                                }
                                            >
                                                {lesionSizeScore.map( score => {
                                                    return(
                                                        <MenuItem key={score.score} value={score.score} >{score.option}</MenuItem>
                                                    )
                                                })}
                                                
                                            </Select>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                        4 - Left Flank
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                            <Select 
                                                fullWidth={true}
                                                value={this.state.pci_4} 
                                                onChange={this.handlePCIChange}
                                                input={
                                                <Input
                                                    value={this.state.pci_4} 
                                                    name="pci_4" 
                                                    id="pci_4" 
                                                />
                                                }
                                            >
                                                {lesionSizeScore.map( score => {
                                                    return(
                                                        <MenuItem key={score.score} value={score.score} >{score.option}</MenuItem>
                                                    )
                                                })}
                                                
                                            </Select>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                        5 - Left Lower
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                            <Select 
                                                fullWidth={true}
                                                value={this.state.pci_5}
                                                onChange={this.handlePCIChange}
                                                input={
                                                <Input
                                                    value={this.state.pci_5}
                                                    name="pci_5"
                                                    id="pci_5"
                                                />
                                                }
                                            >
                                                {lesionSizeScore.map( score => {
                                                    return(
                                                        <MenuItem key={score.score} value={score.score} >{score.option}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                        6 - Pelvis
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                            <Select 
                                                fullWidth={true}
                                                value={this.state.pci_6}
                                                onChange={this.handlePCIChange}
                                                input={
                                                <Input
                                                    value={this.state.pci_6}
                                                    name="pci_6"
                                                    id="pci_6"
                                                />
                                                }
                                            >
                                                {lesionSizeScore.map( score => {
                                                    return(
                                                        <MenuItem key={score.score} value={score.score} >{score.option}</MenuItem>
                                                    )
                                                })}
                                                
                                            </Select>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                        7 - Right Lower
                                        </TableCell>
                                        <TableCell className={classes.cell}> 
                                            <Select 
                                                fullWidth={true}
                                                value={this.state.pci_7}
                                                onChange={this.handlePCIChange}
                                                input={
                                                <Input
                                                    value={this.state.pci_7}
                                                    name="pci_7"
                                                    id="pci_7"
                                                />
                                                }
                                            >
                                                {lesionSizeScore.map( score => {
                                                    return(
                                                        <MenuItem key={score.score} value={score.score} >{score.option}</MenuItem>
                                                    )
                                                })}
                                                
                                            </Select>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                        8 - Right Flank
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                            <Select 
                                                fullWidth={true}
                                                value={this.state.pci_8}
                                                onChange={this.handlePCIChange}
                                                input={
                                                <Input
                                                    value={this.state.pci_8}
                                                    name="pci_8"
                                                    id="pci_8"
                                                />
                                                }
                                            >
                                                {lesionSizeScore.map( score => {
                                                    return(
                                                        <MenuItem key={score.score} value={score.score} >{score.option}</MenuItem>
                                                    )
                                                })}
                                                
                                            </Select>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                        9 - Upper Jejunum
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                            <Select 
                                                fullWidth={true}
                                                value={this.state.pci_9}
                                                onChange={this.handlePCIChange}
                                                input={
                                                <Input
                                                    value={this.state.pci_9}
                                                    name="pci_9"
                                                    id="pci_9"
                                                />
                                                }
                                            >
                                                {lesionSizeScore.map( score => {
                                                    return(
                                                        <MenuItem key={score.score} value={score.score} >{score.option}</MenuItem>
                                                    )
                                                })}
                                                
                                            </Select>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                        10 - Lower Jejunum
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                            <Select 
                                                fullWidth={true}
                                                value={this.state.pci_10}
                                                onChange={this.handlePCIChange}
                                                input={
                                                <Input
                                                    value={this.state.pci_10}
                                                    name="pci_10"
                                                    id="pci_10"
                                                />
                                                }
                                            >
                                                {lesionSizeScore.map( score => {
                                                    return(
                                                        <MenuItem key={score.score} value={score.score} >{score.option}</MenuItem>
                                                    )
                                                })}
                                                
                                            </Select>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                        11 - Upper Ilium
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                            <Select 
                                                fullWidth={true}
                                                // value={this.state.pci_11}
                                                onChange={this.handlePCIChange}
                                                input={
                                                <Input
                                                    value={this.state.pci_11}
                                                    name="pci_11"
                                                    id="pci_11"
                                                />
                                                }
                                            >
                                                {lesionSizeScore.map( score => {
                                                    return(
                                                        <MenuItem key={score.score} value={score.score} >{score.option}</MenuItem>
                                                    )
                                                })}
                                                
                                            </Select>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                        12 - Lower Ilium
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                            <Select 
                                                fullWidth={true}
                                                value={this.state.pci_12}
                                                onChange={this.handlePCIChange}
                                                input={
                                                <Input
                                                    value={this.state.pci_12}
                                                    name="pci_12"
                                                    id="pci_12"
                                                />
                                                }
                                            >
                                                {lesionSizeScore.map( score => {
                                                    return(
                                                        <MenuItem key={score.score} value={score.score} >{score.option}</MenuItem>
                                                    )
                                                })}
                                                
                                            </Select>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>

                <Grid item  xs={12} md={6}>
                    <Paper className={classes.paper}>
                        <img width="100%" alt="PCI Body" src="images/pciBody.jpg"/>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Paper className={classes.paper}>
                        <Typography variant="h6" align="center">
                            LESION SIZE SCORE<br/>
                        </Typography>
                        <Divider variant="middle" />
                        <Typography variant="body1" className={classes.image}>
                            0 - No tumor seen<br/>
                            1 - Tumor up to 0.5 cm<br/>
                            2 - Tumor up to 5.0 cm<br/>
                            3 - Tumor > 5.0 cm or confluence<br/>                 
                        </Typography>
                        <img alt="PCI Intestine" width="100%" src="images/pciIntestine.jpg"/>
                    </Paper>
                </Grid>
                <Grid item  xs={12}>
                    <Paper className={classes.paper}>
                    <Grid container className={classes.container}>
                        <GridItem item xs={12} md={4}>
                            <DialogContent >
                                <TextField
                                onChange={this.handleChange}
                                value={this.state.surgeon_1  || ""}
                                name="surgeon_1"
                                margin="dense"
                                id="surgeon_1"
                                label="Surgeon 1"
                                type="text"
                                />
                            </DialogContent>
                        </GridItem>
                        <GridItem item xs={12} md={4}>
                        <DialogContent >
                                <TextField
                                onChange={this.handleChange}
                                value={this.state.surgeon_2 || ""}
                                name="surgeon_2"
                                margin="dense"
                                id="surgeon_2"
                                label="Surgeon 2"
                                type="text"
                                />
                            </DialogContent>
                        </GridItem>
                        <GridItem item xs={12} md={4}>
                        <FormGroup row>
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    name="no_hipec"
                                    checked={this.state.no_hipec}
                                    onChange={this.handleChangeCheckbox}
                                    value={this.state.no_hipec}
                                    />
                                }
                                label="No HIPEC (Open/Close)"
                                />
                            </FormGroup>
                            {this.state.no_hipec && <DialogContent >
                                <FormControl fullWidth={true} margin="dense" className={classes.formControl}>
                                    <InputLabel shrink htmlFor="reason_oc">
                                        Reason Open/Close
                                    </InputLabel>
                                    <Select
                                        value={this.state.reason_oc || ""}
                                        onChange={this.handleChange}
                                        input={
                                        <Input
                                            name="reason_oc"
                                            fullWidth
                                            id="reason_oc"
                                            labelwidth={this.state.labelWidth}
                                        />
                                        }
                                    >
                                    {openCloseOptions.map( opt => {
                                            return(
                                                <MenuItem key={opt.id} value={opt.id}>{opt.option}</MenuItem>
                                            )
                                            
                                        })}
                                    </Select>
                                </FormControl>
                            </DialogContent>}
                            {!this.state.no_hipec && <DialogContent >
                                <FormControl fullWidth={true} margin="dense" className={classes.formControl}>
                                    <InputLabel shrink htmlFor="hipec_type">
                                        HIPEC Type
                                    </InputLabel>
                                    <Select
                                        value={this.state.hipec_type || ""}
                                        onChange={this.handleChange}
                                        input={
                                        <Input
                                            value={this.state.hipec_type || ""}
                                            name="hipec_type"
                                            id="hipec_type"
                                            labelwidth={this.state.labelWidth}
                                        />
                                        }
                                    >
                                        {hipecTypeOptions.map( opt => {
                                            if (opt.option !== 'Open-Close') {
                                            return(
                                                <MenuItem key={opt.id} value={opt.id}>{opt.option}</MenuItem>
                                            )}
                                        })}
                                        
                                    </Select>
                                </FormControl>
                            </DialogContent>}
                        </GridItem>
                        </Grid>                 
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        {/* Resections */}
                        <ResectionDropdown state={this.state} handleChange={this.handleChange} 
                            expansionPanel2={this.expansionPanel2} handleChangeCheckbox={this.handleChangeCheckbox}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        {/* HIPEC Information */}
                        <AdditionalPageInfo state={this.state} 
                            handleChange={this.handleChange} 
                            handleChangeCheckbox={this.handleChangeCheckbox}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </>
    )};
  }

  InterventionPage.propTypes = {
    classes: PropTypes.object.isRequired,
  };

const mapStateToProps = reduxState => ({
    intervention: reduxState.interventionReducer.data,
    loading: reduxState.interventionReducer.loading,
    error: reduxState.interventionReducer.error,
    updateSuccess: reduxState.interventionReducer.updateSuccess,
    user: reduxState.user,
    hipecTypeOptions: reduxState.optionsReducer.hipecTypeOptions,
    openCloseOptions: reduxState.optionsReducer.openCloseOptions,
    patientId: reduxState.patientReducer.data.id
});


export default connect(mapStateToProps,
    null,
    null,
    { withRef: true }) (withStyles(styles)(InterventionPage))