import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import MUIDataTable from "mui-datatables";
import { withStyles } from "@material-ui/core/styles";
import Notification from "../Notification";
import { researchColumns } from "./GridColumns";
import {
    Input,
    InputLabel,
    Chip,
    CircularProgress,
    Button,
    Card,
    CardHeader,
    CardContent,
    FormLabel,
    FormGroup,
    FormControlLabel,
    MenuItem,
    Select,
    FormControl,
    RadioGroup,
    Radio,
    TextField
} from "@material-ui/core";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

const styles = theme => ({
    root: {
        flex: true,
        flexGrow: 1
        // width: "100%"
    },
    container: {
        // display: "flex",
        // flexWrap: "wrap",
        // alignItems: "stretch",
        marginTop: 80,
        justify: "flex-start",
        alignItems: "stretch"
    },
    item: {
        flexGrow: 1,
        alignItems: "stretch"
    },
    progress: {
        marginTop: 75
    },
    card: {
        // minWidth: 275
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)"
    },
    title: {
        fontSize: 14
    },
    pos: {
        marginBottom: 12
    },
    formControl: {
        margin: theme.spacing.unit * 1,
        minWidth: 120,
        maxWidth: 300
    },
    chips: {
        display: "flex",
        flexWrap: "wrap"
    },
    chip: {
        margin: 2
    },
    noLabel: {
        marginTop: theme.spacing.unit * 3
    },
    overrides: {
        MuiTableCell: {
            root: {
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 10,
                paddingRight: 10,
                border: "1px solid #808080"
            },
            body: {
                fontSize: "1rem"
            },
            head: {
                fontSize: "1rem"
            }
        },
        MUIDataTableBodyCell: {
            root: {
                textAlign: "left"
            }
        },
        MUIDataTableHeadCell: {
            root: {
                textAlign: "center"
            },
            data: {
                textAlign: "center"
            },
            sortLabelRoot: {
                textAlign: "center"
            },
            sortAction: {
                display: "inline"
            },
            toolButton: {
                display: "inline"
            }
        }
    }
});

class ResearchPage extends Component {
    state = {
        errorOpen: true,
        researchQuery: {
            userId: this.props.user.id,
            accessLevel: this.props.user.access_level,
            cancerName: [],
            gender: "All",
            lowerAge: 0,
            upperAge: 120
        }
    };

    componentDidMount = () => {
        this.props.getDropDownOptions();
        this.props.clearResearchData();
    };

    handleCancerChange = event => {
        this.setState({
            ...this.state,
            researchQuery: {
                ...this.state.researchQuery,
                cancerName: event.target.value
            }
        });
    };

    handleChange = name => event => {
        this.setState({
            ...this.state,
            researchQuery: {
                ...this.state.researchQuery,
                [name]: event.target.value
            }
        });
    };

    onClickGetData = () => {
        this.props.getResearchData(this.state.researchQuery);
    };

    render() {
        const { classes, researchData, loading, error, typesOfCancerOptions } = this.props;
        const { errorOpen, researchQuery } = this.state;

        if (error) {
            return (
                <>
                    <div className={classes.toolbar} />
                    <Notification open={errorOpen} error={true} handleCloseError={this.handleCloseError} message={error} />
                </>
            );
        }

        const options = {
            rowsPerPage: 15,
            fixedHeader: true,
            selectableRows: "none",
            viewColumns: true,
            responsive: "scrollMaxHeight",
            filter: true,
            filterType: "multiselect",
            download: true,
            print: false,
            downloadOptions: {
                filename: "research-data.csv",
                separator: ",",
                filterOptions: {
                    useDisplayedColumnsOnly: true,
                    useDisplayedRowsOnly: true
                }
            }
        };

        return (
            <div className={classes.root}>
                <Grid container className={classes.container} spacing={8}>
                    <Grid item xs={3} className={classes.item}>
                        <Card>
                            <CardHeader
                                title="Select Query"
                                action={
                                    <Button variant="contained" color="primary" size="small" onClick={this.onClickGetData}>
                                        Get Data
                                    </Button>
                                }
                            ></CardHeader>
                            {typesOfCancerOptions && (
                                <CardContent>
                                    <FormGroup style={{ paddingBottom: 15 }}>
                                        <InputLabel htmlFor="select-multiple-chip">Type of Cancer</InputLabel>
                                        <FormControl fullWidth>
                                            <Select
                                                fullWidth={true}
                                                multiple
                                                value={researchQuery.cancerName}
                                                onChange={this.handleCancerChange}
                                                input={<Input id="select-multiple-chip" />}
                                                renderValue={selected => (
                                                    <div className={classes.chips}>
                                                        {selected.map(value => (
                                                            <Chip key={value} label={value} className={classes.chip} />
                                                        ))}
                                                    </div>
                                                )}
                                                MenuProps={MenuProps}
                                            >
                                                {typesOfCancerOptions.map(toc => (
                                                    <MenuItem
                                                        key={toc.id}
                                                        value={toc.name}
                                                        // style={getStyles(toc.name, researchQuery.cancerName, theme)}
                                                    >
                                                        {toc.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                    <FormLabel component="legend" style={{ display: "inline-block" }}>
                                        Gender
                                    </FormLabel>
                                    <RadioGroup
                                        aria-label="Gender"
                                        name="gender"
                                        value={researchQuery.gender}
                                        onChange={this.handleChange("gender")}
                                        style={{ display: "flex", flexDirection: "row" }}
                                    >
                                        <FormControlLabel value="All" control={<Radio className={classes.radio} />} label="All" />
                                        <FormControlLabel value="Male" control={<Radio className={classes.radio} />} label="Male" />
                                        <FormControlLabel value="Female" control={<Radio className={classes.radio} />} label="Female" />
                                    </RadioGroup>
                                    <Grid container>
                                        <Grid item xs={5} style={{ marginRight: 5 }}>
                                            <TextField
                                                required
                                                id="outlined-required"
                                                label="Age Lower"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                value={researchQuery.lowerAge}
                                                margin="normal"
                                                fullWidth={true}
                                                type="number"
                                                onChange={this.handleChange("lowerAge")}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                required
                                                id="outlined-required"
                                                label="Age Upper"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                value={researchQuery.upperAge}
                                                margin="normal"
                                                fullWidth={true}
                                                type="number"
                                                onChange={this.handleChange("upperAge")}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            )}
                        </Card>
                    </Grid>
                    <Grid item xs={9} className={classes.item}>
                        <Card>
                            {loading && <CircularProgress></CircularProgress>}
                            <MUIDataTable title={"Research Data"} data={researchData} columns={researchColumns} options={options} />
                        </Card>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = reduxState => ({
    user: reduxState.user,
    researchData: reduxState.allResearcherDataReducer.data,
    loading: reduxState.allResearcherDataReducer.loading,
    error: reduxState.allResearcherDataReducer.error,
    typesOfCancerOptions: reduxState.optionsReducer.cancerOptions
});

const mapDispatchToProps = dispatch => {
    return {
        // dispatching plain actions
        getResearchData: researchQuery => {
            dispatch({ type: "GET_RESEARCH_DATA", payload: researchQuery });
        },
        clearResearchData: () => {
            dispatch({ type: "CLEAR_RESEARCH_DATA" });
        },
        getDropDownOptions: () => {
            dispatch({ type: "GET_OPTIONS" });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ResearchPage));
