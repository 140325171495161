const initialState = {
  data: {},
  loading: false,
  error: ''
};

const postOpReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_POST_OP':
        return {};
      case 'FIND_POST_OP':
        return {
          ...state,
          data: {},
          loading: true,
          error: '',
          updateSuccess: false
        };
      case 'SET_POST_OP_FOUND':
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: '',
          updateSuccess: false
        };
      case "SET_POST_OP_ERROR":
        return {
          ...state,
          loading: false,
          error: action.payload,
          updateSuccess: false
        };
      case 'UPDATE_POST_OP_SUCCESS':
          return {
            ...state,
            data: action.payload,
            loading: false,
            error: '',
            updateSuccess: true
          };
      case 'CLEAR_POST_OP_ERROR':
          return {
            ...state,
            loading: false,
            error: '',
            updateSuccess: false
        };
    // case 'SET_POST_OP':
    //   return action.payload;
    default:
      return state;
  }
};

// postOp will be on the redux state at:
// state.postOp
export default postOpReducer;