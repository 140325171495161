const initialState = {
    data: [],
    loading: false,
    error: ""
};

const allUsersReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_ALL_USERS":
            return {
                ...state,
                users: [],
                loading: true,
                error: "",
                updateSuccess: false
            };
        case "SET_ALL_USERS":
            return {
                ...state,
                users: action.payload,
                loading: false,
                error: "",
                updateSuccess: false
            };
        case "ALL_USERS_FOUND_AFTER_ADD":
            return {
                ...state,
                users: action.payload,
                loading: false,
                error: "",
                updateSuccess: false,
                addSuccess: true
            };
        case "SET_ALL_USERS_AFTER_UPDATE_RESEARCHERS":
            return {
                ...state,
                users: action.payload,
                loading: false,
                error: "",
                updateSuccess: false,
                addSuccess: false,
                updateResearchersSuccess: true
            };
        case "UPDATE_USER_SUCCESS":
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: "",
                updateSuccess: true
            };
        case "ALL_USERS_ERROR":
            return {
                ...state,
                users: [],
                loading: false,
                error: "Error loading users.",
                updateSuccess: false
            };
        case "CLEAR_USERS_ERROR":
            return {
                ...state,
                loading: false,
                error: "",
                updateSuccess: false,
                updateResearchersSuccess: false
            };
        // case "UPDATE_RESEARCHER_SURGEONS_SUCCESS":
        //     return {
        //         ...state,
        //         updateResearchersError: true
        //     };
        default:
            return state;
    }
};

export default allUsersReducer;
