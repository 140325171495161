import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import moment from "moment";
import { Divider, Typography } from "@material-ui/core";

const styles = theme => ({
    gridItem: {
        marginBottom: 10
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120
    },
    group: {
        margin: `${theme.spacing.unit}px 0`
    },
    container: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "stretch"
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 250
    },
    dense: {
        marginTop: 19
    }
});

class IntakePageDiagnosticCT extends Component {
    render() {
        const { classes } = this.props;
        return (
            <>
                <Grid item xs={12}>
                    <Typography variant="h6">Diagnostic CT Information</Typography>
                    <Divider />
                </Grid>
                <Grid container spacing={24}>
                    <Grid item xs={12} sm={6} className={classes.gridItem}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">Diagnostic CT</FormLabel>
                            <RadioGroup
                                aria-label="diagnostic_ct"
                                name="diagnostic_ct"
                                className={classes.group}
                                value={this.props.intake.diagnostic_ct}
                                onChange={this.props.handleChange}
                                type="number"
                            >
                                <FormControlLabel value="1" control={<Radio />} label="Yes" />
                                <FormControlLabel value="2" control={<Radio />} label="No" />
                                <FormControlLabel value="3" control={<Radio />} label="Unknown" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.gridItem}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">CT Conclusion</FormLabel>
                            <RadioGroup
                                aria-label="ct_conclusion"
                                name="ct_conclusion"
                                className={classes.group}
                                value={this.props.intake.ct_conclusion}
                                onChange={this.props.handleChange}
                                type="number"
                            >
                                <FormControlLabel value="1" control={<Radio />} label="Amenable" />
                                <FormControlLabel value="2" control={<Radio />} label="Good for CRS + HIPEC" />
                                {/* <FormControlLabel value='3' control={<Radio />} label="No Go" /> */}
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.gridItem}>
                        <TextField
                            margin="dense"
                            type="date"
                            className={classes.textField}
                            width="400px"
                            onChange={this.props.handleChange}
                            name="date_ct"
                            label="Date of Diagnostic CT"
                            value={moment(this.props.intake.date_ct).format("YYYY-MM-DD")}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.gridItem}>
                        <TextField
                            onChange={this.props.handleChange}
                            value={this.props.intake.ct_notes}
                            name="ct_notes"
                            multiline
                            rows="5"
                            variant="outlined"
                            label="CT Notes"
                            fullWidth={true}
                        />
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default withStyles(styles)(IntakePageDiagnosticCT);
