import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// import Button from '@material-ui/core/Button';
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faUserAlt,
    faDna,
    faFileMedicalAlt,
    faSyringe,
    faXRay,
    faNotesMedical,
    faUserMd,
    faBalanceScale,
    faProcedures
} from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@material-ui/core";

const styles = theme => ({
    root: {
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.primary
    },
    button: {
        margin: theme.spacing.unit * 1
    },
    selected: {
        backgroundColor: theme.palette.primary
    },
    tabLink: {
        color: theme.palette.primary
    },
    divider: {
        marginTop: ".5em",
        marginBottom: ".5em"
    }
});

class SelectedListItem extends React.Component {
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <List component="nav">
                    <ListItem
                        // divider
                        button
                        selected={this.props.selectedIndex === 0}
                        onClick={event => this.props.handleListItemClick(event, 0)}
                    >
                        <ListItemIcon>
                            <FontAwesomeIcon className={classes.tabLink} size="1x" fixedWidth icon={faUserAlt} />
                        </ListItemIcon>
                        <ListItemText primary="Patient Details" />
                    </ListItem>
                    <ListItem
                        // divider
                        button
                        selected={this.props.selectedIndex === 1}
                        onClick={event => this.props.handleListItemClick(event, 1)}
                    >
                        <ListItemIcon>
                            <FontAwesomeIcon size="1x" fixedWidth icon={faDna} />
                        </ListItemIcon>
                        <ListItemText primary="Primary Tumor" />
                    </ListItem>
                    <ListItem
                        // divider
                        button
                        selected={this.props.selectedIndex === 2}
                        onClick={event => this.props.handleListItemClick(event, 2)}
                    >
                        <ListItemIcon>
                            <FontAwesomeIcon size="1x" fixedWidth icon={faFileMedicalAlt} />
                        </ListItemIcon>
                        <ListItemText primary="Intake" />
                    </ListItem>
                    <ListItem
                        // divider
                        button
                        selected={this.props.selectedIndex === 3}
                        onClick={event => this.props.handleListItemClick(event, 3)}
                    >
                        <ListItemIcon>
                            <FontAwesomeIcon size="1x" fixedWidth icon={faSyringe} />
                        </ListItemIcon>
                        <ListItemText primary="Intervention" />
                    </ListItem>
                    <ListItem
                        // divider
                        button
                        selected={this.props.selectedIndex === 4}
                        onClick={event => this.props.handleListItemClick(event, 4)}
                    >
                        <ListItemIcon>
                            <FontAwesomeIcon size="1x" fixedWidth icon={faBalanceScale} />
                        </ListItemIcon>
                        <ListItemText primary="PSDSS" />
                    </ListItem>
                    <ListItem
                        // divider
                        button
                        selected={this.props.selectedIndex === 5}
                        onClick={event => this.props.handleListItemClick(event, 5)}
                    >
                        <ListItemIcon>
                            <FontAwesomeIcon size="1x" fixedWidth icon={faXRay} />
                        </ListItemIcon>
                        <ListItemText primary="Pathology Notes" />
                    </ListItem>
                    <ListItem
                        // divider
                        button
                        selected={this.props.selectedIndex === 6}
                        onClick={event => this.props.handleListItemClick(event, 6)}
                    >
                        <ListItemIcon>
                            <FontAwesomeIcon size="1x" fixedWidth icon={faNotesMedical} />
                        </ListItemIcon>
                        <ListItemText primary="Op Notes" />
                    </ListItem>
                    <ListItem
                        // divider
                        button
                        selected={this.props.selectedIndex === 7}
                        onClick={event => this.props.handleListItemClick(event, 7)}
                    >
                        <ListItemIcon>
                            <FontAwesomeIcon size="1x" fixedWidth icon={faProcedures} />
                        </ListItemIcon>
                        <ListItemText primary="Post Op" />
                    </ListItem>
                    <ListItem
                        // divider
                        button
                        selected={this.props.selectedIndex === 8}
                        onClick={event => this.props.handleListItemClick(event, 8)}
                    >
                        <ListItemIcon>
                            {/* <InboxIcon /> */}
                            <FontAwesomeIcon size="1x" fixedWidth icon={faUserMd} />
                        </ListItemIcon>
                        <ListItemText primary="Follow Up" />
                    </ListItem>
                </List>
                <Divider className={classes.divider}></Divider>
                <Grid alignItems="center">
                    <Typography align="center">Supported By</Typography>
                    <img alt="HIPEC-Logo" src="images/ts-logo.png" height="40" />
                </Grid>
            </div>
        );
    }
}

SelectedListItem.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SelectedListItem);
